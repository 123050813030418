const LayoutSettings = {
  leftSidebar: {
    show: true,
    mode: "full" as 'full' | 'close' | 'compact' | 'mobile', // full, close, compact, mobile,
    theme: "slateDark1", // View all valid theme colors inside MatxTheme/themeColors.js
    sideNavWidth: 305,
    sidenavCompactWidth: 80,
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "dashboardTopBar", // View all valid theme colors inside MatxTheme/themeColors.js
  },
};

export default LayoutSettings;
