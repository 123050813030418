import { Box } from "@mui/material";
import { Timeline } from "@mui/lab";
import RecentUpdateCard from "app/views/agency-pages/properties/viewer/News/RecentUpdateCard";

const Newsletter = () => {
  return (
    <Box sx={{ mx: "auto", maxWidth: 900}}>
      <Box sx={{ display: 'block', height: '30rem' }}>
        <Timeline aria-label="time-line" sx={{
          display: 'list-item',
          maxHeight: '30rem',
          overflowY: 'scroll',
        }}>
          {updateList.map((notification, ind) => (
            <RecentUpdateCard
              key={ind}
              isFirstIndex={ind === 0}
              notification={notification}
              isLastIndex={ind === updateList.length - 1}
            />
          ))}
        </Timeline>
      </Box>

    </Box >
  );
};
const updateList = [
  {
    title: "Update to the latest version of the property viewer",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    timestamp: "2020/09/15",
    file_id: "1",
  },
  {
    title: "Why do we use it?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    timestamp: "2020/08/1",
  },
  {
    title: "Where can I get some?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    timestamp: "2020/07/05",
  },
  {
    title: "Where does it come from?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    timestamp: "2020/05/12",
  },
];
export default Newsletter;



