import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import BedIcon from '@mui/icons-material/Bed';
import PoolIcon from '@mui/icons-material/Pool';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { amenitieType } from 'app/redux/models';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import HotTubIcon from '@mui/icons-material/HotTub';
import { Icon } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import GamesIcon from '@mui/icons-material/Games';
import BalconyIcon from '@mui/icons-material/Balcony';
export const topBarHeight = 64;
export const navbarHeight = 60;
export const containedLayoutWidth = 1200;


export interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [

    { code: 'AR', label: 'Argentina', phone: '54' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'ES', label: 'España', phone: '34' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' }
];



export interface CityType {
    code: string;
    name: string;
}

export const cities: Record<string, CityType[]> = {
    'AR': [
        { code: 'AR-C', name: 'Ciudad Autónoma de Buenos Aires' },
        { code: 'AR-B', name: 'Buenos Aires' },
        { code: 'AR-K', name: 'Catamarca' },
        { code: 'AR-H', name: 'Chaco' },
        { code: 'AR-U', name: 'Chubut' },
        { code: 'AR-CBA', name: 'Córdoba' },
        { code: 'AR-W', name: 'Corrientes' },
        { code: 'AR-X', name: 'Córdoba' },
        { code: 'AR-E', name: 'Entre Ríos' },
        { code: 'AR-P', name: 'Formosa' },
        { code: 'AR-Y', name: 'Jujuy' },
        { code: 'AR-L', name: 'La Pampa' },
        { code: 'AR-F', name: 'La Rioja' },
        { code: 'AR-M', name: 'Mendoza' },
        { code: 'AR-N', name: 'Misiones' },
        { code: 'AR-Q', name: 'Neuquén' },
        { code: 'AR-R', name: 'Río Negro' },
        { code: 'AR-A', name: 'Salta' },
        { code: 'AR-J', name: 'San Juan' },
        { code: 'AR-D', name: 'San Luis' },
        { code: 'AR-Z', name: 'Santa Cruz' },
        { code: 'AR-SF', name: 'Santa Fe' },
        { code: 'AR-G', name: 'Santiago del Estero' },
        { code: 'AR-V', name: 'Tierra del Fuego' },
        { code: 'AR-T', name: 'Tucumán' }
    ],
    'AU': [
        { code: 'AU-NSW', name: 'Nueva Gales del Sur' },
        { code: 'AU-VIC', name: 'Victoria' },
        { code: 'AU-QLD', name: 'Queensland' },
        { code: 'AU-WA', name: 'Australia Occidental' },
        { code: 'AU-SA', name: 'Australia Meridional' },
        { code: 'AU-TAS', name: 'Tasmania' },
        { code: 'AU-ACT', name: 'Territorio de la Capital Australiana' },
        { code: 'AU-NT', name: 'Territorio del Norte' }
    ],
    'EC': [
        { code: 'EC-P', name: 'Pichincha' },
        { code: 'EC-G', name: 'Guayas' },
        { code: 'EC-A', name: 'Azuay' },
        { code: 'EC-M', name: 'Manabí' },
        { code: 'EC-T', name: 'Tungurahua' },
        { code: 'EC-S', name: 'Santa Elena' },
        { code: 'EC-B', name: 'El Oro' },
        { code: 'EC-F', name: 'Esmeraldas' },
        { code: 'EC-H', name: 'Los Ríos' },
        { code: 'EC-Y', name: 'Pastaza' },
        { code: 'EC-X', name: 'Cotopaxi' },
        { code: 'EC-O', name: 'Chimborazo' },
        { code: 'EC-I', name: 'Imbabura' },
        { code: 'EC-L', name: 'Loja' },
        { code: 'EC-R', name: 'Morona Santiago' },
        { code: 'EC-C', name: 'Cañar' },
        { code: 'EC-E', name: 'Carchi' },
        { code: 'EC-U', name: 'Sucumbíos' },
        { code: 'EC-W', name: 'Galápagos' },
        { code: 'EC-MN', name: 'Napo' },
        { code: 'EC-SU', name: 'Zamora Chinchipe' },
        { code: 'EC-D', name: 'Orellana' }
    ],
    'ES': [
        { code: 'ES-MD', name: 'Madrid' },
        { code: 'ES-CT', name: 'Cataluña' },
        { code: 'ES-VC', name: 'Comunidad Valenciana' },
        { code: 'ES-AN', name: 'Andalucía' },
        { code: 'ES-GA', name: 'Galicia' },
        { code: 'ES-NC', name: 'Navarra' },
        { code: 'ES-AR', name: 'Aragón' },
        { code: 'ES-CL', name: 'Castilla y León' },
        { code: 'ES-CM', name: 'Castilla-La Mancha' },
        { code: 'ES-EX', name: 'Extremadura' },
        { code: 'ES-RI', name: 'La Rioja' },
        { code: 'ES-MC', name: 'Región de Murcia' },
        { code: 'ES-AS', name: 'Asturias' },
        { code: 'ES-CB', name: 'Cantabria' },
        { code: 'ES-IB', name: 'Islas Baleares' },
        { code: 'ES-CN', name: 'Canarias' },
        { code: 'ES-PV', name: 'País Vasco' },
        { code: 'ES-CE', name: 'Ceuta' },
        { code: 'ES-ML', name: 'Melilla' }
    ],
    'IL': [
        { code: 'IL-JM', name: 'Jerusalén' },
        { code: 'IL-TA', name: 'Tel Aviv' },
        { code: 'IL-HA', name: 'Haifa' },
        { code: 'IL-DA', name: 'Distrito Central' },
        { code: 'IL-ES', name: 'Distrito Meridional' },
        { code: 'IL-N', name: 'Distrito Norte' },
        { code: 'IL-Z', name: 'Distrito de Judea y Samaria' },
        { code: 'IL-M', name: 'Distrito de Tel Aviv' }
    ],
    'UY': [
        { code: 'UY-MO', name: 'Montevideo' },
        { code: 'UY-AR', name: 'Canelones' },
        { code: 'UY-CA', name: 'Cerro Largo' },
        { code: 'UY-CO', name: 'Colonia' },
        { code: 'UY-DU', name: 'Durazno' },
        { code: 'UY-FS', name: 'Flores' },
        { code: 'UY-FD', name: 'Florida' },
        { code: 'UY-LA', name: 'Lavalleja' },
        { code: 'UY-MA', name: 'Maldonado' },
        { code: 'UY-MO', name: 'Montevideo' },
        { code: 'UY-PA', name: 'Paysandú' },
        { code: 'UY-RN', name: 'Río Negro' },
        { code: 'UY-RV', name: 'Rivera' },
        { code: 'UY-RO', name: 'Rocha' },
        { code: 'UY-SA', name: 'Salto' },
        { code: 'UY-SJ', name: 'San José' },
        { code: 'UY-SO', name: 'Soriano' },
        { code: 'UY-TA', name: 'Tacuarembó' },
        { code: 'UY-TT', name: 'Treinta y Tres' }
    ],
    'IN': [
        { code: 'IN-AN', name: 'Andamán y Nicobar' },
        { code: 'IN-AP', name: 'Andhra Pradesh' },
        { code: 'IN-AR', name: 'Arunachal Pradesh' },
        { code: 'IN-AS', name: 'Assam' },
        { code: 'IN-BR', name: 'Bihar' },
        { code: 'IN-CH', name: 'Chandigarh' },
        { code: 'IN-CT', name: 'Chhattisgarh' },
        { code: 'IN-DN', name: 'Dadra y Nagar Haveli y Daman y Diu' },
        { code: 'IN-DD', name: 'Daman y Diu' },
        { code: 'IN-DL', name: 'Delhi' },
        { code: 'IN-GA', name: 'Goa' },
        { code: 'IN-GJ', name: 'Gujarat' },
        { code: 'IN-HR', name: 'Haryana' },
        { code: 'IN-HP', name: 'Himachal Pradesh' },
        { code: 'IN-JK', name: 'Jammu y Cachemira' },
        { code: 'IN-JH', name: 'Jharkhand' },
        { code: 'IN-KA', name: 'Karnataka' },
        { code: 'IN-KL', name: 'Kerala' },
        { code: 'IN-LD', name: 'Lakshadweep' },
        { code: 'IN-MP', name: 'Madhya Pradesh' },
        { code: 'IN-MH', name: 'Maharashtra' },
        { code: 'IN-MN', name: 'Manipur' },
        { code: 'IN-ML', name: 'Meghalaya' },
        { code: 'IN-MZ', name: 'Mizoram' },
        { code: 'IN-NL', name: 'Nagaland' },
        { code: 'IN-OR', name: 'Odisha' },
        { code: 'IN-PY', name: 'Puducherry' },
        { code: 'IN-PB', name: 'Punjab' },
        { code: 'IN-RJ', name: 'Rajastán' },
        { code: 'IN-SK', name: 'Sikkim' },
        { code: 'IN-TN', name: 'Tamil Nadu' },
        { code: 'IN-TG', name: 'Telangana' },
        { code: 'IN-TR', name: 'Tripura' },
        { code: 'IN-UP', name: 'Uttar Pradesh' },
        { code: 'IN-UT', name: 'Uttarakhand' },
        { code: 'IN-WB', name: 'Bengala Occidental' }
    ],
    'US': [
        { code: 'US-AL', name: 'Alabama' },
        { code: 'US-AK', name: 'Alaska' },
        { code: 'US-AZ', name: 'Arizona' },
        { code: 'US-AR', name: 'Arkansas' },
        { code: 'US-CA', name: 'California' },
        { code: 'US-CO', name: 'Colorado' },
        { code: 'US-CT', name: 'Connecticut' },
        { code: 'US-DE', name: 'Delaware' },
        { code: 'US-FL', name: 'Florida' },
        { code: 'US-GA', name: 'Georgia' },
        { code: 'US-HI', name: 'Hawaii' },
        { code: 'US-ID', name: 'Idaho' },
        { code: 'US-IL', name: 'Illinois' },
        { code: 'US-IN', name: 'Indiana' },
        { code: 'US-IA', name: 'Iowa' },
        { code: 'US-KS', name: 'Kansas' },
        { code: 'US-KY', name: 'Kentucky' },
        { code: 'US-LA', name: 'Louisiana' },
        { code: 'US-ME', name: 'Maine' },
        { code: 'US-MD', name: 'Maryland' },
        { code: 'US-MA', name: 'Massachusetts' },
        { code: 'US-MI', name: 'Michigan' },
        { code: 'US-MN', name: 'Minnesota' },
        { code: 'US-MS', name: 'Mississippi' },
        { code: 'US-MO', name: 'Missouri' },
        { code: 'US-MT', name: 'Montana' },
        { code: 'US-NE', name: 'Nebraska' },
        { code: 'US-NV', name: 'Nevada' },
        { code: 'US-NH', name: 'New Hampshire' },
        { code: 'US-NJ', name: 'New Jersey' },
        { code: 'US-NM', name: 'New Mexico' },
        { code: 'US-NY', name: 'New York' },
        { code: 'US-NC', name: 'North Carolina' },
        { code: 'US-ND', name: 'North Dakota' },
        { code: 'US-OH', name: 'Ohio' },
        { code: 'US-OK', name: 'Oklahoma' },
        { code: 'US-OR', name: 'Oregon' },
        { code: 'US-PA', name: 'Pennsylvania' },
        { code: 'US-RI', name: 'Rhode Island' },
        { code: 'US-SC', name: 'South Carolina' },
        { code: 'US-SD', name: 'South Dakota' },
        { code: 'US-TN', name: 'Tennessee' },
        { code: 'US-TX', name: 'Texas' },
        { code: 'US-UT', name: 'Utah' },
        { code: 'US-VT', name: 'Vermont' },
        { code: 'US-VA', name: 'Virginia' },
        { code: 'US-WA', name: 'Washington' },
        { code: 'US-WV', name: 'West Virginia' },
        { code: 'US-WI', name: 'Wisconsin' },
        { code: 'US-WY', name: 'Wyoming' }
    ],
    'GB': [
        { code: 'US-AL', name: 'Alabama' },
        { code: 'US-AK', name: 'Alaska' },
        { code: 'US-AZ', name: 'Arizona' },
        { code: 'US-AR', name: 'Arkansas' },
        { code: 'US-CA', name: 'California' },
        { code: 'US-CO', name: 'Colorado' },
        { code: 'US-CT', name: 'Connecticut' },
        { code: 'US-DE', name: 'Delaware' },
        { code: 'US-FL', name: 'Florida' },
        { code: 'US-GA', name: 'Georgia' },
        { code: 'US-HI', name: 'Hawaii' },
        { code: 'US-ID', name: 'Idaho' },
        { code: 'US-IL', name: 'Illinois' },
        { code: 'US-IN', name: 'Indiana' },
        { code: 'US-IA', name: 'Iowa' },
        { code: 'US-KS', name: 'Kansas' },
        { code: 'US-KY', name: 'Kentucky' },
        { code: 'US-LA', name: 'Louisiana' },
        { code: 'US-ME', name: 'Maine' },
        { code: 'US-MD', name: 'Maryland' },
        { code: 'US-MA', name: 'Massachusetts' },
        { code: 'US-MI', name: 'Michigan' },
        { code: 'US-MN', name: 'Minnesota' },
        { code: 'US-MS', name: 'Mississippi' },
        { code: 'US-MO', name: 'Missouri' },
        { code: 'US-MT', name: 'Montana' },
        { code: 'US-NE', name: 'Nebraska' },
        { code: 'US-NV', name: 'Nevada' },
        { code: 'US-NH', name: 'New Hampshire' },
        { code: 'US-NJ', name: 'New Jersey' },
        { code: 'US-NM', name: 'New Mexico' },
        { code: 'US-NY', name: 'New York' },
        { code: 'US-NC', name: 'North Carolina' },
        { code: 'US-ND', name: 'North Dakota' },
        { code: 'US-OH', name: 'Ohio' },
        { code: 'US-OK', name: 'Oklahoma' },
        { code: 'US-OR', name: 'Oregon' },
        { code: 'US-PA', name: 'Pennsylvania' },
        { code: 'US-RI', name: 'Rhode Island' },
        { code: 'US-SC', name: 'South Carolina' },
        { code: 'US-SD', name: 'South Dakota' },
        { code: 'US-TN', name: 'Tennessee' },
        { code: 'US-TX', name: 'Texas' },
        { code: 'US-UT', name: 'Utah' },
        { code: 'US-VT', name: 'Vermont' },
        { code: 'US-VA', name: 'Virginia' },
        { code: 'US-WA', name: 'Washington' },
        { code: 'US-WV', name: 'West Virginia' },
        { code: 'US-WI', name: 'Wisconsin' },
        { code: 'US-WY', name: 'Wyoming' }
    ]
};


export const listAmmenities: {
    [key in amenitieType]: {
        icon: React.ElementType | JSX.Element
    }
} = {
    WIFI: { icon: SignalWifi2BarIcon },
    BEDS: { icon: BedIcon },
    POOL: { icon: PoolIcon },
    GRILL: { icon: OutdoorGrillIcon },
    GYM: { icon: FitnessCenterIcon },
    LAUNDRY: { icon: LocalLaundryServiceIcon },
    SOL: { icon: SolarPowerIcon },
    PARKING: { icon: LocalParkingIcon },
    SAUNA: { icon: HotTubIcon },
    GAMES: { icon: GamesIcon },
    CAR: { icon: DirectionsCarIcon },
    TERRACE: { icon: BalconyIcon }
};