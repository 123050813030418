import { Box, Typography, Icon } from '@mui/material';

const DataPack1 = ({ icon: IconComponent, title, value, type = 1, mb: mbStyle = '0.3rem' }: { icon?: any, title: string, value: string | number, type?: 1 | 2, mb?: any }) => {
    const typeDisplay = type === 1 ? 'row' : 'column';
    return <Box sx={{ display: 'flex', flexDirection: 'column', mb: mbStyle, mt: mbStyle, justifyContent: 'flex-start' }}>
        <div style={{ width: '100%', display: typeDisplay, textAlign: 'center' }}>
            {IconComponent && <Icon>{<IconComponent />}</Icon>}
            <Typography variant='body2' alignSelf={'center'}> {title} </Typography>
        </div>
        <Typography variant="subtitle2" fontSize={'1.2em'} textAlign={'center'}>{value}</Typography>
    </Box>
}


export default DataPack1;