import { Delete } from "@mui/icons-material";
import { Box, Button, Dialog, Grid, Icon, IconButton, Typography, styled } from "@mui/material";
import { FlexBetween } from "app/components/FlexBox";
import RangeCalendarField from "app/components/RangeCalendarField";
import { H4 } from "app/components/Typography";
import { Formik } from "formik";
import { useState } from "react";
import shortId from "shortid";
import { addNewEvent, deleteEvent, updateEvent } from "./CalendarService";
import { date, object, string } from "yup";


const DialogHeader = styled(FlexBetween)(({ theme }) => ({
    padding: "10px 15px",
    background: theme.palette.primary.main,
}));

const validationSchema = object().shape({
    title: string().min(3, "Minimum 3 characters required!").required("Title is required!"),
    note: string().required("Note is required!"),
    location: string().required("Location is required!"),
    endingDate: date().required("Ending Date is required!"),
    startingDate: date().required("Staring Date is required!"),
});

const EventEditorDialog = ({ event = {}, open, handleClose }: { event?: any, open: boolean, handleClose: () => void }) => {
    const setSelectedRange = useState<any>({})[1];

    const initialValues = {
        note: event?.note || "",
        title: event?.title || "",
        location: event?.location || "",
        endingDate: event?.endingDate || new Date(),
        startingDate: event?.startingDate || new Date(),
    };

    const handleFormSubmit = (values: any) => {
        console.log(values);
        let { id } = values;
        if (id) {
            updateEvent(values).then(() => handleClose());
        } else {
            //@ts-ignore
            addNewEvent({ id: shortId.generate(), ...values }).then(() => handleClose());
        }
    };

    const handleDeleteEvent = () => {
        if (event?.id) {
            deleteEvent(event).then(() => handleClose());
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true}>
            <DialogHeader>
                <H4 sx={{ m: 0, color: "#fff" }}>Add Books</H4>

                <IconButton onClick={handleClose}>
                    <Icon sx={{ color: "#fff" }}>clear</Icon>
                </IconButton>
            </DialogHeader>

            <Box p={2}>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} display='flex'>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, width: '10rem' }} component="div" alignSelf={'center'}>
                                            Time Period
                                        </Typography>
                                        <RangeCalendarField onRangeSelect={(r) => setSelectedRange(r)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid xs={12} item display={'flex'}>
                                            <Grid xs={4} item display={'flex'}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    Nights
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    1
                                                </Typography>
                                            </Grid>
                                            <Grid xs={4} item display={'flex'}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600, p: 1 }} component="div" alignSelf={'center'}>
                                                    Price
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600, p: 1 }} component="div" alignSelf={'center'}>
                                                    10
                                                </Typography>
                                            </Grid>
                                            <Grid xs={4} item display={'flex'}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    Total
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    $100
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item display={'flex'}>
                                            <Grid xs={5} item display={'flex'}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    Available Credits
                                                </Typography>
                                            </Grid>
                                            <Grid xs={2} item display={'flex'}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }} component="div" alignSelf={'center'}>
                                                    300
                                                </Typography>
                                            </Grid>
                                            <Grid xs={5} item display={'flex'}>
                                                <Button variant="contained" color="primary">
                                                    Pucharse Credits
                                                </Button>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Grid>

                                <Typography variant="subtitle2" sx={{ mt: 2 }} component="div">
                                    Booking is pending approval since the property has be booked already
                                </Typography>


                                <FlexBetween>
                                    <Button variant="contained" color="primary" type="submit">
                                        Book
                                    </Button>

                                    <Button disabled={!event.id} startIcon={<Delete />} onClick={handleDeleteEvent}>
                                        Delete
                                    </Button>
                                </FlexBetween>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Dialog>
    );
};

export default EventEditorDialog;
