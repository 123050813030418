import { Box, styled } from "@mui/material";
import { useState } from "react";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';



const ImageCard = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "26rem",
    height: "26rem",
    position: 'relative',
    borderRadius: '1rem',
});

const IMG = styled("img")(({ theme }) => ({
    width: "auto",
    margin: "auto",
    overflow: "hidden",

    backgroundColor: "#fff",
}));


const ThumbImg = styled("img")({
    width: "auto",
    marginLeft: 2,
    marginRight: 2,
    maxHeight: 70,
    borderRadius: '0.2rem',
});

const ArrowImg = styled(Box)({
    color: 'white',
    width: 20,
    marginLeft: 2,
    marginRight: 2,
    maxHeight: 70,
    '&:hover': {
        cursor: 'pointer'
    }
});



const ImagesCarrousel = ({ images }: { images: any[]}) => {

    const [selectedImage, setSelectedImage] = useState(images[0]);

    const handleClickArrow = (direction: string) => {

        const index = images.indexOf(selectedImage)

        if (direction === 'left') {
            if (index === 0) {
                setSelectedImage(images[images.length - 1])
            } else {
                setSelectedImage(images[index - 1])
            }
        } else {
            if (index === images.length - 1) {
                setSelectedImage(images[0])
            } else {
                setSelectedImage(images[index + 1])
            }
        }
    }


    return (<ImageCard>
        <IMG
            aria-label='foto-principal'
            sx={{
                width: '100%', height: '100%', pt: 0,
                backgroundImage: `url(${selectedImage})`, backgroundSize: "cover", backgroundPosition: "center",
                borderRadius: '1rem',
            }} />

        <Box aria-label='main-thumbs' sx={{
            padding: "0.5em 0 0 0", display: 'flex', width: '100%',
            position: 'absolute', bottom: 0, justifyContent: 'center', alignItems: 'center',
            backgroundImage: 'linear-gradient(0deg, #2a324cfa, #a395957d)', borderEndEndRadius: '1rem', borderEndStartRadius: '1rem'


        }}>
            <ArrowImg onClick={() => handleClickArrow('left')}>
                <ArrowBackIosNewOutlinedIcon />
            </ArrowImg>
            {images.map((imgUrl: string, i: number) => (
                <ThumbImg
                    src={imgUrl}
                    alt={`img-${i}`}
                    key={imgUrl + i}
                    sx={{ transform: selectedImage === imgUrl ? "scale(1.05)" : "none" }}
                    onClick={() => setSelectedImage(imgUrl)}
                />
            ))}
            <ArrowImg onClick={() => handleClickArrow('right')}>
                <ArrowForwardIosOutlinedIcon />
            </ArrowImg>
        </Box>
    </ImageCard>);
}

export default ImagesCarrousel;