import { Box, Card, SxProps, styled } from "@mui/material";
import { H5 } from "app/components/Typography";

const SidebarCardRoot = styled("div")(({ theme }) => ({
    position: "relative",
    "& .headerBG": {
        height: 200,
        background: theme.palette.primary.main,
        backgroundSize: "contain",
    },
    "& .sidenavHolder": { marginTop: -200 },
    "& .sidenav": {
        background: "transparent !important",
        [theme.breakpoints.down("sm")]: {
            background: theme.palette.background.default,
        },
    },
}));

const Content = styled("div")(() => ({
    flexGrow: 1,
    height: "100%",
    position: "relative",
}));

const PageWrapper = ({ children, headerSize = 'normal', nocard = false, sx }: { children: JSX.Element[] | JSX.Element, headerSize?: 'normal' | 'small', nocard?: boolean, sx?: SxProps<any> }) => {
    const pad = '30px'
    return (
        <SidebarCardRoot >
            <div className="headerBG" />
            <div className="sidenavHolder"></div>
            <Content sx={{
                pl: { xs: 1, sm: pad },
                pr: { xs: 1, sm: pad },
                ...sx

            }}>
                {headerSize === 'normal' && <H5 sx={{ p: 4, color: "#fff" }}></H5>}
                {nocard ? <Box sx={{ m: 2 }}>{children}</Box> : <Card sx={{ m: 2, p: '24px' }} elevation={2}> {children} </Card>}
            </Content>

        </SidebarCardRoot>
    );
}

export default PageWrapper;