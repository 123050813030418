import { Box } from "@mui/system";
import { formatToUSDValue } from "app/utils/utils";

export const ColorBasedOnValue = ({ value }: { value: number }) => {
    const color = value > 0 ? 'success.main' : 'error.main';

    return (
        <Box
            sx={{
                color,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginLeft: '60%',
                wordBreak: 'auto-phrase',
                '& svg': { mr: 0.5 },
            }}
        >
            {formatToUSDValue(value)}
        </Box>
    );
};
