import { Skeleton, Box } from "@mui/material";

const LoadingPropertyCard = () => {
    return (<article aria-label="loading-card">
        <header>
            <Skeleton variant="rectangular" animation='wave' sx={{
                aspectRatio: '10/9',
                borderRadius: '1rem',
                width: '100%',
                height: 'auto'
            }}
            />
        </header>
        <Box sx={{ mx: '2px' }}>
            <Skeleton variant="rectangular" width={'100%'} height={20} sx={{ borderRadius: '1rem', mt: 1 }} />
            <Skeleton variant="rectangular" width={'100%'} height={20} sx={{ borderRadius: '1rem', mt: 1 }} />
            <Skeleton variant="rectangular" width={'100%'} height={20} sx={{ borderRadius: '1rem', mt: 1 }} />
            <Skeleton variant="rectangular" width={'66%'} height={20} sx={{ borderRadius: '1rem', mt: 1 }} />
        </Box>
    </article>);
}

export default LoadingPropertyCard;


