import { Box, Button, Icon, Typography } from "@mui/material";
import ChartComponent from "app/components/ChartComponent";
import TitleWrapper from "./components/TitleWrapper";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import { usersApi } from "app/redux/api/usersApi";
import useAuth from "app/hooks/useAuth";
import { useEffect, useState } from "react";
import { formatToUSDValue } from "app/utils/utils";
import dayjs from "dayjs";
/**
[
    {
        "amount": 262800,
        "balance": 584,
        "date": "2024-01-19T15:41:37.273273",
        "description": "Property PROP_7855685 registration, 584 GLB_0745 minted.",
        "operation_type": "CREDIT",
        "price": 450,
        "quantity": 584,
        "ticker": "GLB_0745"
    }
]
 */

const walletSchema: ITableSchema[] = [
    {
        id: 'photo',
        label: '',
        customComponent: () => <Icon>token</Icon>,
        sx: { width: '3rem' },
        align: 'center'
    },
    {
        id: 'token',
        label: 'Token',
    },
    {
        id: 'balance',
        label: 'Balance',
        customComponent: ({ value }) => formatToUSDValue(value)
    },
    {
        id: 'in_order',
        label: 'In Order',
        customComponent: ({ value }) => formatToUSDValue(value)
    },
    {
        id: 'available',
        label: 'Available',
        customComponent: ({ value }) => formatToUSDValue(value)
    }
]

const expandibleWalletSchema: ITableSchema[] = [
    {
        id: 'date',
        label: 'Date',
        customComponent: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'operation_type',
        label: 'Operation Type',
    },
    {
        id: 'price',
        label: 'Price',
        customComponent: ({ value }) => formatToUSDValue(value)
    },
    {
        id: 'quantity',
        label: 'Quantity',
    },
]

const InvestmentPage = () => {
    const [getWallet, { isLoading }] = usersApi.endpoints.getWallet.useLazyQuery()
    const { user } = useAuth()
    const [tokens, setTokens] = useState<any[]>([])

    useEffect(() => {
        user && user.getAsyncToken().then(token => {
            getWallet({ token }).unwrap()
                .then(tokens => {
                    console.log('tokens', tokens)
                    setTokens(tokens)

                })
                .catch(e => console.log(e))
        })
        // eslint-disable-next-line
    }, [user])



    return (<>

        <TitleWrapper title='Investment' />
        <Box aria-label='balances-info' sx={{
            width: '100%',
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            px: '2rem',

        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: '1rem',
            }}>

                <Typography variant="title3" component='p' >
                    Estimated Balance
                </Typography>
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ marginRight: '1rem' }}
                    >
                        Deposit
                    </Button>
                    <Button
                        sx={{ marginRight: '1rem' }}
                        size="small"
                    >
                        Withdraw
                    </Button>
                    <Button

                        size="small"
                    >
                        Buy Crypto
                    </Button>
                </div>
            </Box>
            <Box>
                <Typography variant="subtitle1" fontWeight='400' >
                    ≈ 0.00 USD
                </Typography>
            </Box>
        </Box>


        <Box aria-label='graf' sx={{
            width: '100%',
            height: '300px',
        }}>
            <ChartComponent dummy />

        </Box>

        <Box aria-label='assets'>
            <Typography variant="title3" component='p' mt={3}>
                My Assets
            </Typography>

            <RenvanceTable
                rows={tokens}
                loading={isLoading}
                schema={walletSchema}
                expandibleSchema={expandibleWalletSchema}
                expandibleTableName='Movements'
                actions={['expand']}
            />

        </Box>



    </>);
}

export default InvestmentPage;