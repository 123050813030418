import { Box, InputBaseProps, Menu, MenuItem, SxProps, TextField } from "@mui/material";
import RangePicker from "./RangePicker";
import { useState, MouseEvent, Fragment, useEffect, ReactNode } from "react";
import { addDays, subDays } from "date-fns";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { min } from "lodash";


type selectionType = {
    startDate: Date,
    endDate: Date,
}


interface RangeCalendarFieldProps {
    sx?: SxProps,
    name?: string,
    placeholder?: string,
    label?: string,
    onRangeSelect?: (selection: selectionType) => void,
    error?: boolean,
    helperText?: ReactNode,
    defaultValue?: { startDate?: Date, endDate?: Date },
    onBlur?: InputBaseProps['onBlur']
    options?: { //? next steps...
        minDate?: Date,
        maxDate?: Date,
        minDiffDays?: number,
        maxDiffDays?: number

    }

}

const fixedName = (selection: selectionType) => `${selection.startDate.toLocaleDateString()} - ${selection.endDate.toLocaleDateString()}`

const RangeCalendarField = ({ sx, name, onBlur, placeholder = "DD/MM/YYYY - DD/MM/YYYY", label = '', onRangeSelect, error, helperText, defaultValue }: RangeCalendarFieldProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [rangeState, setRangeState] = useState<any>([
        {
            startDate: defaultValue?.startDate ?? subDays(new Date(), 7),
            endDate: defaultValue?.endDate ?? addDays(new Date(), 7),
            key: "selection"
        }
    ]);
    const [textfieldValue, setTextfieldValue] = useState<string>(() => {
        if (defaultValue && defaultValue.endDate && defaultValue.startDate) {
            return fixedName(defaultValue as any)
        }
        return ''
    });
    const [countSelections, setCountSelections] = useState<number>(0);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setCountSelections(0);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (countSelections > 1) {
            handleClose();
        }

    }, [countSelections])

    const handleOnRangeSelect = (ranges: any) => {
        const selection = ranges.selection;
        setTextfieldValue(fixedName(selection));
        onRangeSelect && onRangeSelect(selection);
        setCountSelections(countSelections + 1);
        setRangeState([selection]);
    };



    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <TextField
                    label={label}
                    InputProps={{
                        startAdornment: <DateRangeIcon sx={{ mr: 1 }} />
                    }}
                    size="small"
                    sx={sx}
                    aria-controls={open ? 'data-range' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    placeholder={placeholder}
                    onClick={handleClick}
                    value={textfieldValue}
                    name={name}
                    onBlur={onBlur}
                    error={error}
                    helperText={helperText}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="data-range"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <MenuItem disableRipple>
                    <RangePicker onChange={handleOnRangeSelect} state={rangeState} />

                </MenuItem>
            </Menu>
        </Fragment>
    );
}

export default RangeCalendarField;

