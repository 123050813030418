//import useSettings from "app/hooks/useSettings";


const RenvanceLogo = ({ className, color = 'white' }: { className?:  any, color?: any }) => {
  //const { settings } = useSettings();
  //const theme = settings.themes[settings.activeTheme];

  return (
    // <svg
    // width="24px"
    // height="24px"
    //   className={className}
    //   viewBox="0 0 240 239"
    //   version="1.1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   xmlnsXlink="http://www.w3.org/1999/xlink"

    // >
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="36px"
      height="36px" viewBox="0 0 240 239"
      preserveAspectRatio="xMidYMid meet" className={className}>
      <g transform="translate(0.000000,244.000000) scale(0.100000,-0.100000)" fill="transparent">
        <path 
        d="M0 1220 l0 -1220 1150 0 1150 0 0 1220 0 1220 -1150 0 -1150 0 0
              -1220z m1985 967 c51 -23 91 -61 118 -112 22 -40 22 -42 25 -830 2 -703 1
              -795 -13 -832 -33 -85 -94 -134 -191 -153 -42 -8 -279 -10 -809 -8 l-750 3
              -48 23 c-26 13 -60 40 -77 60 -62 75 -60 50 -60 901 l0 776 24 50 c34 73 89
              116 171 134 22 5 378 9 805 8 714 -2 768 -3 805 -20z" fill="transparent" stroke="white" />
        <path d="M342 2123 c-38 -19 -74 -63 -91 -112 -18 -54 -15 -1523 3 -1574 18
                -50 42 -76 91 -98 38 -18 84 -19 815 -19 875 0 815 -6 875 79 l30 43 3 766 c2
                516 0 779 -7 803 -15 49 -45 86 -92 109 -37 19 -61 20 -816 20 -700 0 -781 -2
                -811 -17z m907 -307 l83 -84 -335 -338 -336 -339 -88 87 -88 88 120 122 c340
                348 541 548 550 548 6 0 48 -38 94 -84z m249 -249 l83 -84 -338 -342 -338
                -343 -87 89 -88 88 331 338 c182 185 336 337 343 337 6 0 49 -38 94 -83z m241
                -251 l84 -85 -161 -168 c-88 -92 -239 -246 -334 -341 l-173 -172 -88 88 -88
                88 332 337 c182 185 334 337 338 337 3 0 44 -38 90 -84z" stroke="#FFF" />
        <path style={{ fill: color }} d="M865 1525 l-288 -296 41 -42 42 -42 121 120 c66 66 196 199 289 296 l169 176 -43 42 -42 42 -289 -296z" fill="#FFF" />
        <path style={{ fill: color }} d="M1116 1276 l-288 -295 39 -45 38 -45 73 72 c41 40 172 174 292 298 l219 226 -43 42 -43 42 -287 -295z" fill="#FFF" />
        <path style={{ fill: color }} d="M1357 1018 l-286 -293 42 -43 41 -42 49 47 c27 27 157 160 289 296 l241 248 -38 39 c-22 22 -42 40 -46 40 -4 0 -135 -132 -292 -292z" fill="#FFF" />
      </g>
    </svg>
  );
};

export default RenvanceLogo;
