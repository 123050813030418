
import LogoBig from './logo-big.png';
import LogoBrand from '../svg/logo-brand.svg';
import bannerBusiness from './banner-business.png';
import propertyPlaceholder from './property_placeholder.jpg';
import MetamaskIcon from './metamask.png';
import CoinbaseIcon from './coinbase.png';
import TetherIcon from './tether-usdt-logo.png'
import idCard from './idCard.png';

import frontIdCard from './frontIdCard.png';
import backIdCard from './backIdCard.png';


import dummyProp1 from './dummy/prop1.png';
import dummyProp2 from './dummy/prop2.png';
import dummyProp3 from './dummy/prop3.png';
import dummyProp4 from './dummy/prop4.png';
import dummyProp5 from './dummy/prop5.png';
import dummyProp6 from './dummy/prop6.png';
import dummyProp7 from './dummy/prop7.png';

import gaonaPlano from '../images/landing/gaona_plano.jpg'
import gaonaSum from '../images/landing/gaona_sum.jpg'
import villaroelPlano from '../images/landing/villaroel_plano.jpg'
import velazcoFrente from '../images/landing/velazco_frente.jpg'
import velazcoInterior from '../images/landing/velazco_interior.jpg'
import lookingProps from '../images/landing/looking_props.png'

import choosing from '../images/landing/choosing.png'
import seeReturns from '../images/landing/see_returns.png'
import addToPortfolio from '../images/landing/add_to_portfolio.svg'
import plano2 from '../images/landing/plano2.png'
import edificioVillacrespo from '../images/landing/edificio_villacrespo.jpg'
import nordeltaDepto from '../images/landing/nordelta_depto.jpg'
import edificioArbolado from '../images/landing/edificio_arbolado.jpg'
import ourBussinessLoking from '../images/landing/our_bussiness_looking.png'
import ourBussinessSombras from '../images/landing/sombras_que_hacemos.png'
import texturaFondo from '../images/landing/textura_fondo.png'

const landing_photos = {
    gaonaPlano,
    gaonaSum,
    villaroelPlano,
    velazcoFrente,
    velazcoInterior,
    lookingProps,
    plano2,
    choosing,
    seeReturns,
    addToPortfolio,
    edificioVillacrespo,
    nordeltaDepto,
    edificioArbolado,
    ourBussinessSombras,
    texturaFondo
}






export {
    LogoBig, LogoBrand, bannerBusiness, propertyPlaceholder, MetamaskIcon, CoinbaseIcon, TetherIcon, idCard,
    frontIdCard, backIdCard, landing_photos, ourBussinessLoking,

    dummyProp1, dummyProp2, dummyProp3, dummyProp4, dummyProp5, dummyProp6, dummyProp7
};
