import { LoadingButton } from "@mui/lab";
import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import { FlexBox } from "app/components/FlexBox";
import { Paragraph } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { RefObject, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { GoogleButton } from "./AuthModal";
import ReCAPTCHA from "react-google-recaptcha";


const validationSchema = object().shape({
    name: string().min(1, "Name must be at least 2 characters long").required("Name is required!"),
    lastname: string().min(1, "Lastname must be at least 2 characters long").required("Lastname is required!"),
    password: string().min(6, "Password must be 6 character length").required("Password is required!"),
    email: string().email("Invalid Email address").required("Email is required!"),
});
const initialValues = {
    name: "",
    lastname: "",
    email: "",
    password: "",
    remember: true,
};

const SignUpForm = ({ captchaRef }: { captchaRef: RefObject<ReCAPTCHA> }) => {
    const { createUserWithEmail, signInWithGoogle } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const handleGoogleRegister = async () => {
        try {
            await signInWithGoogle();
            navigate("/");
        } catch (e) {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (values: typeof initialValues) => {
        const token = await captchaRef?.current?.executeAsync();
        if (!token) return

        try {
            setLoading(true);
            await createUserWithEmail(values.email, values.password, values.name, values.lastname);

            navigate("/");
            enqueueSnackbar("Register Successfully!", { variant: "success" });
        } catch (e: any) {
            setLoading(false);
            enqueueSnackbar(e.message, { variant: "error" });
        }
    };



    return (
        <div className="form-container sign-up-container">
            <form>
                <h1>Crear cuenta</h1>
                <div className="social-container">
                    <GoogleButton
                        fullWidth
                        variant="contained"
                        onClick={handleGoogleRegister}
                        startIcon={<img src="/assets/images/logos/google.svg" alt="google" />}
                    >
                        Seguír con Google
                    </GoogleButton>
                </div>
                <Typography my='1rem'>o registrate con tu email</Typography>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.name}
                                        onChange={handleChange}
                                        helperText={touched.name && errors.name}
                                        error={Boolean(errors.name && touched.name)}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="lastname"
                                        label="Lastname"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.lastname}
                                        onChange={handleChange}
                                        helperText={touched.lastname && errors.lastname}
                                        error={Boolean(errors.lastname && touched.lastname)}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                type="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                                sx={{ mb: 3 }}
                            />
                            <TextField
                                fullWidth
                                size="small"
                                name="password"
                                type="password"
                                label="Password"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(errors.password && touched.password)}
                                sx={{ mb: 1.5 }}
                            />

                            <FlexBox gap={1} alignItems="center">
                                <Checkbox
                                    size="small"
                                    name="remember"
                                    onChange={handleChange}
                                    checked={values.remember}
                                    sx={{ padding: 0 }}
                                />

                                <Typography fontSize={13}>
                                    I have read and agree to the terms of service.
                                </Typography>
                            </FlexBox>

                            <LoadingButton
                                type="submit"
                                color="primary"
                                loading={loading}
                                variant="contained"
                                sx={{ my: 2 }}
                            >
                                Regiser
                            </LoadingButton>

                            <Paragraph>
                                Already have an account?
                                <NavLink
                                    to="/session/signin"
                                    style={{ marginLeft: 5 }}
                                >
                                    Login
                                </NavLink>
                            </Paragraph>
                        </form>
                    )}
                </Formik>
            </form>
        </div>
    );
}

export default SignUpForm;
