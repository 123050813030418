import { Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box, styled } from '@mui/system';
import PageContainer from "../../components/PageContainer";


const ContactImg1 = "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/webapp/home/contact-img.png"

const ContactSection = () => {
  return (
    <PageContainer id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <Typography variant="title1">
              Estamos para ayudarte
            </Typography>
            <Typography variant="subtitle1">
              Dejanos tus dudas y consultas que responderemos a la brevedad.
            </Typography>
          </HeaderInfo>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: { md: '50%' } }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    size="small"

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tema"
                    variant="outlined"
                    size="small"

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Consulta"
                    variant="outlined"
                    size="small"

                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" style={{ borderRadius: '0.8rem', fontSize: '1.2rem' }}>
                    Enviar consulta
                  </Button>
                </Grid>
              </Grid>


            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
              <ImgWrapper className="flexCenter">
                <img src={ContactImg1} alt="add"
                  style={{
                    width: '556px',
                    height: '407px',
                    borderRadius: '2rem',
                    objectFit: 'cover'
                  }}

                />
              </ImgWrapper>
            </Box>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
export default ContactSection;

const HeaderInfo = styled('div')({
  padding: "5px 0",
  '@media (max-width: "860px")': {
    textAlign: "center"
  }
});

const ImgWrapper = styled('div')({
  width: '100%',
  padding: '0 15%',
  img: {
    width: '100%',
    height: 'auto'
  },
  '@media (max-width: 400px)': {
    padding: '0'
  }
});