

export enum InvestmentType {
    CROWD_FUNDING = 'CROWD_FUNDING',
    OPEN_MARKET = 'OPEN_MARKET',
    MANAGE = 'MANAGE'
}


export type amenitieType = 'POOL' | 'BEDS' | 'WIFI' | 'GRILL' | 'GYM' | 'LAUNDRY' | 'SOL' | 'PARKING' | 'SAUNA' | 'GAMES' | 'CAR' | 'TERRACE'


/**
 *  @description Complete model of a property
 */
export interface Property {
    property_uuid: string
    property_code: string
    building_type: string
    name: string
    address: string
    city: string
    dorms: number
    bathrooms: number
    parking_lots: number
    state: string
    country: string
    zipcode: string
    description: string
    amenities: amenitieType[] | any[]
    pictures: string[]
    balance: number
    surface: number
    floor: number
    apartment: string
    economics: {
        active_buying: boolean
        active_renting: boolean
        active_selling: boolean
        cf_interest: number
        cf_type: string
        created_at: string
        current_market_asset_value: number
        expected_net_yearly_rent_percentage: number
        fund_raising_end: string
        fund_raising_start: string
        id: number
        investment_type: InvestmentType
        re_appreciation_period: string
        Construction_type_code: string
        construction: {
            asset_value: number
            asset_value_at_completed: number
            expected_asset_value: number
            construction_completed: string
            construction_started: string
            construction_status: string
        }
        current_market_value: number
        current_reference_value: number
        estimation: {
            cost_apartment_expenses: number
            cost_building_expenses: number
            cost_insurance: number
            cost_taxes: number
            gross_rent_yearly_percentage: number
            gross_rent_yearly_raw: number
            net_rent_yearly_percentage: number
            occupancy: number
            platform_management_commission_percentage: number
            platform_management_commission_raw: number
            property_management_commission_percentage: number
            property_management_commission_raw: number
        }
        minimal_permanency_period: string
        plan_code: string
        rent_distribution_period: string
        total_investment: {
            administrative_fees: number
            decoration_n_furniture: number
            initial_asset_value: number
            platform_buy_commision_amount: number
            property_buy_commission_amount: number
        }
    }
    token: {
        token_available: number
        active: boolean
        deployment_date: string
        id: number
        modified_at: string
        token_address: string
        token_amount: number
        token_description: string
        token_in_market: number
        token_name: string
        token_price: number
        token_ticker: string
        updated_by: string
        uuid: string
    }
    manager: {
        company_name: string
        contact_name: string
        email: string
        phone: string
        id: number
    }
}
