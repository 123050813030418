import { Button, Grid, Icon, Skeleton, useMediaQuery } from "@mui/material"
import { styled } from "@mui/system"
import { useState } from "react"
import PropertyCarrousel from "./PropertyCarrousel"

const BigImage = styled("img")(({ theme }) => {
    const height = useMediaQuery(theme.breakpoints.up('md')) ? '406px' : '310px'

    return {
        width: '100%',
        height,
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }
})
const LoadingBigImage = styled(Skeleton)(({ theme }) => {
    const height = useMediaQuery(theme.breakpoints.up('md')) ? '406px' : '310px'
    return {
        width: '100%',
        height,
        borderRadius: '10px',
    }

})


const ChildImage = styled('img')(({ theme }) => {
    const height = useMediaQuery(theme.breakpoints.up('md')) ? '200px' : '100px'
    return {
        OObjectFit: 'cover',
        width: '100%',
        height,
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }
})
const LoadingChildImage = styled(Skeleton)(({ theme }) => {
    const height = useMediaQuery(theme.breakpoints.up('md')) ? '200px' : '100px'
    return {
        width: '100%',
        height,
        minHeight: height,
        borderRadius: '10px',
    }
})


const PropertyImages = ({ images, loading }: { images?: string[], loading: boolean }) => {
    const [activeCarrousel, setActiveCarrousel] = useState(false)


    return (<>
       {images && <PropertyCarrousel images={images} open={activeCarrousel} onClose={() => setActiveCarrousel(false)} />}
        <Grid container aria-label='property-images'>
            <Grid item md={7} xs={12} sx={{ pr: { md: 1 } }}>

                {loading ? <LoadingBigImage animation='wave' variant='rectangular' width='100%' height='100%' /> : images && images.length > 0 &&  <BigImage src={images[0]} alt="Main Photo" />}



            </Grid>
            <Grid item md={5} xs={12} sx={{ pl: { md: 1 } }}>
                <Grid container>
                    <Grid item md={6} xs={3} pr={1} pb='1px'>
                        {loading ? <LoadingChildImage sx={{ bottom: '2px ' }} animation='wave' variant='rectangular' width='100%' height='100%' /> : images && images.length > 0 && <ChildImage src={images[1]} alt="property photo 2" />}
                    </Grid>
                    <Grid item md={6} xs={3} pr={{ xs: 1, md: 0 }}>
                        {loading ? <LoadingChildImage sx={{ bottom: '2px ' }} animation='wave' variant='rectangular' width='100%' height='100%' /> :images && images.length > 1 && <ChildImage src={images[2]} alt="property photo 3" />}
                    </Grid>
                    <Grid item md={6} xs={3} pr={1}>
                        {loading ? <LoadingChildImage sx={{ top: '2px ' }} animation='wave' variant='rectangular' width='100%' height='100%' /> :images && images.length > 2 && <ChildImage src={images[3]} alt="property photo 4" />}
                    </Grid>
                    <Grid item md={6} xs={3} position='relative'>
                        {loading ? <LoadingChildImage sx={{ top: '2px ' }} animation='wave' variant='rectangular' width='100%' height='100%' /> :images && images.length > 3 && <ChildImage src={images[4]} alt="property photo 5" />}
                        <div style={{
                            position: 'absolute',
                            right: '15px',
                            bottom: '20px',

                        }}>
                            {!loading && <Button
                                startIcon={<Icon>fullscreen</Icon>}
                                variant="contained" color="primary" sx={{ width: '100%' }} onClick={() => setActiveCarrousel(true)}>
                                See all photos
                            </Button>}
                        </div>
                    </Grid>
                </Grid>
            </Grid >
        </Grid >



    </>)
}

export default PropertyImages