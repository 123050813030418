import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import useAuth from "app/hooks/useAuth";
import { companiesApi } from "app/redux/api/companiesApi";
import { useEffect, useState } from "react";
import PageWrapper from "../Layout/PageWrapper";



const usersTableSchema: ITableSchema[] = [
    {
        id: 'uid',
        label: 'UID',
    },
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'lastname',
        label: 'Lastname',
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'role',
        label: 'Role',
    },
]



const ListCompanyUserPage = () => {

    const { user } = useAuth();
    const [getCompanyUsers, { isLoading }] = companiesApi.endpoints.getCompanyUsers.useLazyQuery();
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        if (!user) return;
        user.getAsyncToken().then(token => {
            const companyUUID = user.claims.company_uuid;
            getCompanyUsers({ token, company: companyUUID })
                .unwrap()
                .then((users) => setUsers(users.map(
                    (user: any) => ({
                        uid: user.uid,
                        name: user.name,
                        lastname: user.lastname,
                        phoneNumber: user.phone_number,
                        role: user.role,
                        email: user.email,
                        company: user?.company?.company_name
                    })

                )))
                .catch((err) => {
                    console.log(err);
                });
        });

    }, [user]);

    return (<PageWrapper>
        <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
            Company Users
        </Typography>

        <Box mt={4}>
            <RenvanceTable
                schema={usersTableSchema}
                rows={users}
                loading={isLoading}
            />
        </Box>

    </PageWrapper>);
}

export default ListCompanyUserPage;