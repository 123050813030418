import { createApi } from '@reduxjs/toolkit/query/react';
import { PathApis } from 'config';
import { axiosBaseQuery, buildHeaders } from './baseApi';



export const investorsApi = createApi({
    reducerPath: 'InvestorsApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    tagTypes: ['Investors'],
    endpoints: ({ mutation }) => ({
        approveIdentificationRequest: mutation<any, { token: string, data: any }>({
            query: ({ token, data }) => ({
                url: '/users/approve_identification_request',
                method: 'POST',
                data,
                body: data,
                headers: buildHeaders(token)
            })
        })
    })
});

export const {
    useApproveIdentificationRequestMutation,
} = investorsApi;
