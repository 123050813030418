import { Button, Grid, Icon, Paper, TextField, Typography } from "@mui/material";
import ModalAddStatement from "app/components/Modals/ModalAddStatement";
import RangeCalendarField from "app/components/RangeCalendarField";
import StatementTable from "app/components/Tables/StatementTable";
import useAuth from "app/hooks/useAuth";
import { PropertyStatementHistoryRequest, PropertyStatementHistoryResponse, propertiesApi } from "app/redux/api/propertiesApi";
import { useEffect, useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import { LoadingButton } from "@mui/lab";

const StatementHistoryPage = () => {
    const { user } = useAuth();

    const [filters, setFilters] = useState<PropertyStatementHistoryRequest>({
        propertyUUID: '',
        startDate: '',
        endDate: '',
        keyword: '',
        token: 'COMPANY_USER'
    })
    const [movements, setMovements] = useState<PropertyStatementHistoryResponse[]>([]);
    const [fetchGetMovements, { data, isLoading, isUninitialized }] = propertiesApi.endpoints.getPropertyStatementsMovements.useLazyQuery();
    const [openAddStatement, setOpenAddStatement] = useState(false);
    const [searching, setSearching] = useState(false);

    const getMovements = () => {
        user.getAsyncToken().then((token) => {
            fetchGetMovements({ ...filters, token }, true).unwrap().then((movs) => {
                setMovements(movs);
            }).catch((err) => {
                setMovements([]);
            }).finally(() => {
                setSearching(false);
            });
        }).catch((err) => { console.log(err) });

    }



    useEffect(() => {
        if (user) {
            isUninitialized && getMovements();
            !isLoading && data && console.log(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);



    return (
        <PageWrapper>
            <Typography variant="title1">Statement</Typography>
            <Paper aria-label='buscador' sx={{ p: 3, mb: 5 }}>
                <Typography variant="title2" pb={1} pt={3} component={'p'}>Statement history</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Property code" variant="outlined" size="small"
                            onChange={(e) => setFilters({ ...filters, propertyUUID: e.target.value as string })}
                            value={filters.propertyUUID}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RangeCalendarField
                            sx={{ width: '100%' }}
                            onRangeSelect={({ startDate, endDate }) => {
                                setFilters({ ...filters, startDate: startDate.toISOString(), endDate: endDate.toISOString() })
                            }}
                        />

                    </Grid>
                    <Grid item xs={12} md={2}>
                        <LoadingButton
                            onClick={() => { setSearching(true); getMovements() }}
                            loading={searching}
                            loadingPosition="start"
                            startIcon={<Icon>search</Icon>}
                            variant="contained"
                        >
                            <span>Search</span>
                        </LoadingButton>
                    </Grid>
                </Grid>

            </Paper>

            <Button
                startIcon={<Icon>post_add</Icon>}
                variant="contained" color="primary" sx={{ mb: 5 }}
                onClick={() => setOpenAddStatement(true)}
            >Add Statement</Button>

            <ModalAddStatement open={openAddStatement} onClose={() => setOpenAddStatement(false)} />

            <StatementTable movements={movements} isLoading={isLoading} />
        </PageWrapper>);
}

export default StatementHistoryPage;