import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import BankTransfer from "./BankTransfer";
import CashTranfer from "./CashTransfer";
import CryptoTransfer from "./Crypto/CryptoTransfer";


const buttonVariants: Variants = {
    selected: {
        backgroundColor: "#34314c",
        color: "white",
        transition: { type: "spring", duration: 0.3 },
    },
    unselected: {
        backgroundColor: "white",
        color: "#34314c",
        transition: { ease: "easeOut", duration: 2 },
    }
}



const ButtonTypeDeposit = ({ title, isSelected, onClick }: { title: string; isSelected: boolean; onClick: () => void }) => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start(isSelected ? "selected" : "unselected");
    }, [isSelected, controls]);

    return (
        <motion.div
            whileHover={{ cursor: 'pointer' }}
            variants={buttonVariants}
            initial={isSelected ? "selected" : "unselected"}
            animate={controls}
            onClick={onClick}
            style={{
                width: '100%',
                height: '3.5rem',
                border: '1px solid #E0E0E0',
                borderRadius: '0.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="subtitle2">
                {title}
            </Typography>
        </motion.div>
    );
};

export enum DepositType {
    BANK_TRANSFER_USD = 'Bank Transfer (USD)',
    BANK_TRANSFER_AR = 'Bank Transfer (AR$)',
    CASH = 'Cash',
    CRYPTO = 'Crypto'
}






const dummy_config_usd: any = {
    accountNumber: '123456789',
    alias: 'renvance.mp.usd',
    accountType: 'Current account',
    cbuAccount: '12345678990213',
}
const dummy_config_ars: any = {
    accountNumber: '921030912',
    alias: 'renvance.uala.args',
    accountType: 'Cuenta Corriente',
    cbuAccount: '529102348',
}



const DepositPage = () => {
    const [selectedButton, setSelectedButton] = useState<DepositType>(DepositType.CRYPTO);

    const handleButtonClick = (title: DepositType) => {
        setSelectedButton(title);
    };

    return (
        <>
            <Box component='section' sx={{
                height: '10rem',
                color: '#34314C',
            }}>
                <Container maxWidth='lg'>
                    <Box sx={{
                        mx: '4rem',
                        pt: 4,
                    }}>
                        <Typography variant='title1' component='h1'>
                            Deposit funds
                        </Typography>
                        <Typography variant='subtitle1' component='h2'>
                            Choose your preferred payment method and start investing!
                        </Typography>
                    </Box>
                </Container>

            </Box>
            <PageContainer>


                <header style={{
                    margin: '1rem 4rem 2rem 4rem',
                }}>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}

                        style={{
                            'display': 'grid',
                            'gridTemplateColumns': 'repeat(4, 1fr)',
                            'gap': '1rem'
                        }}
                    >
                        <ButtonTypeDeposit
                            title='Bank Transfer (USD)'
                            isSelected={selectedButton === DepositType.BANK_TRANSFER_USD}
                            onClick={() => handleButtonClick(DepositType.BANK_TRANSFER_USD)}
                        />
                        <ButtonTypeDeposit
                            title='Bank Transfer (AR$)'
                            isSelected={selectedButton === DepositType.BANK_TRANSFER_AR}
                            onClick={() => handleButtonClick(DepositType.BANK_TRANSFER_AR)}
                        />
                        <ButtonTypeDeposit
                            title='Cash'
                            isSelected={selectedButton === DepositType.CASH}
                            onClick={() => handleButtonClick(DepositType.CASH)}
                        />
                        <ButtonTypeDeposit
                            title='Crypto'
                            isSelected={selectedButton === DepositType.CRYPTO}
                            onClick={() => handleButtonClick(DepositType.CRYPTO)}
                        />
                    </motion.div>
                </header>
                <section style={{
                    margin: '1rem 5rem auto',
                }}>
                    {selectedButton === DepositType.BANK_TRANSFER_USD && <BankTransfer config={dummy_config_usd} />}
                    {selectedButton === DepositType.BANK_TRANSFER_AR && <BankTransfer config={dummy_config_ars} />}
                    {selectedButton === DepositType.CASH && <CashTranfer />}
                    {selectedButton === DepositType.CRYPTO && <CryptoTransfer />}
                </section>
            </PageContainer>
        </>
    );
};

export default DepositPage;