import { Box, Typography } from "@mui/material";
import PageWrapper from "../../Layout/PageWrapper";
import RegisterExpenseTable from "./RegisterExpensesTable";

const RegisterExpensePage = () => {
    return (
        <PageWrapper>

            <Typography variant="title1">Register Expenses</Typography>
            <Box sx={{ p: 3 }} >
                <RegisterExpenseTable />
            </Box>



        </PageWrapper>);
}

export default RegisterExpensePage;