import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import useAuth from "app/hooks/useAuth";
import { useRegisterUserMutation } from "app/redux/api/usersApi";
import { UserRole } from "app/redux/models";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import { object, string } from "yup";
import PageWrapper from "../Layout/PageWrapper";



function dummySleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });
}

const dummyCompanies = [
    {
        name: 'Company 1',
        code: 'code 1'
    },
    {
        name: 'Company 2',
        code: 'code 2'
    },
    {
        name: 'Company 3',
        code: 'code 3'
    }

]


interface Company {
    name: string;
    code: string;
}



const TextFieldForm = ({ name, label, formik }: { name: any, label: string, formik: any }) => {
    return (
        <TextField fullWidth label={label} name={name} variant="outlined" size="small"
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    )
}



const createUserSchema = object().shape({
    name: string().required(),
    lastname: string().required(),
    phone_number: string().required(),
    email: string().email().required(),
    address: string().required(),
    date_of_birth: string().required('Required').test(
        'is-over-18', 'Must be over 18 years old', (value) => {
            const now = dayjs()
            const dob = dayjs(value)
            return now.diff(dob, 'year') >= 18
        }
    ),
    role: string().required(),
    password: string().required(),

});


const CreateUserPage = () => {

    const [options, setOptions] = useState<readonly Company[]>([]);
    const [registerUser, { isLoading }] = useRegisterUserMutation();

    const { user } = useAuth();
    const formik = useFormik({
        initialValues: {
            name: '',
            lastname: '',
            phone_number: '',
            email: '',
            address: '',
            date_of_birth: '',
            role: UserRole.AGENCY_USER,
            password: ''
        },
        validationSchema: createUserSchema,
        onSubmit: async (data) => {
            const token = await user.getAsyncToken()
            const date_of_birth = dayjs(data.date_of_birth).format('YYYY-MM-DD')
            registerUser({ token, data: { ...data, date_of_birth } }).unwrap()
                .then(() => {
                    enqueueSnackbar('You will be notified when the user is created', {
                        variant: 'success', autoHideDuration: 5000,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
                    })
                })
                .catch((err: any) => {
                    enqueueSnackbar("Couldn't create user", {
                        variant: 'error', autoHideDuration: 5000,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
                    })
                })
        },
    });



    return (<PageWrapper>
        <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
            Create {user.claims.company_name} User
        </Typography>

        <Box component={'section'} margin='0 auto' mt={4} display='flex' justifyContent='center'>
            <Grid container spacing={2} p={{ sm: 0, md: '0 6rem' }} maxWidth={'55rem'} >
                <Grid item xs={12} md={6}>
                    <TextFieldForm name='name' label='Name' formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldForm name='lastname' label='Lastname' formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldForm name='email' label='Email' formik={formik} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DatePicker label="Date of Birth" value={formik.values.date_of_birth} onChange={(value) => formik.setFieldValue('date_of_birth', value)}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: Boolean(formik.errors.date_of_birth),
                                helperText: formik.touched.date_of_birth && formik.errors.date_of_birth
                            }
                        }}
                        format="dd/MM/yyyy"

                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextFieldForm name='phone_number' label='Phone Number' formik={formik} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextFieldForm name='address' label='Address' formik={formik} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl fullWidth size='small'>
                        <InputLabel >Role</InputLabel>
                        <Select
                            value={formik.values.role}
                            label="Role"
                            onChange={(e) => formik.setFieldValue('role', e.target.value as UserRole)}

                        >
                            <MenuItem value={UserRole.AGENCY_ADMIN}> Agency Admin </MenuItem>
                            <MenuItem value={UserRole.AGENCY_USER}> Agency User </MenuItem>
                            <MenuItem value={UserRole.DEVELOPER_ADMIN}> Developer Admin </MenuItem>
                            <MenuItem value={UserRole.DEVELOPER_USER}> Developer Admin </MenuItem>

                        </Select>
                        <FormHelperText>
                            {formik.touched.role && formik.errors.role}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextFieldForm name='password' label='Password' formik={formik} />
                </Grid>

                <Grid item xs={12} textAlign='end'>
                    <Button variant="contained" color="primary" onClick={() => formik.handleSubmit()}>
                        Create
                    </Button>
                </Grid>
            </Grid>
        </Box>


    </PageWrapper>);
}

export default CreateUserPage;

function enqueueSnackbar(arg0: string, arg1: { variant: string; autoHideDuration: number; anchorOrigin: { vertical: string; horizontal: string; }; }) {
    throw new Error("Function not implemented.");
}
