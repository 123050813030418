import { Box, Button, Icon, Typography } from "@mui/material";
import useAuth from "app/hooks/useAuth";
import TitleWrapper from "./components/TitleWrapper";


const ListItem = ({ title, description, icon, content = '' }: { title: string, description?: string, icon: string, content?: string | JSX.Element | null }) => {

    return (<Box aria-label='list-item' sx={{
        width: '100%',
        backgroundColor: 'white',
        padding: '1rem 3rem 1rem 3rem',
        fontSize: '2rem',
        display: 'flex',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    }}
        component='li'

    >
        <div style={{ display: 'flex', flex: '8 1 0%' }}>
            <Icon fontSize="medium">{icon}</Icon>
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>

                <Typography variant="title3" component='p' color='black' fontWeight='inherit'>
                    {title}
                </Typography>
                <Typography variant="subtitle2" component='p' color='dimgray'>
                    {description}
                </Typography>
            </div>
        </div>
        <div style={{ display: 'flex', flex: '6 1 0%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle2">
                {content}
            </Typography>
            <Button
                variant="contained"
                size="small"
                sx={{ marginLeft: '1rem', height: 'min-content' }}
            >
                Change
            </Button>
        </div>



    </Box>);

}


const SecurityPage = () => {
    const { user } = useAuth()
    return (<>
        <TitleWrapper title='Security' />

        <ul aria-label="list-container" style={{ marginTop: '1rem', padding: 0 }}>
            <ListItem title="Email" description="Use your email to login" icon="email" content={user.email} />
            <ListItem title="Phone Number" description="Use your number for recover your account" icon="phone" content={user.phoneNumber} />
            <ListItem title="Login Password" description="Change your login password" icon="key" />
            <ListItem title="Authenticator App" description="On" icon="lock_clock" content={'Off'} />
            <ListItem title="Passkeys" description="On" icon="sync_lock" content={'On'} />

        </ul>


    </>);
}

export default SecurityPage;