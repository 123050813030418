import { Box, Grid, SxProps, Typography } from "@mui/material";
import { landing_photos, ourBussinessLoking } from "assets/images";
import PageContainer from "../../components/PageContainer";
const BusinessCard2 = ({ img, title, description, sxImg }: { img: string, title: string, description: string, sxImg?: SxProps<any> }) => {
    return (
        <Grid item xs={12} md={4} justifyContent='space-between' sx={{ backgroundColor: '#FFF', borderRadius: '1rem', borderWidth: '1px', border: 'thin' }}>
            <Box display='flex' flexDirection='column'
                sx={{
                    margin: '0 auto',
                    height: "300px",
                    width: "310px",
                    position: "relative",
                    marginBottom: '2rem',
                }}
            >
                <Typography aria-label="title"
                    textAlign='center'
                    component='p'
                    variant='title3'
                >{title}
                </Typography>
                <div aria-label="description" style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "14rem",
                    zIndex: 2,
                    borderRadius: "10px",
                    backgroundColor: "white",
                    padding: "10px",

                }}>
                    <Typography variant='subtitle1' sx={{ lineHeight: 1.2, fontWeight: 400, color: "#2d6ea5", fontSize: "0.9rem" }}>{description}</Typography>
                </div>
                <Box
                    component="img"
                    src={img}
                    alt={title}
                    sx={{
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "1rem",
                        position: "relative",
                        ...sxImg
                    }}
                />
            </Box>
        </Grid>
    );
};

const SectionOurBusiness = () => {

    const data = {
        title: '¿Qué hacemos?',
        descriptionFirstParagraph: `
            Ofrecemos oportunidades de inversión en proyectos inmobiliarios para usuarios con diferentes perfiles. Aquellos mas arriesgados, 
            podrán invertir en proyectos de pozó donde obtendrán mayores ganancías. Mientras que los inversores mas tradicionales, 
            que quieran ver y tocar sus inversiones, podrán invertir en propiedades terminadas que generen renta mensual. 
        `,
        descriptionSecondParagraph: `
            De esta manera todos podrán ver crecer sus ingresos de forma segura y según su elección.
            Con nuestro smart portfolio, ofrecemos sugerencias de las inversiones que mejor se ajustan a tus preferencias. 
        `
    }
    const bgcolor = '#34628f'
    return (
        <section id='our-business' style={{ paddingTop: '3rem' }}>
            <PageContainer
                sx={{
                    backgroundColor: bgcolor,
                    padding: '2rem 0',
                    borderRadius: { md: '1rem' },
                    position: 'relative',
                    overflow: 'hidden',
                    height: { sm: '60rem', md: 'auto' },

                }}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item md={6} xs={12} sx={{ textAlign: 'center' }}>
                        <img src={ourBussinessLoking} alt='business' style={{ width: '80%' }} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ textAlign: 'center', position: 'relative' }}>
                        <Box sx={{ position: 'absolute', top: '2rem', zIndex: 3 }}>
                            <Typography component='h3' variant='title1'
                                sx={{ color: 'white', fontWeight: 700, fontSize: '2rem', textAlign: 'center' }}>{data.title}</Typography>
                            <Typography variant='subtitle1' sx={{ color: 'white', fontWeight: 400, fontSize: '1rem', textAlign: 'start', margin: '0 auto', width: '100%', lineHeight:1.5 }}>
                                {data.descriptionFirstParagraph}
                            </Typography>
                            <Typography variant='subtitle1' sx={{ color: 'white', fontWeight: 400, fontSize: '1rem', textAlign: 'start', margin: '0 auto', width: '100%', lineHeight:1.5 }}>
                                {data.descriptionSecondParagraph}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <span id="gradient"
                    style={{
                        position: 'absolute',
                        bottom: '-10rem',
                        right: '-10rem',
                        width: '24rem',
                        height: '24rem',
                        background: 'radial-gradient(circle, rgb(159 186 213 / 37%) 12%, rgb(52, 98, 143) 70%)',
                        borderRadius: '50%',
                        zIndex: 1

                    }}
                />
                <img src={landing_photos.texturaFondo} alt='business' style={{ width: '80%', position: 'absolute', zIndex: 0, top: 0 }} />
            </PageContainer>
        </section>
    );
}


export default SectionOurBusiness;