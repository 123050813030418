import { Suspense } from "react";
import RenvanceLoading from "./RenvanceLoading";

const Loadable = (Component : any ) => (props: any) =>
  (
    <Suspense fallback={<RenvanceLoading />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
