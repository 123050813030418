import { LoadingButton } from "@mui/lab";
import { Button, Divider, Grid, Icon, InputAdornment, Paper, Skeleton, Typography } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box } from "@mui/system";
import useContract, { connectionStatus } from "app/hooks/useContract";
import { LogoBig, MetamaskIcon, TetherIcon } from "assets/images";
import { useSnackbar } from "notistack";
import { forwardRef, useState } from "react";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import ConnectWallet from "./ConnectWallet";



const NumericFormatCustom = forwardRef<NumericFormatProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, name, ...other } = props as any;

        return (
            <NumericFormat
                decimalScale={2}
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        );
    },
);

const CryptoTextfield = ({ image, cryptoName, label, value, onChange }: { image: any, cryptoName: string, label: string, value: number | undefined, onChange: (newValue: number) => void }) => {

    return (
        <>
            <Typography variant='subtitle2' mb={'3px'} fontWeight='400' ml='20px'>
                {label}
            </Typography>
            <OutlinedInput
                name={`${cryptoName}input`}
                fullWidth
                id="cripto-textfield"
                placeholder="0.00"
                sx={{ borderRadius: '2rem', fontSize: '2ch' }}
                value={value}
                inputComponent={NumericFormatCustom as any}
                onChange={(e) => onChange(parseFloat(e.target.value) || 0)}
                startAdornment={
                    <InputAdornment position="start"
                    >
                        <img src={image} alt={cryptoName} style={{ width: '2rem' }} />
                        <Typography variant='subtitle2' mx={1}>
                            {cryptoName}
                        </Typography>
                    </InputAdornment>
                }
            />
        </>
    )
}


const CryptoTransfer = () => {
    const [usdtValue, setUsdtValue] = useState<number | null>(null)
    const [reUsdValue, setReUsdValue] = useState<number | null>(null)

    const [isBuying, setIsBuying] = useState(false)
    const totalCommisions = 0

    const { providerConnState, balanceREUSD, balanceUSDT, AddReUSDTokenToMetamask, BuyReUsd } = useContract()
    const { enqueueSnackbar } = useSnackbar()

    const showErrorMsg = (msg: string) => {
        enqueueSnackbar(msg, {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }
        })
    }

    const updateCriptoValues = (value: number, currency: 'reusd' | 'usdt') => {
        if (value <= 0) {
            value = 0
            setUsdtValue(null)
            setReUsdValue(null)
            return
        }
        if (currency === 'reusd') {
            setReUsdValue(value)
            setUsdtValue(value ? value * totalCommisions / 100 + value : null)
        }
        else {
            setUsdtValue(value)
            setReUsdValue(value ? value * 100 / (100 + totalCommisions) : null)
        }
    }

    const handleBuyReUsd = async () => {
        if (!usdtValue) return
        setIsBuying(true)
        BuyReUsd(usdtValue)
            .then().catch((e) => {
                if (e.code === 'ACTION_REJECTED') {
                    showErrorMsg('Transaction rejected')
                }
                else if (e.code === 'ACTION_APROVAL_REJECTED') {
                    showErrorMsg('Approval rejected')
                }
                else {
                    console.log(e.code)
                    showErrorMsg('Error buying ReUSD')
                }
            })
            .finally(() => setIsBuying(false))
    }


    return (<>

        {providerConnState === connectionStatus.LOADING && <Box sx={{ mx: { md: '10rem', xs: '5px' } }}>
            <Skeleton variant="rectangular" width={'100%'} height={420} />
        </Box>}
        {providerConnState === connectionStatus.CONNECTED && <Box sx={{ mx: { md: '10rem', xs: '5px' } }}>
            <Paper elevation={4} sx={{ p: 2 }}>
                <Typography variant='title2' component='h3' mb={3}>
                    Buy ReUSD
                </Typography>
                <Typography variant='subtitle2' fontWeight='400' ml='1rem' component='h3' mb={3}>
                    Current Balance: {balanceUSDT} USDT
                </Typography>
                <Typography variant='subtitle2' fontWeight='400' ml='1rem' component='h3' mb={3}>
                    Current Balance: {balanceREUSD} ReUSD
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CryptoTextfield image={TetherIcon} cryptoName='USDT'
                            label="You send"
                            value={usdtValue || undefined}
                            onChange={(newValue) =>
                                updateCriptoValues(newValue, 'usdt')
                            }
                        />
                        <span style={{
                                fontSize: '0.7rem',
                                fontStyle: "italic",
                                display: "block",
                                fontWeight: 400}}> convertion rate 1.05 USDT = 1 ReUSD</span>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: '20px', pb: 0 }}>
                        <Divider>
                            <Icon>
                                currency_exchange
                            </Icon>
                        </Divider>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 0 }}>
                        <CryptoTextfield image={LogoBig} cryptoName='ReUSD'
                            label="You will recibe"
                            value={reUsdValue || undefined}
                            onChange={(newValue) =>
                                updateCriptoValues(newValue, 'reusd')
                            }
                        />
                    </Grid>

                </Grid>
                <Box display='flex' justifyContent='space-between' padding='0px' width='100%'>
                    <LoadingButton variant='contained' sx={{ mt: 3 }}
                        loading={isBuying}
                        disabled={isBuying || !usdtValue || usdtValue <= 0}
                        onClick={() => handleBuyReUsd()}
                    >
                        Buy ReUSD
                    </LoadingButton>

                    <Button variant='outlined' sx={{ mt: 3 }} onClick={() => AddReUSDTokenToMetamask()}
                        endIcon={
                            <img src={MetamaskIcon} alt="metamask" style={{ width: '1.5rem' }} />
                        }
                    >
                        Add ReUSD to your wallet
                    </Button>
                </Box>
            </Paper >
        </Box >}
        {providerConnState === connectionStatus.ERROR && <Typography variant='h4' color='error'>Error connecting to the network</Typography>}
        {providerConnState === connectionStatus.DISCONNECTED && <ConnectWallet/>}
    </>);
}

export default CryptoTransfer;