import React, { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { ITableSchema, ITableType } from '.';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SxProps } from '@mui/system';
import dayjs from 'dayjs';
import { formatServerDate, parseFormattedDate } from 'app/utils/utils';
import NumberField from 'app/components/NumberField';

interface RenderCellProps {
    cell: ITableSchema;
    index: number;
    value: any;
    mode: any;
    error: boolean;
    helperText: ReactNode;
    onChange: (id: any, value: any) => void;
    sx?: SxProps<any>,
    row: any,
}

interface renderEditableCellProps {
    cell: ITableSchema;
    value: any;
    cellType: ITableType;
    error: boolean;
    helperText: ReactNode;
    onChange: (id: any, value: any) => void;
}

const RenderEditableCell = ({ cell, value, cellType, error, helperText, onChange }: renderEditableCellProps) => {
    if (cell.editable !== false) {
        if (cellType === 'text') {
            return (
                <TextField
                    value={value}
                    size="small"
                    onChange={(e) => onChange(cell.id, e.target.value)}
                    error={error}
                    helperText={helperText}
                />
            );
        } else if (cellType === 'select') {
            return (
                <FormControl fullWidth>
                    <Select
                        error={error}
                        size="small"
                        value={value}
                        onChange={(e) => onChange(cell.id, e.target.value)}
                    >
                        {cell.selectOptions?.map((option, index) => (
                            <MenuItem key={`Cell${index}-item${cell.id}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>{helperText}</FormHelperText>
                </FormControl>
            );
        } else if (cellType === 'date') {
            return (
                <DatePicker
                    slotProps={{
                        textField: {
                            size: 'small',
                            helperText: helperText,
                            error: error
                        },
                        shortcuts: {

                            items: cell.DateProps?.items ? cell.DateProps?.items.map((item) => {
                                return {
                                    label: item.label,
                                    getValue: () => {
                                        return dayjs(item.date).toDate()
                                    },
                                }
                            }) : [],
                            changeImportance: 'set'
                        },
                    }}
                    //@ts-ignore
                    minDate={cell.DateProps?.minDate ? dayjs(cell.DateProps?.minDate).toDate() : undefined}
                    //@ts-ignore
                    maxDate={cell.DateProps?.maxDate ? dayjs(cell.DateProps?.maxDate).toDate() : undefined}
                    //@ts-ignore
                    defaultValue={cell.DateProps?.defaultValue ? dayjs(cell.DateProps?.defaultValue) : undefined}

                    //@ts-ignore
                    value={() => {
                        const [day, month, year] = value.split('-');
                        return new Date(year, month - 1, day);
                    }}
                    onChange={(e) => {
                        //@ts-ignore
                        const date = dayjs(e);
                        date.subtract(1, 'day');
                        const isoString = date.toISOString();
                        const formatedDate = formatServerDate(isoString);
                        onChange(cell.id, formatedDate)
                    }}

                />
            );
        } else if (cellType === 'number') {
            return (
                <NumberField
                    min={cell.NumberProps?.min}
                    max={cell.NumberProps?.max}
                    prefix={cell.NumberProps?.prefix}
                    suffix={cell.NumberProps?.suffix}
                    value={value}
                    size="small"
                    onChange={(number) => onChange(cell.id, number)}
                    error={error}
                    helperText={helperText}

                />
            );
        }

    }
    return null;
};



const RenderCell: React.FC<RenderCellProps> = ({
    cell,
    index,
    value,
    mode,
    error,
    helperText,
    onChange,
    sx,
    row
}) => {
    const cellType: ITableType = cell.type ?? 'text';

    const getNameForSelect = (value: any) => {
        if (cell.selectOptions) {
            const option = cell.selectOptions.find((option) => option.value === value);
            return option?.label ?? value;
        }
        return value;
    };



    const customComponentContent = cell.customComponent ? <cell.customComponent value={value} row={row} /> : value;

    const formatedNumber = (value: number) => {
        const suffix = cell.NumberProps?.suffix ?? '';
        const prefix = cell.NumberProps?.prefix ?? '';
        return `${prefix}${value}${suffix}`;
    }

    return (
        mode === 'view' ?
            <TableCell
                key={`Cell${index}-item`}
                align={cell.align}
                padding={cell.padding}
                sx={{ cursor: 'pointer', ...sx }}
            >
                {cellType === 'select' && getNameForSelect(value)}
                {cellType === 'text' && customComponentContent}
                {cellType === 'date' && dayjs(value).format('DD/MM/YYYY')}
                {cellType === 'number' && formatedNumber(value)}

            </TableCell>
            :
            <TableCell key={`Cell${index}-edit-item`}>
                <RenderEditableCell
                    cell={cell}
                    value={value}
                    cellType={cellType}
                    error={error}
                    helperText={helperText}
                    onChange={onChange}
                />
            </TableCell>


    )
};

export default RenderCell;