import { Box, Icon } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useState } from 'react';
import PageContainer from '../components/PageContainer';

const HeaderQueries = () => {

    const data = [
        {
            label: 'Argentina',
            icon: 'flag',
        },
        {
            label: 'Brasil',
            icon: 'flag',
        },
        {
            label: 'Canadá',
            icon: 'flag',
        },
        {
            label: 'Dinamarca',
            icon: 'flag',
        },
        {
            label: 'Egipto',
            icon: 'flag',
        },
        {
            label: 'Francia',
            icon: 'flag',
        },
        {
            label: 'Grecia',
            icon: 'flag',
        },
        {
            label: 'Honduras',
            icon: 'flag',
        },
        {
            label: 'India',
            icon: 'flag',
        },
        {
            label: 'Japón',
            icon: 'flag',
        },
        {
            label: 'Kenia',
            icon: 'flag',
        },
        {
            label: 'Líbano',
            icon: 'flag',
        },
        {
            label: 'México',
            icon: 'flag',
        },
        {
            label: 'Noruega',
            icon: 'flag',
        },
        {
            label: 'Perú',
            icon: 'flag',
        },
        {
            label: 'Qatar',
            icon: 'flag',
        },
        {
            label: 'Rusia',
            icon: 'flag',
        },
        {
            label: 'Sudáfrica',
            icon: 'flag',
        },
        {
            label: 'Turquía',
            icon: 'flag',
        },
        {
            label: 'Ucrania',
            icon: 'flag',
        },
    ];


    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ backgroundColor: 'white', position: 'sticky' }}>
            <PageContainer>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    aria-label="scrollable force tabs example"
                >

                    {
                        data.map((item, index) => {
                            return <Tab
                                disableRipple
                                disableFocusRipple
                                key={index}
                                label={<Box display='flex' flexDirection='column' alignItems='center' gap='8px'>
                                    <Icon>{item.icon}</Icon>
                                    <span>{item.label}</span>
                                </Box>}
                            />
                        })
                    }

                </Tabs >
            </PageContainer>

        </Box>
    )
};

export default HeaderQueries;
