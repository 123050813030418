import { Box, Grid, Typography, Divider } from "@mui/material";
import RenvanceTable from "app/components/Tables/RenvanceTable";
import { contractTableSchema } from "./FormContract";
import { companyTypes } from "./FormDetails";
import { usersTableSchema } from "./FormUsers";

const FormResume = ({ answers }: { answers: any }) => {

    const details = answers['details'];
    const plans = answers['plans'];
    const users = answers['users'];

    return (<>
        <Box p={{ xs: 0, md: '0px 4rem' }} maxWidth="60rem" m="0 auto">
            <Typography variant="h5" gutterBottom>
                Company Details
            </Typography>
            <Grid container spacing={2} maxWidth="45rem" margin={{ xs: 0, md: 'auto' }}>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Name:</strong> {details.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Type: </strong>
                        {companyTypes.find((type: any) => type.value === details.type)?.label}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Phone number:</strong> {details.phoneNumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Email:</strong> {details.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1"><strong>Address:</strong> {details.address}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1"><strong>Signed contract:</strong> {details.signedContract}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 4 }} />

            <Typography variant="h5" gutterBottom mt={4}>
                Plans
            </Typography>
            <RenvanceTable schema={contractTableSchema} rows={plans} loading={false} />

            <Typography variant="h5" gutterBottom mt={4}>
                Users
            </Typography>
            <RenvanceTable schema={usersTableSchema} rows={users} loading={false} />


        </Box >

    </>);
}

export default FormResume;