import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { Button, IconButton, SxProps, Theme } from '@mui/material';
import useAuth from 'app/hooks/useAuth';

const FavouriteButton = ({ propertyUUID, sx, isButton = false }: { propertyUUID: string, sx?: SxProps<Theme>, isButton?: boolean }) => {
    const { user } = useAuth();


    const handleClick = () => {
        if (propertyUUID === undefined) {
            console.error('propertyUUID is undefined');
            return;
        };

        user.toggleFavourite(propertyUUID);
    };
    
    const icon = user && user.favorites.includes(propertyUUID) ? (
        <FavoriteIcon sx={{ width: '100%', color: 'primary.main' }} />
    ) : (
        <FavoriteBorder sx={{ width: '100%' }} />
    );

    const buttonProps = {
        sx,
        onClick: handleClick,
        title: 'Favourite',
    };

    if (!user) {
        return <></>;
    } else if (isButton) {
        return <Button {...buttonProps} variant='outlined'>{icon}</Button>;
    } else {
        return <IconButton {...buttonProps}>{icon}</IconButton>;
    }
};

export default FavouriteButton;
