import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import RangeCalendarField from "app/components/RangeCalendarField";
import { useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import HistoryTable2 from "./HistoryTable2";

const DistributionHistoryPage = () => {
    const [filters, setFilters] = useState({
        distributionStatus: '',
        property_code: '',
        token_ticker: '',
        start_date: '',
        end_date: '',
    });


    return (<PageWrapper nocard>

        <Paper aria-label='buscador' sx={{ p: 3, mb: 5 }}>
            <Typography variant="title1" pb={1} pt={3} component={'p'}>Distribution history</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Property code" variant="outlined" size="small"
                        onChange={(e) => setFilters({ ...filters, property_code: e.target.value as string })}
                        value={filters.property_code}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Token ticker" variant="outlined" size="small"
                        onChange={(e) => setFilters({ ...filters, token_ticker: e.target.value as string })}
                        value={filters.token_ticker}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="id-distribution-status">Distribution status</InputLabel>
                        <Select
                            size="small"
                            labelId="id-distribution-status"
                            id="dist-status"
                            value={filters.distributionStatus}
                            label="Distribution status"
                            onChange={(e) => setFilters({ ...filters, distributionStatus: e.target.value as string })}
                        >
                            <MenuItem value={'pending default'}>Pending default</MenuItem>
                            <MenuItem value={'distributed'}>Distributed</MenuItem>
                            <MenuItem value={'transfer'}>Transfer</MenuItem>
                            <MenuItem value={'past due'}>Past due</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <RangeCalendarField
                        sx={{ width: '100%' }}
                        onRangeSelect={({ startDate, endDate }) => {
                            setFilters({ ...filters, start_date: startDate.toISOString(), end_date: endDate.toISOString() })
                        }}
                    />

                </Grid>
                <Grid item xs={12} md={2}>
                    <Button fullWidth variant="contained" color="primary">Search</Button>
                </Grid>
            </Grid>

        </Paper>

        <Paper sx={{ p: 3 }} elevation={3}>
            <HistoryTable2/>
        </Paper>



    </PageWrapper>);
}

export default DistributionHistoryPage;