import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Fade, Grid, LinearProgress, Typography } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Fragment } from 'react';
import { backIdCard, frontIdCard } from 'assets/images';
import { useApproveIdentificationRequestMutation } from 'app/redux/api/investorsApi';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{
                width: { xs: '100%', md: '60rem' },
            }} {...props} />
        </Draggable>
    );
}

const ModalReviewUserApproval = ({ open, onClose, onSubmit, approval: approval, rows, updateTableRows }: { open: boolean, onClose: () => void, onSubmit: (approval: any) => void, approval: any, rows: any[], updateTableRows: (updatedRows: any[]) => void }) => {


    const { user } = useAuth();
    const [approveIdentificationRequest, { isLoading }] = useApproveIdentificationRequestMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        !isLoading && onClose()
    }


    const handleApprove = async () => {
        console.log(approval.user_uid)
        user.getAsyncToken()
            .then((token) => {
                approveIdentificationRequest({
                    token, data: { 
                        address: approval.address,
                        zipcode: 0,
                        id: approval.id,
                        name: approval.name,
                        lastname: approval.lastname,
                        phone_number: approval.phone_number,
                        country: approval.country,
                        state: approval.state,
                        user_uid: approval.user_uid,
                        user_email: approval.user_email,
                        official_id: "",
                        official_id_backside_photo: approval.official_id_backside_photo,
                        official_id_frontside_photo: approval.official_id_frontside_photo,
                        wallet_address: approval.wallet_address,
                        approved: true,
                    }
                })
                    .unwrap()
                    .then((data) => {
                        enqueueSnackbar('Approval processed', { variant: 'success' });
                        onSubmit(data);
                        updateTableRows(rows.filter(r => r.id !== approval.id));
                        handleClose();
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.message || 'Error approving user request', { variant: 'error' });
                    });
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || 'Error authorizing you!', { variant: 'error' });
            });

    }


    return approval && (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                maxWidth="xl"
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Review user approval request
                    <Fade in={isLoading} unmountOnExit>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Fade>

                </DialogTitle>
                    <DialogContent>
                    <Grid item xs={8}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Wallet Address</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.wallet_address}</strong></Typography>
                                    </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Name</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.name}</strong></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Lastname</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.lastname}</strong></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Phone number</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.phone_number}</strong></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Country</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.country}</strong></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">State</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.state}</strong></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Email</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{approval.user_email}</strong></Typography>
                                </Grid>

                                <Grid item xs={6} aria-label='front' >
                                    <Box component='img'
                                        src={approval.official_id_frontside_photo ? approval.official_id_frontside_photo : frontIdCard}
                                        alt="Front Id"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: '10/4',
                                            objectFit: 'cover',
                                            borderRadius: '1rem',
                                            position: 'inherit',
                                            zIndex: 2,
                                            '&:hover': {
                                                opacity: 0.9,
                                                cursor: 'pointer',
                                            
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} aria-label='front' >
                                    <Box component='img'
                                        src={approval.official_id_backside_photo  ? approval.official_id_backside_photo : backIdCard}
                                        alt="Front Id"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: '10/4',
                                            objectFit: 'cover',
                                            borderRadius: '1rem',
                                            position: 'inherit',
                                            zIndex: 2,
                                            '&:hover': {
                                                opacity: 0.9,
                                                cursor: 'pointer',
                                            
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} aria-label='front' >
                                    <Box component='img'
                                        src={approval.selfie_photo  ? approval.selfie_photo : frontIdCard}
                                        alt="Front Id"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: '10/4',
                                            objectFit: 'cover',
                                            borderRadius: '1rem',
                                            position: 'inherit',
                                            zIndex: 2,
                                            '&:hover': {
                                                opacity: 0.9,
                                                cursor: 'pointer',
                                            
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={() => handleApprove()}>Confirm</LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment >

    );
}

export default ModalReviewUserApproval;