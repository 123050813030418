import { useGetAppreciationMutation } from "app/redux/api/propertiesApi";
import PageWrapper from "../Layout/PageWrapper";
import AppreciationTable from "./AppreciationTable";
import { useEffect } from "react";
import useAuth from "app/hooks/useAuth";

const AppreciationPage = () => {
    return (<PageWrapper>

        <AppreciationTable />

    </PageWrapper>);
}

export default AppreciationPage;