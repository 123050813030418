import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Collapse, Fade, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { formatToUSDValue } from 'app/utils/utils';
import dayjs from 'dayjs';
import { useApproveUserPurchaseMutation } from 'app/redux/api/usersApi';
import useAuth from 'app/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useState, Fragment, useEffect } from 'react';
import MoneyTextField from '../MoneyTextField';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{
                width: { xs: '100%', md: '60rem' },
            }} {...props} />
        </Draggable>
    );
}

const ModalReviewDeposit = ({ open, onClose, onSubmit, deposit }: { open: boolean, onClose: () => void, onSubmit: (deposit: any) => void, deposit: any }) => {


    const { user } = useAuth();
    const [approveUserPurchase, { isLoading }] = useApproveUserPurchaseMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [approved_amount, setApprovedAmount] = useState<number>(deposit?.approved_amount || 0);
    const [conversion_rate, setConversionRate] = useState<number>(deposit?.conversion_rate || 1);

    const [difference_reason, setDifferenceReason] = useState('');


    const handleClose = () => {
        !isLoading && onClose()
    }


    const handleApprove = async () => {
        user.getAsyncToken()
            .then((token) => {
                approveUserPurchase({
                    token, data: {
                        purchase_uuid: deposit.uuid,
                        status: 'ACCEPTED',
                        approved_amount: approved_amount,
                        token_ticker: 'REUSD',
                        currency: deposit.currency,
                        conversion_rate: conversion_rate,
                    }
                })
                    .unwrap()
                    .then((data) => {
                        enqueueSnackbar('Deposit processed', { variant: 'success' });
                        onSubmit(data);
                        handleClose();
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.message || 'Error approving deposit', { variant: 'error' });
                    });
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || 'Error authorizing you!', { variant: 'error' });
            });

    }


    return deposit && (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                maxWidth="xl"
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Review Deposit
                    <Fade in={isLoading} unmountOnExit>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Fade>

                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Typography variant="body1">Ticket</Typography>

                            <Box
                                component="img"
                                src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/12054180302/original/euAxLwJBVUbaYCnhpIDWZsDKYUBcSV65rQ.png?1564003880"
                                alt="Deposit Ticket"
                                onClick={() => window.open(deposit.deposit_ticket, '_blank')}
                                sx={{
                                    width: '100%',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {

                                        transform: 'scale(1.03)',
                                        cursor: 'pointer',
                                    }
                                }}
                            />
                            <Button
                                onClick={() => window.open(deposit.deposit_ticket, '_blank')}
                                variant="outlined"
                                color="primary"

                            >Open Ticket</Button>

                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>


                                <Grid item xs={6}>
                                    <Typography variant="body1">Account</Typography>
                                    <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{deposit.investor.display_name} {deposit.wallet_address}</strong></Typography>
                                    G</Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Date Notified</Typography>
                                    <Typography variant="body1"><strong>{dayjs(deposit.deposit_date).format('DD/MM/YYYY')}</strong></Typography>

                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1">Date accepted</Typography>
                                    <Typography variant="body1"><strong>{dayjs().format('DD/MM/YYYY')}</strong></Typography>

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">Amount Informed</Typography>
                                    <Typography variant="body1"><strong>{formatToUSDValue(deposit.deposit_amount)} {deposit.currency}</strong></Typography>

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">Amount deposited</Typography>
                                    <MoneyTextField size='small'
                                        value={approved_amount}
                                        onValueChange={(value) => setApprovedAmount(value)}

                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">Amount ReUSD</Typography>
                                    <Typography variant="body1"><strong>{formatToUSDValue(approved_amount / conversion_rate)} ReUSD</strong></Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">Conversion Rate</Typography>
                                    <TextField
                                        type="number"
                                        inputProps={{
                                            min: 1,
                                        }}
                                        size='small'
                                        value={conversion_rate}
                                        onChange={({ target: { value } }) => {
                                            if (isNaN(Number(value))) return;
                                            if (Number(value) < 1) return;
                                            setConversionRate(Number(value))
                                        }}

                                    />


                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">Amount USD</Typography>
                                    <Typography variant="body1"><strong>{formatToUSDValue(approved_amount / conversion_rate)} USD</strong></Typography>

                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <Collapse in={deposit.deposit_amount !== approved_amount}
                                timeout={500}
                                sx={{ width: '100%' }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1">Difference</Typography>
                                    <Typography variant="body1"><strong>{formatToUSDValue(deposit.deposit_amount - approved_amount)} {deposit.currency}</strong></Typography>
                                </div>

                                <Typography variant="body1">Difference Reason</Typography>
                                <TextField fullWidth multiline rows={4}
                                    value={difference_reason}
                                    onChange={(e) => setDifferenceReason(e.target.value)}
                                />
                            </Collapse>
                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={() => handleApprove()}>Confirm</LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment >

    );
}

export default ModalReviewDeposit;