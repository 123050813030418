import { ThemeOptions } from "@mui/material";
import { components } from "./components";

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true;
    title2: true;
    title3: true;

    subtitle3: true;
  }
}

const themeOptions: ThemeOptions  = {
  typography: {
    fontSize: 14,
    fontFamily: 'inherit',
    body1: { fontSize: "14px" },
    title1: {
      fontSize: '2.3rem',
      fontWeight: 'bold',
      letterSpacing: '1px'
    },
    title2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: '1px'
    },
    title3: {
      fontSize: '1.15rem',
      fontWeight: 'bold',
      letterSpacing: '1px'
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: '400',
      letterSpacing: '1px'
    },
    subtitle3: {
      fontSize: '1rem',
      fontWeight: '300',
      letterSpacing: '1px'

    }
  } as any,

  components: { ...components },
};

export default themeOptions;
