import { configureStore } from "@reduxjs/toolkit";
import { homeStatsReducer } from "./slices/homeStatsSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { propertiesApi } from "./api/propertiesApi";
import { walletApi } from "./api/walletApi";
import { usersApi } from "./api/usersApi";
import { companiesApi } from "./api/companiesApi";
import { utilitiesApi } from "./api/utilities";
import { investorsApi } from "./api/investorsApi";


const dataStore = configureStore({
  reducer: {
    statistics: homeStatsReducer,
    //apis
    [propertiesApi.reducerPath]: propertiesApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [utilitiesApi.reducerPath]: utilitiesApi.reducer,
    [investorsApi.reducerPath]: investorsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(propertiesApi.middleware)
      .concat(walletApi.middleware)
      .concat(usersApi.middleware)
      .concat(companiesApi.middleware)
      .concat(investorsApi.middleware)
      .concat(utilitiesApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof dataStore.getState>
export type AppDispatch = typeof dataStore.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export default dataStore
