import {
  Box,
  Card,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

import useAuth from 'app/hooks/useAuth';
import { propertiesApi } from 'app/redux/api/propertiesApi';
import { Property } from 'app/redux/models';
import { useSnackbar } from 'notistack';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "../../../../components/cards/Alert";
import PageWrapper from '../../Layout/PageWrapper';
import Account from './Account/Account';
import BookingPage from './Booking/Booking';
import DeleteProperty from './DeleteProperty';
import HomeInfo from './Home/HomeInfo';
import Newsletter from './News/Newsletter';
import Pucharse from './Pucharse/Purchase';
import Statements from './Statement/Statements';


const PropertyViewerPage = () => {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar()
  const uuid = searchParams.get("uuid") as string
  const { user } = useAuth()
  const location = useLocation();
  const locationProp = location.state?.property


  const [fetchPropertyByUUID, { data, isLoading, error }] = propertiesApi.endpoints.getPropertyDetails.useLazyQuery()



  const [loading, setLoading] = useState(true);
  const navi = useNavigate()
  const [property, setProperty] = useState<Property>({} as Property);
  const [statements] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const tabChange = (event: any, newValue: SetStateAction<number>) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!uuid) {
      enqueueSnackbar('Property Not Found', { variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
      navi('/agency/properties', { replace: true })
      return
    }
    if (!user) return

    if (error) {
      console.log(error)
    }
    if (user && !error) {

      const token = user.accessToken
      fetchPropertyByUUID({ uuid: uuid, token: token })
        .then(res => {
          console.log(res)
          if (res.data) {
            setProperty(res.data.page[0])
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)
        })


    }

    // if (locationProp) {
    //   setProperty(locationProp)
    //   setLoading(false)
    // }
    //eslint-disable-next-line
  }, [user, data, error]);




  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function PropertyContent(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box aria-label='tab-panel'>
            {children}
          </Box>
        )}
      </div>
    );
  }



  const TabPages = () => {
    return <Fragment>
      <PropertyContent value={tabValue} index={0}>
        {/* <Economics economics={property.economics} /> */}
      </PropertyContent>
      <PropertyContent value={tabValue} index={1}>
        <Account uuid={uuid} />
      </PropertyContent>
      <PropertyContent value={tabValue} index={2}>
        {/* <Token token={property.token} /> */}
      </PropertyContent>
      <PropertyContent value={tabValue} index={3}>
        <BookingPage />
      </PropertyContent>
      <PropertyContent value={tabValue} index={4}>
        <Pucharse />
        {/*//TODO VALIDAR QUE EL USUARIO SEA  *INVESTOR*   */}
      </PropertyContent>
      <PropertyContent value={tabValue} index={5}>
        <Statements statements={statements} />
      </PropertyContent>
      <PropertyContent value={tabValue} index={6}>
        <Newsletter />
      </PropertyContent>
      <PropertyContent value={tabValue} index={7}>
        <DeleteProperty />
      </PropertyContent>
    </Fragment>
  }

  const TabNameArray = ['Economics', 'Account', 'Token', 'Booking', 'Pucharse', 'Statement & legal docuementation', 'Recent Updates', 'Delete Property']

  return (
    <PageWrapper>

      <Box aria-label='notification-register'>
        {false && (
          <Alert
            btnText="Complete registration"
            title="User registration uncompleted"
            description="In order to be able to invest in our properties you have to complete the registration process and Known your client process." hiddenButton={undefined} onClickFunction={undefined} />
        )}
      </Box>
      <Card sx={{ padding: '2rem', mb: '1rem' }} aria-label="main-content-property">
        <Box>
          <HomeInfo property={property} isLoading={loading} />

          <Tabs value={tabValue} onChange={tabChange}

            variant="scrollable"
            scrollButtons="auto"
            sx={{ paddingTop: '1rem' }}>
            {TabNameArray.map((tabName, index) => <Tab key={index} label={tabName} />)}
          </Tabs>
        </Box>
      </Card>
      <Card sx={{ padding: '2rem', mb: '2rem', minHeight: '30rem' }}>
        <Typography variant="title2" sx={{ mb: '1rem' }} component={'h2'} >{TabNameArray[tabValue]} </Typography>
        <TabPages />
      </Card>
    </PageWrapper>
  );
};


export default PropertyViewerPage;
