import useSettings from "app/hooks/useSettings";
import { lazy } from "react";
import RenvanceSuspense from "../RenvanceSuspense";


const LazyLayout = lazy(() => import(`./Layout/Layout`));

const DashboardLayout = (props: any) => {
  return (
    <RenvanceSuspense>
      <LazyLayout {...props} />
    </RenvanceSuspense>
  );
};

export default DashboardLayout;
