import { ColorBasedOnValue } from "app/components/ColorBasedOnValue";
import RenvanceTable, { ITableSchema } from "../RenvanceTable";
import { Button } from "@mui/material";





const schema: ITableSchema[] = [
    {
        id: 'propertyCode',
        label: 'Property Code',
        align: 'left',
    },
    {
        id: 'propertyName',
        label: 'Property Name',
        align: 'left',
    },
    {
        id: 'statementDate',
        label: 'Date',
        align: 'left',
    },
    {
        id: 'description',
        label: 'Description',
        align: 'left',
        sortable: false
    },
    {
        id: 'filePathLink',
        label: 'File',
        align: 'right',
        sortable: false,
        customComponent: (row: any) => {

            return (
                <Button size='small'
                    variant='contained'
                    color='primary'
                    sx={{ textTransform: 'none' }}
                    onClick={(e) => alert(`Download ${row.value}`)}

                >Download</Button>
            )

        }
    },
]


const StatementTable = ({ movements, isLoading }: { movements: any, isLoading: boolean }) => {

    return (
        <RenvanceTable rows={movements} schema={schema} loading={isLoading} />
    );
}

export default StatementTable;