import PageWrapper from "../../Layout/PageWrapper";

const ManageAppUser = () => {
    return (<PageWrapper>
        <>
            manage user
        </>

    </PageWrapper>);
}

export default ManageAppUser;