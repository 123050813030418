import { useState } from "react";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";




const TablaRow = ({ title, value, subitems }: { title: string, value: string, subitems?: any }) => {
    const [open, setOpen] = useState(false);

    return (<>
        <Grid item xs={12} display='flex' justifyContent='space-between'>
            <Typography variant="subtitle1" component="p" sx={{
                fontWeight: '300'
            }}>
                {title}
            </Typography>
            <div style={{ display: 'flex' }}>
                <Typography variant="subtitle1" component="p" sx={{
                    fontWeight: 'bold'
                }}>
                    {value}
                </Typography>
                {subitems && (
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </IconButton>
                )}
            </div>

        </Grid>
        {subitems && (
            <Box sx={{ ml: 2 }}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {subitems.map((item: { title: string; value: any; }, index: string) => (
                        <TablaRow key={'k' + item.title + index} title={item.title} value={item.value} />
                    ))}
                </Collapse>

            </Box>
        )}
    </>
    );

}


export default TablaRow