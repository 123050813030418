export const appConfig = {
  env: process.env.REACT_APP_ENV || 'UAT' as 'UAT' | 'PROD' | 'DEV',
}

export const googleConfig = {
  captcha_site_key: process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY as string,
  captcha_enterprice_site_key: process.env.REACT_APP_GOOGLE_CAPTCHA_ENTERPRICE_SITE_KEY as string,
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const PathApis = {
  gatewayPath: process.env.REACT_APP_GATEWAY_PATH as string,
  bucketApi: process.env.REACT_APP_BUCKET_API as string,

}

export const criptoConfig = {
  reUsdAddress: process.env.REACT_APP_RE_USD_ADDRESS as string,
  usdtAddress: process.env.REACT_APP_USDT_ADDRESS as string,

}