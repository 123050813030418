import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { PathApis } from 'config';

type CreateOrderType = {
    order_quantity: number;
    order_price: number;
    order_type: "BUY" | "SELL";
    token_ticker: string;
    token: string
}

type CancelOrderType = {
    order_quantity: number;
    order_price: number;
    order_type: "BUY" | "SELL";
    token_ticker: string;

}


const buildHeaders = (token: string) => ({ 'Authorization': `Bearer ${token}` })


const axiosBaseQuery = ({ baseUrl, token }: { baseUrl: string, token?: string } = { baseUrl: '' }):
    BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            headers?: AxiosRequestConfig['headers']
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axios({ url: baseUrl + url, method, data, params, headers })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError as AxiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

export const walletApi = createApi({
    reducerPath: 'walletApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    endpoints: ({ mutation }) => ({
        createOrder: mutation<any, CreateOrderType>({
            query: (data) => ({
                url: '/market/create_order',
                method: 'post',
                body: data,
                headers: buildHeaders(data.token)
            }),
        }),
        cancelOrder: mutation<any, { options: CancelOrderType | {}, token: string }>({
            query: ({ options, token }) => ({
                url: '/market/cancel_order',
                method: 'post',
                params: options,
                headers: buildHeaders(token)
            }),
        }),


    }),

    keepUnusedDataFor: 3600,
});

export const {
    useCreateOrderMutation,
    useCancelOrderMutation,
} = walletApi;