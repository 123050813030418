import { Avatar, Box, Chip, ChipProps, Grid, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import useAuth from "app/hooks/useAuth";
import { useVerifyUserIdentificationMutation } from "app/redux/api/usersApi";
import { IUser } from "app/redux/models";
import { isInvestor } from "app/utils/utils";
import { useSnackbar } from "notistack";
import { useState } from "react";
import TitleWrapper from "./components/TitleWrapper";
import VerificationForm from "./verification/VerificationForm";


const steps = [
    {
        label: 'Fiat Limit of 10K USD Monthly',
        description: `Verified Personal Information, Governament-issue ID, Facial Recognition`,
    },
    {
        label: 'Fiat Limit of 30K USD Monthly',
        description:
            'Require proof of address',
    }
];

const ListContainer = ({ title, children }: { title: string, children: React.ReactNode | any }) => {
    return (<>
        <Typography variant="title3" component='p' fontSize='1.25rem'>
            {title}
        </Typography>
        <ul style={{ listStyle: 'none', padding: '0', marginTop: 0 }}>
            {children}
        </ul>


    </>)
}


const ListItem = ({ title, value }: { title: string, value: string }) => {
    return (<>

        <li style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            fontSize: '2rem',
            paddingTop: '4px',
            paddingBottom: '4px'

        }}>
            <Typography variant="subtitle1" component='p' fontSize='0.95rem'>
                {title}
            </Typography>
            <Typography variant="subtitle1" component='p' fontWeight='500' fontSize='1rem'>
                {value}
            </Typography>
        </li>



    </>)


}


const AccountInfo = () => {
    return (<>
        <ListContainer title="Account Limits">
            <ListItem title="Fiat Deposit & Withdraw" value="10K USD Monthly" />
            <ListItem title="Crypto Deposit Limit" value="Unlimited" />
            <ListItem title="Crypto Withdraw Limit" value="10K ReUSD Daily" />
        </ListContainer>
        <ListContainer title="Personal Information">
            <ListItem title="Legal Name" value="Alejandro Mildiner" />
            <ListItem title="Date of Birth" value="1974-06-22" />
            <ListItem title="Address" value="Buenos Aires City, Argentina" />
            <ListItem title="Identification Documents" value="ID Card, 23********82" />
            <ListItem title="Email Address" value="Ale***@gmail.com" />
        </ListContainer>
    </>
    )
}


const VerifiedChip = ({ user }: { user: IUser }) => {

    const verifiedSettings: ChipProps = {
        label: 'Verified',
        color: 'success',
        variant: 'filled',
        size: 'small'
    }
    const unverifiedSettings: ChipProps = {
        label: 'Unverified',
        color: 'error',
        variant: 'filled',
        size: 'small'
    }

    const settings = isInvestor(user.role) ? verifiedSettings : unverifiedSettings

    return <Chip {...settings} />
}

const IdentificationPage = () => {
    const { user } = useAuth();
    const [activeStep] = useState(0);
    const [verifyUserIdentification] = useVerifyUserIdentificationMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleVerify = async (data: FormData, captcha: string) => {
        try {
            const token = await user.getAsyncToken();
            const response = await verifyUserIdentification({
                token,
                data: {
                    name: data.get("name"),
                    lastname: data.get("lastname"),
                    phone_number: data.get("phone_number"),
                    passport: data.get("passport"),
                    country: data.get("country"),
                    state: data.get("state"),
                    address: data.get("address"),
                    zipcode: data.get("zipcode"),
                    official_id_frontside_photo: data.get("official_id_frontside_photo"),
                    official_id_backside_photo: data.get("official_id_backside_photo"),
                    selfie_photo: data.get("selfie_photo"),
                    wallet_address: data.get("wallet_address"),
                    user_uid: user.uid,
                    user_email: user.email,
                    approved: false
                },
                captcha
            }).unwrap();

            enqueueSnackbar('Verification of identification submitted', { variant: 'success' });
        } catch (err: any) {
            enqueueSnackbar(err?.message || 'Error sending the request', { variant: 'error' });
        }
    }




    return (<>
        <TitleWrapper title='Identification' />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <div aria-label='profile' style={{ display: 'flex', padding: '1rem 3rem 1rem 3rem', }}>
                <div >
                    <Avatar alt="Ale"
                        sx={{ width: 56, height: 56 }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                    <Typography variant="title3" component='p'>
                        {user.displayName}
                    </Typography>

                    <VerifiedChip user={user} />

                </div>
            </div>

            <Grid container>
                <Grid item xs={8} p='1rem' component='main'>
                    {isInvestor(user.role) ? <AccountInfo /> : <VerificationForm onVerify={handleVerify} />}
                </Grid>
                <Grid component='aside' item xs={4} p='1rem'>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>

                        <Typography variant="title3" component='p' fontSize='1.25rem'>
                            Verification Levels
                        </Typography>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label} expanded>
                                    <StepLabel
                                        optional={
                                            index === 2 ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </Grid>

            </Grid>


        </Box>

    </>);
}

export default IdentificationPage;