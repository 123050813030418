import { Skeleton, TableCell, TableRow } from "@mui/material";
import { array } from "prop-types";


const LoadingRow = ({ span }: { span: number }) => {
    return (
        <TableRow>
            <TableCell colSpan={span} align="center" sx={{ p: 0, pb: 1 }}>
                <Skeleton variant="rectangular" height={20} animation="wave" />
            </TableCell>
        </TableRow>
    );

}


const LoadingRows = ({ span }: { span: number }) => {
    return (
        Array(8).fill(0).map((_, index) => <LoadingRow key={index} span={span} />)
    );
}

export default LoadingRows;