import Loadable from "app/components/Loadable";
import { lazy } from "react";
import StatementHistoryPage from "./Statement/History/StatementHistoryPage";
import AppreciationPage from "./appreciation/AppreciationPage";
import BookStayingPage from "./bookings/bookStaying/BookStayingPage";
import BookingHistory from "./bookings/history/BookingHistory";
import DistributionHistoryPage from "./distribution/History/DistributionHistoryPage";
import DistributionRegisterPage from "./distribution/Register/DistributionRegisterPage";
import PropertyViewerPage from "./properties/viewer/PropertyViewerPage";
import PropertyMovementsPage from "./propertyAccount/Movements/PropertyMovementsPage";
import RegisterExpensePage from "./propertyAccount/RegisterExpenses/RegisterExpensesPage";
import WalletPage from "./wallet/WalletPage";
import AuthGuard from "app/auth/AuthGuard";
import { UserRole } from "app/redux/models";
import { Outlet, RouteObject } from "react-router-dom";
import CompanyAddPage from "./companies/add/CompanyAddPage";
import ListAppUsers from "./appUsers/list/ListAppUsers";
import CreateAppUser from "./appUsers/create/CreateAppUser";
import ManageAppUser from "./appUsers/manage/ManageAppUser";

import TokensPage from "./Tokens/TokensPage";
import CreateUserPage from "./users/CreateUserPage";
import ListCompanyUserPage from "./users/ListCompanyUserPage";
import SeeCompanyPage from "./companies/see/SeeCompanyPage";
import CompaniesPage from "./companies/list/CompaniesPage";
import DepositsPage from "./deposits/DepositsPage";
import UserApproval from "./appUsers/manage/UserApproval";

const Home = Loadable(lazy(() => import("./home/DashboardHomePage")));
const PropertyListPage = Loadable(lazy(() => import("./properties/list/PropertyListPage")));
const PropertyRegisterPage = Loadable(lazy(() => import("./properties/register/PropertyRegisterPage")));

/*
//? first propertyViewer
const PropertyViewerj = () => {
  const [searchParams] = useSearchParams();

  console.log(searchParams.get("uuid"))
  const dict = { uuid: searchParams.get("uuid"), current: searchParams.get("current") }
  return Loadable(lazy(() => import("./properties/viewer/PropertyViewerPage")))(dict);
}
*/


const renvancePagesRoutes: RouteObject[] = [
  { path: "/renvance/companies", element: <CompaniesPage /> },
  { path: "/renvance/companies/:companyName", element: <SeeCompanyPage /> },
  { path: "/renvance/companies/create", element: <CompanyAddPage /> },
  { path: "/renvance/companies/deposits", element: <DepositsPage /> },
  { path: "/renvance/users", element: <ListAppUsers /> },
  { path: "/renvance/users-approval", element: <UserApproval /> },
  { path: "/renvance/users/create", element: <CreateAppUser /> },
  { path: "/renvance/users/:userId", element: <ManageAppUser /> },
  { path: "/renvance/tokens", element: <TokensPage /> },
];

const agencyPagesRoutes: RouteObject[] = [
  { path: "/company/home", element: <Home /> },
  { path: "/company/property-register", element: <PropertyRegisterPage /> },
  { path: "/company/properties", element: <PropertyListPage /> },
  { path: "/company/property-viewer", element: <PropertyViewerPage /> },
  { path: "/company/wallet", element: <WalletPage /> },
  { path: "/company/property-account/register-expenses", element: <RegisterExpensePage /> },
  { path: "/company/property-account/movements", element: <PropertyMovementsPage /> },
  { path: "/company/statements/register", element: <>Igual que el register expenses pero sin el amount</> },
  { path: "/company/statements/history", element: <StatementHistoryPage /> },
  { path: "/company/bookings/book-staying", element: <BookStayingPage /> },
  { path: "/company/bookings/history", element: <BookingHistory /> },
  { path: "/company/appreciation", element: <AppreciationPage /> },
  { path: "/company/distribution/register", element: <DistributionRegisterPage /> },
  { path: "/company/distribution/history", element: <DistributionHistoryPage /> },
  { path: "/company/users", element: <ListCompanyUserPage /> },
  { path: "/company/users/:userId", element: <ListCompanyUserPage /> },
  { path: "/company/users/create", element: <CreateUserPage /> },



  {
    element: <AuthGuard allowRoles={[UserRole.RENVANCE_ADMIN, UserRole.RENVANCE_USER]}><Outlet /></AuthGuard>,
    children: [...renvancePagesRoutes],
  },
];


export default agencyPagesRoutes;
