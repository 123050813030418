import { Fab, Grid, Card, Typography, styled } from "@mui/material";
import { H3 } from "app/components/Typography";
import { formatToUSDValue } from "app/utils/utils";

const ContentBox = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

const FabIcon = styled(Fab)(() => ({
    width: "44px !important",
    height: "44px !important",
    boxShadow: "none !important",
}));





const WalletCard = ({ amount, icon, label, color }: { amount: number, icon: JSX.Element, label: string, color: string }) => {

    return (
        <Grid item xs={12} md={3} sx={{ placeSelf: 'end', p: 0 }}>
            <Card elevation={3} sx={{ p: 2 }}>
                <ContentBox>
                    <FabIcon size="medium" sx={{ zIndex: 1, color: color }}>
                        {icon}
                    </FabIcon>
                    <H3 sx={{ color: color, ml: 2 }}>{label}</H3>
                </ContentBox>

                <ContentBox sx={{ pt: 2 }}>
                    <Typography component='h1' variant='h4' sx={{ color: '#34314c8a', fontWeight: 'bold' }} >{formatToUSDValue(amount)}</Typography>
                </ContentBox>
            </Card>
        </Grid>
    )

}


export default WalletCard;