import { Box, Typography } from "@mui/material";
import PageWrapper from "../../Layout/PageWrapper";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import { useState, useEffect } from "react";
import { companiesApi } from "app/redux/api/companiesApi";
import useAuth from "app/hooks/useAuth";
import { ICompany } from "app/redux/models";

const companyTableSchema: ITableSchema[] = [
    {
        id: 'name',
        label: 'Company Name',
    },
    {
        id: 'phone_number',
        label: 'Company Phone',
    },
    {
        id: 'address',
        label: 'Company Address',
    },
    {
        id: 'email',
        label: 'Company Email',
    },
    {
        id: 'wallet_owner',
        label: 'Wallet Address',
        customComponent: ({ value }) => <>{value?.wallet_address}</>
    }

]


const CompaniesPage = () => {
    const { user } = useAuth();
    const [getAllCompanies, { data: response, error, isLoading }] = companiesApi.endpoints.getAllCompanies.useLazyQuery();
    const [companies, setCompanies] = useState<ICompany[]>([]);

    useEffect(() => {
        user.getAsyncToken().then(token => {
            getAllCompanies({ token }, true)
        });

    }, []);

    return (<PageWrapper>
        <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
            Companies
        </Typography>

        <Box mt={4}>
            <RenvanceTable
                schema={companyTableSchema}
                rows={response?.page || []}
                loading={isLoading}

            />
        </Box>

    </PageWrapper>);
}

export default CompaniesPage;