import { Card, CardContent, Divider, Grid, Icon, Typography } from "@mui/material"
import PageContainer from "../components/PageContainer"


const cardData = [
    {
        title: "What Is Real Estate Crowdfunding?",
        description: "Crowdfunding is where multiple investors pool their investments together to fund larger investments or projects they could not otherwise afford to do. Investor returns are then generated from rental income and capital appreciation.",
    },
    {
        title: "Differences between real estate crowdfunding and peer-to-peer lending",
        description: "Do you want to own part of an investment property or simply profit from lending money? Should you be investing in crowdfunding or peer-to peer?",
    },
    {
        title: "How does real estate crowdfunding actually work?",
        description: "There’s a formula for success behind crowdfunding. It takes us a lot of time and hard work, but you can benefit with just a few clicks from the comfort of your own home."
    }

]


const HowItWorksCard = ({ title, description }: { title: string, description: string }) => {
    return (
        <Card sx={{ maxWidth: '20rem', pt: '1rem' }}>
            <Icon sx={{ width: '100%', fontSize: '5rem !important' }}>quiz</Icon>
            <Typography variant="title3" component="h1" gutterBottom sx={{ textAlign: 'center', px: '2rem' }}>
                {title}
            </Typography>
            <Divider sx={{ width: '80%', margin: '0 auto' }} />
            <CardContent sx={{ p: '3rem' }}>
                {description}
            </CardContent>
        </Card>
    )

}

const HowItWorks = () => {
    return <>
        <Divider>
            <Typography variant="title1" component="h1" gutterBottom>
                How It Works
            </Typography>
        </Divider>

        <PageContainer>
            <Grid container sx={{
                marginTop: 5,
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
            }}>
                {cardData.map((card) => {
                    return <HowItWorksCard title={card.title} description={card.description} />
                })}
            </Grid>
        </PageContainer >


    </>
}

export default HowItWorks