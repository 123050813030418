import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";


export const CrowdFundingSlider = styled(Slider)({
    color: "#18d663",
    height: 8,
    "& .Mui-disabled": {
        color: "blue",
        height: 8
    },
    ".MuiSlider-track": {
        color: "linear-gradient(to right,  #18d663 50%, #ff6666 100%);"
    },
    "& .MuiSlider-rail": {
        background: "linear-gradient(to right,  #18d663 50%, #ff6666 100%);"
    },
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit"
        },
        "&:before": {
            display: "none"
        }
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#1e2335",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&:before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
        },
        "& > *": {
            transform: "rotate(45deg)"
        }
    }
});

