import { themes } from "../MatxTheme/initThemes";
import layoutSettings from "./Layout/LayoutSettings";

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html
export const MatxLayoutSettings = {
  activeLayout: "layout", // layout1, layout2
  activeTheme: "blue", // View all valid theme colors inside MatxTheme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layoutSettings, // open Layout/LayoutSettings.js

  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: "slateDark1", // View all valid theme colors inside MatxTheme/themeColors.js
  },
};
