import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { SxProps, styled, useTheme } from '@mui/system';
import { dummyProp1, landing_photos } from "assets/images";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/PageContainer";

const Images = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    //? el segundo array [pos inicial, pos de hasta dónde se mueve]
    const xGaonaPlano = useTransform(scrollYProgress, [0.5, 1], [-190, -350]);
    const yGaonaSum = useTransform(scrollYProgress, [0.5, 1], [-250, -350]);
    const yVelazcoFrente = useTransform(scrollYProgress, [0.5, 1], [-213, -400]);
    const xVelazcoInterior = useTransform(scrollYProgress, [0.5, 1], [-126, -200]);
    const xVillaroel = useTransform(scrollYProgress, [0.5, 1], [108, 300]);


    return (
        <Box
            ref={ref}
            sx={{ rotate: '45deg', position: 'absolute', transform: 'translate(-50%)', padding: '40px' }} aria-label='animated images'>
            <motion.div style={{ x: xGaonaPlano, y: -100 }}>
                <PortafolioImage label='Newvery' src={landing_photos.gaonaPlano} porcent={16} rotation={0} width="27rem" />
            </motion.div>
            <motion.div style={{ x: -100, y: yGaonaSum }}>
                <PortafolioImage src={landing_photos.gaonaSum} label='Gaona' porcent={15} rotation={-90} />
            </motion.div>
            <motion.div style={{ x: 50, y: yVelazcoFrente }}>
                <PortafolioImage src={landing_photos.velazcoFrente} label='Velazco' porcent={15} rotation={-90} width="20rem" />
            </motion.div>
            <motion.div style={{ x: xVelazcoInterior, y: 82 }}>
                <PortafolioImage src={landing_photos.velazcoInterior} label='Corrientes' porcent={15} rotation={-90} height="12rem" width="18rem" />
            </motion.div>
            <motion.div style={{ x: xVillaroel, y: 20 }}>
                <PortafolioImage src={landing_photos.villaroelPlano} label='Villaroel' porcent={16} rotation={0} width="10rem" height="10rem" />
            </motion.div>
        </Box>
    );
};

const BannerImg = styled('img')({
    borderRadius: '2rem',
    position: 'absolute',
    objectFit: 'cover',
    transition: 'border-radius 0.5s, z-index 0.7s', // Agregamos la transición para el zIndex aquí
    '&:hover': {
        borderRadius: '1.3rem',
        boxShadow: '0 0 5px 0px #0000008c',
        //zIndex: 2
    }
});

const PortFolioImg = styled('img')({
    borderRadius: '2rem',
    position: 'absolute',
    objectFit: 'cover',
    transition: 'border-radius 0.5s, z-index 0.7s', // Agregamos la transición para el zIndex aquí
    '&:hover': {
        borderRadius: '1.1rem',
    }
});


const PersonImg = styled('img')({
    borderRadius: '2rem',
    position: 'absolute',
    objectFit: 'cover',
    zIndex: 6
});


const PortafolioImage = ({ label, porcent, rotation, src, sx, width, height }: { label: string, porcent: number, rotation: number, width?: string, height?: string, src?: string, sx?: SxProps<any> }) => {
    const IMG_WIDTH = width || '15rem'
    const IMG_HEIGHT = height || '10rem'

    return (
        <Box aria-label={`property-picture-${label}`}
            sx={{
                ...sx,
                rotate: `${rotation}deg`, position: 'absolute',
                width: IMG_WIDTH, height: IMG_HEIGHT

            }}>
            <span style={{ position: 'absolute', zIndex: 1, margin: '1rem', color: 'white', backgroundColor: '#0000008c', paddingRight: '10px', paddingLeft: '10px', borderRadius: '5px' }}>{label}</span>
            <span style={{ bottom: 0, position: 'absolute', zIndex: 1, margin: '1rem', color: 'white', backgroundColor: '#0000008c', paddingRight: '10px', paddingLeft: '10px', borderRadius: '5px' }}>{porcent}%</span>
            <PortFolioImg src={src || dummyProp1} alt="dummyProp1" sx={{ width: IMG_WIDTH, height: IMG_HEIGHT }} />
        </Box>
    )

}

export default function SectionPresentation() {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <PageContainer id='TemplateSction1' component='section' sx={{ mt: 2 }}>
            <Grid container sx={{ height: { xs: 'auto', md: '45rem' }, backgroundColor: 'white', position: 'relative', zIndex: 10 }}>
                <Grid item xs={12} md={4} sx={{ paddingTop: { xs: 0, sm: 0, md: '5rem' } }}>
                    <Typography variant="title1" component="h1" gutterBottom
                        fontSize="3rem"
                        display={isMobile ? 'block' : 'flex'}
                        flexWrap="wrap"
                        columnGap="10px"
                        rowGap="10px"
                    >
                        Inversiones inmobiliarias <span style={{ color: 'orange' }}>sencillas</span>
                    </Typography>

                    <Typography variant="subtitle1" component="h2"
                        sx={{fontWeight:400, lineHeight:"1.5", paddingBottom:"0.5rem"}}
                    >
                        Convertite en inversor inmobiliario desde 200USD y comenzá a descubrir la libertad financiera. 
                    </Typography>
                    <Typography component="h2" sx={{fontSize:"1rem"}}>
                    Cada token representa una parte de una propiedad que genera ingresos por alquiler. Estos ingresos se distribuyen entre los inversores. 
                    </Typography>

                    <Button component="label" variant="contained" onClick={() => {
                            navigate(`invest`)
                        }} 
                        style={{ borderRadius: '0.8rem', fontSize: '1.2rem', marginTop: '20px' }}>
                        INVERTIR
                    </Button>
                </Grid>
                <Grid item xs={12} md={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <div aria-label="images" style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <BannerImg src={landing_photos.edificioArbolado} alt="Foto de Inmueble" sx={{ width: '19rem', left: '21rem', zIndex: 2, height: '13rem' }} id="child-top" />
                        <BannerImg src={landing_photos.nordeltaDepto} alt="Foto de Inmueble" sx={{ width: '26rem', left: '21rem', top: '15rem', aspectRatio: '1/1', zIndex: 2 }} id="big" />
                        <BannerImg src={landing_photos.edificioVillacrespo} alt="Foto de Inmueble" sx={{ width: '19rem', left: '6rem', top: '9rem', aspectRatio: '10/9', zIndex: 1 }} id="mid" />
                        <BannerImg src={landing_photos.plano2} alt="Foto de Inmueble" sx={{ width: '19rem', left: '6rem', top: '27rem', aspectRatio: '16/9', zIndex: 1 }} id="bot" />
                        <PersonImg src={landing_photos.lookingProps} alt="looking_props" sx={{ width: '14rem', left: '1rem', zIndex: 2, top: '22rem' }} />

                    </div>

                </Grid>
            </Grid>

            <Box sx={{ mt: '6rem', mb: '5rem' }}>
                <Typography variant="title1" zIndex={3} position='relative'>
                    Diversifica tus inversiones
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={4} sx={{ paddingTop: { md: '1rem' } }}>
                        <Typography variant="subtitle1" m='1rem' zIndex={3} position='relative' sx={{ backgroundColor: 'white', borderRadius: '5px', letterSpacing: '0.5px', fontWeight: 400 }}>
                            Invertí como los expertos, en lugar de invertir en una sola propiedad, diversifica tus inversiones y arma un portfolio inmobiliario.
                        </Typography>
                        <Typography variant="subtitle1" m='1rem' zIndex={3} position='relative' sx={{ backgroundColor: 'white', borderRadius: '5px', letterSpacing: '0.5px', fontWeight: 400, fontSize:"1rem" }}>
                            Hacemos que las inversiones inmobiliarias sean tan faciles como comprar y vender cryptos. Mientras disfrutas de los retornos de tus inversiones. 
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ paddingTop: '5rem', display: { xs: 'none', sm: 'none', md: 'flex' }, justifyContent: 'center' }}>
                        <Images />
                    </Grid>
                </Grid>

            </Box>



        </PageContainer >
    );
}
