import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { RenvanceHeader, RenvanceHeaders } from './RenvanceHeader';
import { RenvanceToolbar, RenvanceToolbarProps } from './RenvanceToolbar';
import { getComparator, stableSort } from './TableUtils';
import { FC, useState, ChangeEvent, MouseEvent, useMemo, memo, ReactNode } from 'react'
import LoadingRows from './LoadingRows';
import NoRows from './NoRows';
import { Icon, IconButton, SxProps } from '@mui/material';
import RenvanceRow, { RenvanceRowProps } from './RenvanceRow';
import { Schema } from 'yup';
import { NumberFieldProps } from 'app/components/NumberField';



type Order = 'asc' | 'desc';

export type ITableType = 'text' | 'number' | 'select' | 'date'

export interface ITableSchema extends RenvanceHeaders {
  id: string,
  align?: RenvanceHeaders['align'],
  label: string,
  customComponent?: FC<{ value: any, row?: any }>,
  editable?: boolean
  validation?: Schema<any>
  sx?: SxProps<any>
  type?: ITableType

  selectOptions?: { value: string, label: string }[]
  NumberProps?: NumberFieldProps
  DateProps?: {
    minDate?: Date
    maxDate?: Date
    defaultValue?: Date
    items?: { date: Date, label: string }[]
  }
}

export interface ITableSchemaDefined<T extends Record<string, any>> extends ITableSchema {
  id: Extract<keyof T, string> | string
}



interface RenvanceTableProps {
  sx?: SxProps<any>
  rows: any[],
  toolbar?: RenvanceToolbarProps['options']
  schema: ITableSchema[]
  expandibleSchema?: ITableSchema[]
  expandibleTableName?: string;
  selectable?: boolean
  loading?: boolean

  pagination?: boolean
  paginationMode?: 'full' | 'simple' //? full: get all pages and then cut them, simple: get only the current page and fetch the rest when click on next page
  pageSize?: number
  currentPage?: number
  totalPages?: number

  actions?: RenvanceRowProps['actions']
  customActions?: RenvanceRowProps['customActions']
  upperTableRow?: ReactNode

  editionMode?: RenvanceRowProps['editionMode']
  onEditRow?: (row: any, index: number) => Promise<any>
  onAddRow?: (row: any) => Promise<any>
  onDeleteRow?: (row: any, index: number) => Promise<any>
}

export interface IRenvanceRow {
  id: number,
  data: any
  onClick?: (id: number) => void
}



export default function RenvanceTable({ sx, rows, toolbar, schema, expandibleSchema,
  expandibleTableName, selectable = false, loading = true, pagination = true, pageSize = 5, totalPages, upperTableRow,
  actions, onEditRow, onAddRow, onDeleteRow, customActions, editionMode = 'inline' }: RenvanceTableProps) {

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [newsRows, setNewRows] = useState<any[]>([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof typeof rows | any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: { id: any; }) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {

    if (!selectable) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddNewRow = () => {
    const newRow = schema.reduce((acc, curr) => {
      acc[curr.id] = '';
      acc['_new'] = true;
      acc['_id'] = Math.random() * 1000;
      return acc;
    }, {} as any);
    setNewRows([...newsRows, newRow]);
  }




  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () => {
      const totalRows = [...rows, ...newsRows];
      return stableSort(totalRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      )
    },
    [order, orderBy, page, rowsPerPage, rows, newsRows],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {toolbar && <RenvanceToolbar numSelected={selected.length} options={toolbar} onClickAddRow={handleAddNewRow} />}
        <TableContainer sx={{
          pr: { sm: '16px', xs: '16px', md: '16px' },
          pl: { sm: '5px', xs: '5px', md: '5px' },
        }}>
          <Table
            sx={{ minWidth: 750, ...sx }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <RenvanceHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={schema}
              actions={actions}
              customActions={customActions}
              upperTableRow={upperTableRow}
            />
            <TableBody>
              {
                loading ? <LoadingRows span={schema.length + ((actions?.length || customActions?.length) ? 1 : 0)} /> :
                  (newsRows.length === 0 && rows.length === 0) ? <NoRows span={schema.length + ((actions?.length || customActions?.length) ? 1 : 0)} /> :
                    <>
                      {
                        visibleRows.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          return (
                            <RenvanceRow
                              expandibleTableName={expandibleTableName}
                              key={`TableVisibleRow-${index}`}
                              onRowClick={handleClick}
                              isItemSelected={isItemSelected}
                              editionMode={editionMode}
                              schema={schema}
                              actions={actions}
                              expandibleSchema={expandibleSchema}
                              customActions={customActions}
                              row={row}
                              onEditRow={(r: any) => onEditRow ? onEditRow(r, index) : Promise.resolve()}
                              addRow={(r: any) => {
                                const editRow = { ...r };
                                delete editRow._new;
                                delete editRow._id;

                                return onAddRow ? onAddRow(editRow)
                                  .finally(() => {
                                    setNewRows(newsRows.filter((item) => item._id !== r._id))
                                  }) : Promise.resolve();
                              }}
                              deleteRow={(r: any) => {
                                if (r?._new === true) {
                                  setNewRows(newsRows.filter((item) => item !== r));
                                } else {
                                  onDeleteRow ? onDeleteRow(r, index) : Promise.resolve();
                                }
                              }}
                            />
                          );
                        })
                      }
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}

                    </>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalPages || rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </Paper>
    </Box>
  );
}