import { Card, CardContent, CardHeader, Chip, Divider, Grid, Icon, IconButton, InputBase, Paper, Typography } from "@mui/material";
import PageWrapper from "../Layout/PageWrapper";
import useContract, { IReUSDContract } from "app/hooks/useContract";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";


const TokensPage = () => {
    const [reUsdContract, setReUsdContract] = useState<IReUSDContract>();
    const { getReUSDContract } = useContract();
    const [walletAddAddress, setWalletAddAddress] = useState<string>('');
    const [walletRemoveAddress, setWalletRemoveAddress] = useState<string>('');
    const [walletCheckAddress, setWalletCheckAddress] = useState<string>('');

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        getReUSDContract().then(info => setReUsdContract(info))
    }, []);

    const handleAddWallet = () => {
        if (walletAddAddress) {
            reUsdContract?.wallets.addKnownWallet(walletAddAddress).then((tx) => {
                enqueueSnackbar('Adding wallet', { variant: 'info', autoHideDuration: 4000 })
                tx.wait().then(() => {
                    enqueueSnackbar('Wallet added', { variant: 'success' })
                })

            }).catch((err) => {
                enqueueSnackbar(err.data.message, { variant: 'error' })
            })
        }
    }
    const handleRemoveWallet = () => {
        if (walletRemoveAddress) {
            reUsdContract?.wallets.removeKnownWallet(walletRemoveAddress).then((tx) => {
                enqueueSnackbar('Removing wallet', { variant: 'info', autoHideDuration: 4000 })
                tx.wait().then(() => {
                    enqueueSnackbar('Wallet removed', { variant: 'success' })
                })
            })
        }

    }
    const handleCheckIsKnownWallet = () => {
        if (walletCheckAddress) {
            reUsdContract?.wallets.isKnownWallet(walletCheckAddress).then((isKnown) => {
                enqueueSnackbar(`Wallet is ${isKnown ? '' : 'not'} known`, { variant: isKnown ? 'success' : 'error' })
            })
        }

    }



    return (
        <PageWrapper>


            <Card>
                <CardHeader title={<strong>ReUSD Contract
                    <Chip label={reUsdContract?.paused ? 'Paused' : 'Active'} sx={{ ml: 1 }}
                        color={reUsdContract?.paused ? 'error' : 'success'}
                    />
                </strong>} />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography display='flex' flexDirection='column'>
                                <strong>Contract Address:</strong> {reUsdContract?.address}
                            </Typography>
                            <Typography display='flex' flexDirection='column'>
                                <strong>USDT Balance:</strong> {reUsdContract?.holder.balance} USDT
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography display='flex' flexDirection='column'>
                                <strong>Manage Wallets</strong>
                            </Typography>
                            <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, mt: 2 }}
                            >

                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Add Authorized Wallet"
                                    inputProps={{ 'aria-label': 'Add Authorized Wallet' }}
                                    value={walletAddAddress}
                                    onChange={(e) => { setWalletAddAddress(e.target.value) }}
                                />

                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => { handleAddWallet() }}>
                                    <Icon>add</Icon>
                                </IconButton>
                            </Paper>

                            <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, mt: 2 }}
                            >

                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Remove Known wallet"
                                    inputProps={{ 'aria-label': 'Remove Known wallet' }}
                                    value={walletRemoveAddress}
                                    onChange={(e) => { setWalletRemoveAddress(e.target.value) }}
                                />

                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => { handleRemoveWallet() }}>
                                    <Icon>remove</Icon>
                                </IconButton>
                            </Paper>
                            <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, mt: 2 }}
                            >

                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Remove Known wallet"
                                    inputProps={{ 'aria-label': 'Remove Known wallet' }}
                                    value={walletCheckAddress}
                                    onChange={(e) => { setWalletCheckAddress(e.target.value) }}
                                />

                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => { handleCheckIsKnownWallet() }}>
                                    <Icon>check</Icon>
                                </IconButton>
                            </Paper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>




        </PageWrapper>

    );
}

export default TokensPage;