import { useMemo } from "react";
import DividerWithPaperText from "../components/DividerWithPaperText";
import GridCardsContainer from "../components/GridCardsContainer";
import PageContainer from "../components/PageContainer";
import HeaderQueries from "./HeaderQueries";
import RentCard from "./RentCard";

const RentPage = () => {

    const rentCards = useMemo(() => {
        const randomImages = [
            'https://a0.muscache.com/im/pictures/miso/Hosting-48716549/original/6244888f-5051-4f81-a132-3f022186df9a.jpeg?im_w=720',
            'https://a0.muscache.com/im/pictures/6f9783bb-5bbf-4f91-8452-14fa80889ded.jpg?im_w=720',
            'https://a0.muscache.com/im/pictures/miso/Hosting-628750879988103483/original/c77ec913-ff5d-49a2-b16c-a3fd7b4a680d.jpeg?im_w=720',
            'https://a0.muscache.com/im/pictures/c5d46e7b-c977-4fc1-9ee6-40d1f83ddd6c.jpg?im_w=720',
            'https://a0.muscache.com/im/pictures/2810fdf9-1310-4504-929d-d073b9fff791.jpg?im_w=720',
            'https://a0.muscache.com/im/pictures/59076a02-7f3f-4d96-ab83-a5e5d832e2da.jpg?im_w=720',
            'https://a0.muscache.com/im/pictures/59076a02-7f3f-4d96-ab83-a5e5d832e2da.jpg?im_w=720',
        ]
        const rentData = {
            propertyName: 'Departamento en BSAS',
            propertyDirection: 'Av. Libertador 1111,',
            available_start: '2023-09-01',
            available_end: '2023-09-06',
            price_per_night: 70,
        }

        return Object.keys(Array.from({ length: 20 })).map((_, index) => (
            <RentCard rentData={{
                ...rentData,
                image: randomImages[Math.floor(Math.random() * randomImages.length)]
            }} key={index} />
        ));
    }, []);

    return (
        <>
          <DividerWithPaperText text="¿A dónde viajas?" />
            <HeaderQueries />
            <section aria-label='rents-cards-container'>
                <PageContainer>
                    <GridCardsContainer>
                        {rentCards}
                    </GridCardsContainer>

                </PageContainer>
            </section>
        </>);
}

export default RentPage;