import { Grid, Tabs, Tab, styled } from "@mui/material";
import Header from "app/views/Layout/Header";
import PageContainer from "app/views/main-pages/components/PageContainer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Home as HomeIcon, Info as InfoIcon, Settings as SettingsIcon } from '@mui/icons-material';
import useAuth from "app/hooks/useAuth";
import { isInvestor } from "app/utils/utils";


const ProfileLayout = () => {

    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { user } = useAuth();

    const StyledTab = styled(Tab)(({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        mr: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
        flexDirection: 'row',
        justifyContent: 'flex-start',

        '& .MuiSvgIcon-root': {
            marginRight: '10px',
            marginBottom: '0px',

        },
    }));


    return (<>
        <Header />
        <PageContainer>
            <Grid component='main' container mt={5} spacing={2}>
                <Grid item xs={2} component='aside'>
                    <Tabs
                        value={pathname}
                        onChange={(e, value) => navigate(value)}
                        orientation="vertical"
                        variant="scrollable"
                        textColor="primary"
                        indicatorColor="primary"
                        scrollButtons={false}
                        sx={{
                            borderRight: 0, borderColor: 'divider',
                            '& .MuiTabs-indicator': {
                                display: 'none', // saca linea del costado
                            },
                            '& .Mui-selected': {
                                backgroundColor: 'primary.main',
                                borderRadius: '1rem',
                                color: 'white !important'

                            },
                            '& .MuiTab-root': {
                            },

                        }}
                    >
                       {isInvestor(user.role) && <StyledTab label="Investment" tabIndex={0} value={'/profile'} icon={<HomeIcon />} />}
                        <StyledTab label="Security" tabIndex={1} value={'/profile/security'} icon={<InfoIcon />} />
                        <StyledTab label="Identification" tabIndex={2} value={'/profile/identification'} icon={<SettingsIcon />} />


                    </Tabs>
                </Grid>
                <Grid item xs={10}>
                    <Outlet />
                </Grid>
            </Grid>
        </PageContainer>
    </>);
}

export default ProfileLayout;

