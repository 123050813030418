import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import LoadingPropertyCard from "app/components/cards/LoadingPropertyCard";
import useAuth from "app/hooks/useAuth";
import { GetAllPropertiesLazy, propertiesApi } from "app/redux/api/propertiesApi";
import { Property } from "app/redux/models";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import InvestCard from "../../Invest/InvestCard";



const RentCards = () => {

    const { user } = useAuth();
    const [cards, setCards] = useState([]);
    const [getAllProperties] = propertiesApi.endpoints.getAllProperties.useLazyQuery()
    const { enqueueSnackbar } = useSnackbar();

    const GetProperties = async () => {
        const token = user ? await user.getAsyncToken() : ''
        getAllProperties({ token, filters: { active_rent: true } })
            .unwrap()
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        GetProperties()
    }, [])

    return (
        <PageContainer>
            <Typography variant="title2" component="h2">
                Propiedades para Alquilar
            </Typography>
            <Typography variant="subtitle1" component="h3" sx={{ mb: 2 }}>
                Encontrá el alojamiento perfecto para tu estadía
            </Typography>
            <Swiper
                spaceBetween={10}
                slidesPerView={6}

            >
                {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                        {card}
                    </SwiperSlide>
                ))}
                ...
            </Swiper>
        </PageContainer>
    )
}



const InvestCards = () => {

    const { user } = useAuth();
    const [getAllProperties] = GetAllPropertiesLazy()


    const [cards, setCards] = useState<Property[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();



    const GetProperties = async () => {
        const token = user ? await user.getAsyncToken() : ''
        getAllProperties({ token, filters: { active_invest: true } })
            .unwrap()
            .then((props) => setCards(props.page))
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        GetProperties()
    }, [])

    return (
        <PageContainer>
            <Typography variant="title1" component="h1">
                Propiedades para Invertir
            </Typography>
            <Typography variant="subtitle1" component="h1" sx={{ mb: 2 }}>
                Buscá entre las mejores propiedades para invertir
            </Typography>
            <Swiper
                spaceBetween={10}
                slidesPerView={isMobile ? 2 : 4}
            >
                {isLoading && Array.from({ length: 4 }).map((_, index) => (
                    <SwiperSlide key={index}>
                        <LoadingPropertyCard />
                    </SwiperSlide>
                ))}

                {cards.map((card: Property, i: number) => (
                    <SwiperSlide key={i}>
                        <InvestCard investData={card} onClick={() => {
                            navigate(`/property/${card.property_uuid}`)
                        }} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </PageContainer>
    )
}


const SectionCarrousels = () => {

    return (
        <section id="many-carrousels">
            <div aria-label='rents-cards-container' style={{ marginTop: '2rem' }}>
                <InvestCards />
            </div>
            {/* <div aria-label='rents-cards-container' style={{ marginTop: '2rem' }}>
                <RentCards />
            </div> */}


        </section >
    );
}

export default SectionCarrousels;