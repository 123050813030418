import { Backdrop, Button, CircularProgress, List, ListItem, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useContract from "app/hooks/useContract";
import { CoinbaseIcon, MetamaskIcon } from "assets/images";
import { useSnackbar } from "notistack";
import { useState } from "react";



const ListProviderItem = ({ provider, onClick }: { provider: string; onClick: (provider: string) => void }) => {
    const img = provider === 'metamask' ? MetamaskIcon : CoinbaseIcon;

    return (
        <ListItem
            className='connect-wallet-list-item'
            disableGutters
            onClick={() => onClick(provider)}
        >
            <Button
                className='cta-button connect-wallet-button'
                fullWidth
                variant="outlined"
                sx={{ justifyContent: 'space-between', px: 2 }}
                endIcon={<img src={img} alt={`${provider} photo`} style={{ width: '2rem' }} />}
            >
                <Typography variant='subtitle1' fontWeight='400' component='h4' className='connect-wallet-text'>
                    {provider === 'metamask' ? 'Metamask' : 'Coinbase'}
                </Typography>

            </Button>
        </ListItem>
    );


}

const ProviderList = ({ onClick }: { onClick: (provider: string) => void }) => {
    return (
        <List className='connect-wallet-list' >
            <ListProviderItem provider='metamask' onClick={onClick} />
            <ListProviderItem provider='coinbase' onClick={onClick} />
        </List>
    );


}


const ConnectWallet = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [connectingWallet, setConnectingWallet] = useState(false);
    const { ConnectWallet } = useContract();

    const handleConnectWallet = async () => {
        const { ethereum } = window as any;
        if (!ethereum) {
            enqueueSnackbar('Please install a Wallet Provider!', { variant: 'error', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            return;
        }

        setConnectingWallet(true);
        ConnectWallet().then(() => { }).catch((err) => { }).finally(() => setConnectingWallet(false));
        


    }




    return (<Box sx={{ mx: '10rem' }}>
        <Paper elevation={3} className='connect-wallet' sx={{ p: 2 }}>
            <Typography variant='title2' component='h3' className='connect-wallet-title' mb={3}>
                Connect Wallet
            </Typography>
            <Box sx={{ px: 2 }}>
                <Typography variant='subtitle1' component='h4' className='connect-wallet-text' mb={1}>
                    Connect your wallet to get started.
                </Typography>

                <ProviderList onClick={handleConnectWallet} />

                <Typography variant='subtitle2' component='h6' fontWeight='200' className='connect-wallet-text' my={1}>
                    We do not own your private keys and cannot access your funds without your confirmation.
                </Typography>

            </Box>

        </Paper >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={connectingWallet}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </Box >);
}

export default ConnectWallet;

