import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ConstructionIcon from '@mui/icons-material/Construction';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SavingsIcon from '@mui/icons-material/Savings';
import TokenIcon from '@mui/icons-material/Token';
import { Button, Divider, Grid, Icon, IconButton, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { Box } from '@mui/system';
import ChartComponent from "app/components/ChartComponent";
import { CrowdFundingSlider } from "app/components/CrowdFundingSlider";
import DataPack1 from "app/components/DataPack1";
import TablaRow from "app/components/TablaRow";
import { Property } from 'app/redux/models';
import { formatToUSDValue } from "app/utils/utils";
import dayjs from 'dayjs';
import { useState } from "react";



const CrowdFunding = ({ property, prices }: { property: Property, prices:any }) => {
    const [barValue, setBarValue] = useState(50);
    const crowdFundingMarks = [
        {
            value: 7,
            label: "0 ReUSD"
        },
        {
            value: 50,
            label: `${(property.economics.construction.asset_value / 2).toFixed(0)} ReUSD`
        },
        {
            value: 90,
            label: `${property.economics.construction.asset_value} ReUSD`
        }
    ]


    return (<>
        <Grid container spacing={2} aria-label="description-crowdfunding">
            <Grid item md={7} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2">
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="h2">
                        {property.description}
                    </Typography>
                </Grid>

            </Grid>
            <Grid item md={5} xs={12} px={2}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2">
                        Crowd Funding
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="h2">
                            Crowd Funding Progress (MISSING%)
                        </Typography>
                        <Box display='flex' justifyContent='space-between' mb='-0.7rem'>
                            <Typography variant="subtitle1" component="h2">
                                {dayjs(property.economics.fund_raising_start).format('DD/MM/YYYY')}
                            </Typography>
                            <Typography variant="subtitle1" component="h2">
                                Expected {dayjs(property.economics.fund_raising_end).format('DD/MM/YYYY')}
                            </Typography>
                        </Box>
                        <CrowdFundingSlider
                            aria-label="Crowd Funding Progress"
                            defaultValue={50}
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            marks={crowdFundingMarks}
                            getAriaValueText={(value) => `${value}%`}
                        />
                    </Grid>

                    <Grid item xs={12} display='flex' my={1} justifyContent='space-between'>
                        <TextField label="Amount" variant="outlined" size='small' sx={{ mr: 1 }} />

                        <Typography variant="subtitle1" component="h2">
                            Available: {formatToUSDValue(10000)} ReUSD
                        </Typography>
                    </Grid>


                    <Grid item xs={12} display='flex' justifyContent='flex-end'>

                        <Button size='small' variant="contained" sx={{ mr: 1 }}>Buy</Button>
                        <Button size='small' variant="outlined" sx={{ ml: 1 }}>Deposit</Button>
                    </Grid>


                </Grid>

            </Grid>
        </Grid>


        <Grid container spacing={2} aria-label="description-summary" my={2}>
            <Grid item md={3}>
                <DataPack1 icon={TokenIcon} title={'Token'} value={formatToUSDValue(property.token.token_price)} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={CurrencyBitcoinIcon} title={'Token Name'} value={property.token.token_name} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={CurrencyExchangeIcon} title={'Appreciation Period'} value={property.economics.re_appreciation_period} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={ConstructionIcon} title={'Under Construction'} value={property.economics.Construction_type_code} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={AttachMoneyIcon} title={'Estimated Asset Value'} value={formatToUSDValue(property.economics.construction.expected_asset_value)} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={AutoGraphIcon} title={'Estimated Appreciation'} value={`${1}%`} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={SavingsIcon} title={'Accumulated Appreciation'} value={`${0}%`} />
            </Grid>
            <Grid item md={3}>
                <DataPack1 icon={AutoGraphIcon} title={'Estimated Rent'} value={`${property.economics.expected_net_yearly_rent_percentage}%`} />
            </Grid>

        </Grid>


        <Grid container spacing={2} aria-label="description">
            <Grid item md={8} xs={12}>
                
                {prices && <ChartComponent data={Object.entries(prices?.prices).map(([time, value]: [time:string, value:any]) => ({ time, value }))} />}
            </Grid>
            <Grid item md={4} xs={12}>
                <Typography variant="title2" component="h2" pb={1}>
                    Investment
                </Typography>

                <TablaRow title='Adminitrative Fees' value={formatToUSDValue(property.economics.total_investment.administrative_fees)} />
                <TablaRow title='Decoration n Furniture' value={formatToUSDValue(property.economics.total_investment.decoration_n_furniture)} />
                <TablaRow title='Initial Asset Value' value={formatToUSDValue(property.economics.total_investment.initial_asset_value)} />
                <TablaRow title='Commission' value={"MISSING"} />
                <TablaRow title='Total Investment' value={formatToUSDValue(
                    Number(property.economics.total_investment.administrative_fees) +
                    Number(property.economics.total_investment.decoration_n_furniture) +
                    Number(property.economics.total_investment.initial_asset_value)
                )} />
                <Divider sx={{ my: 1 }} />
                <TablaRow title='Total' value={formatToUSDValue(
                    Number(property.economics.total_investment.administrative_fees) +
                    Number(property.economics.total_investment.decoration_n_furniture) +
                    Number(property.economics.total_investment.initial_asset_value)
                )} />


            </Grid>
        </Grid>


        <Grid item xs={12} pt={2} display='flex' pb={5} aria-label="property-legal-information" container spacing={3}>
            <Grid item xs={4}>
                <Typography variant="title2" component="h2" pb={1}>
                    Financial
                </Typography>
                <TablaRow title="Gross rent yearly" value="$14,078 (0.03%)" />
                <TablaRow title="Gross rent monthly" value="$1,173.17" />
                <TablaRow title="Occupancy" value="86.94%" />
                <TablaRow title="Monthly Costs" value="$99,999,999" subitems={[
                    {
                        title: 'Property management',
                        value: `${formatToUSDValue(property.economics.estimation.gross_rent_yearly_raw)} (${property.economics.estimation.gross_rent_yearly_percentage}%)`
                    },
                    {
                        title: 'Renance commission',
                        value: `${formatToUSDValue(property.economics.estimation.gross_rent_yearly_raw / 12)}`
                    },
                    {
                        title: 'Apartment expenses',
                        value: `${formatToUSDValue(property.economics.estimation.cost_apartment_expenses)}`
                    },
                    {
                        title: 'Building expenses',
                        value: `${formatToUSDValue(property.economics.estimation.cost_building_expenses)}`
                    },
                    {
                        title: 'Insurance',
                        value: `${formatToUSDValue(property.economics.estimation.cost_insurance)}`
                    },
                    {
                        title: 'Taxes',
                        value: `${formatToUSDValue(property.economics.estimation.cost_taxes)}`
                    },
                    {
                        title: 'Expected occupancy',
                        value: `${formatToUSDValue(property.economics.estimation.occupancy)}`
                    }


                ]} />
                <TablaRow title="Net rent by month" value={(property.economics.estimation.net_rent_yearly_percentage / 12).toFixed(2) + '%'} />
                <TablaRow title="Net rent by Year" value={property.economics.estimation.net_rent_yearly_percentage + '%'} />
            </Grid>
            <Grid item xs={4}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2">
                        Statement
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            }
                        >
                            <Icon sx={{ mr: 1 }}>description</Icon>

                            <ListItemText
                                primary="Inicio de Obra"
                                secondary={'Planos, Tareas'}
                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            }
                        >
                            <Icon sx={{ mr: 1 }}>description</Icon>

                            <ListItemText
                                primary="Gastos generales"
                                secondary={'Albanileria y Pintura'}
                            />
                        </ListItem>,
                    </List>
                </Grid>

                <Grid item xs={12}>


                </Grid>

            </Grid>

            <Grid item xs={4}>
                <Typography variant="title2" component="h2" pb={1}>
                    Token Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TablaRow title="Deployment Date" value={dayjs(property.token.deployment_date).format('DD/MM/YYYY')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TablaRow title="Token Address" value={property?.token?.token_address || 'not deployed yet'} />
                    </Grid>
                    <Grid item xs={12}>
                        <TablaRow title="Token Amount" value={property.token.token_amount.toString()} />
                    </Grid>
                    <Grid item xs={12}>
                        <TablaRow title="Token Initial Value" value={"MISSING"} />
                    </Grid>

                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            label="Token Initial Value"
                            variant="outlined"
                            size='small'

                        />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    </>);
}

export default CrowdFunding;