import { styled } from "@mui/material";

export const OBContainer = styled("div")({
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    justifyContent: 'space-between',
    padding: '8px',
});

export const OBTableContainer = styled("div")({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

export const OBRowItem = styled("span")({
    zIndex: 1,
});

export const OBSPanHeader = styled("span")({
    fontWeight: '600',
});