import FolderIcon from '@mui/icons-material/Folder';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, List, ListItem, MenuItem, Select, Typography } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneFormik from "app/components/FormikUI/PhoneFormik";
import TextFieldFormik from "app/components/FormikUI/TextFieldFormik";
import RangeCalendarField from "app/components/RangeCalendarField";
import useAuth from "app/hooks/useAuth";
import { useValidateRegisterCompanyMutation } from "app/redux/api/companiesApi";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { matchIsValidTel } from "mui-tel-input";
import { RefObject, useImperativeHandle, useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import { array, object, string } from "yup";


const companyDetailsSchema = object().shape({
    name: string().required(),
    company_type: string().required('El tipo de compania es requerido'),
    phone_number: string().required('Required').test('is-valid-tel', 'Invalid phone number', (v) => matchIsValidTel(v)),
    email: string().email().required(),
    address: string().required(),
    contract_start: string().required(),
    contract_end: string().required(),
    signedContract: string().required()
        .test('minDiff >= 30', 'Contract must be at least 30 days', (value) => {
            const [start, end] = value.split(',');
            const [startDate, endDate] = [dayjs(start), dayjs(end)]
            return endDate.diff(startDate, 'day') >= 30
        })
        .test('maxDiff <= 10 years', 'Contract must be at most 10 years', (value) => {
            const [start, end] = value.split(',');
            const [startDate, endDate] = [dayjs(start), dayjs(end)]
            return endDate.diff(startDate, 'year') <= 10
        }),
    contract_files: array().min(1, 'At least one file is required')
});

const formatDate = (isoDate: string) => {
    let date = new Date(isoDate);

    let day = String(date.getUTCDate()).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript comienzan desde 0
    let year = date.getUTCFullYear();

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const companyTypes = [
    {
        label: 'Development Company',
        value: 'RE_DEVELOPER'
    },
    {
        label: 'Real State Agency',
        value: 'RE_AGENCY'
    }
]

const trimPhone = (phone: string) => phone.trim().replace(/\s/g, '').replace(' ', '')

const FormDetails = ({ refDetails }: { refDetails: RefObject<any> }) => {

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            phone_number: '',
            email: '',
            address: '',
            signedContract: '',
            wallet_address: `${0x0}${Math.random().toString(36).substr(2, 9)}`,
            contract_end:  dayjs().add(1, 'month').toISOString(),
            contract_start: dayjs().toISOString(),
            company_type: '',
            contract_files: []
        },
        validationSchema: companyDetailsSchema,
        onSubmit: (values) => {

        },
    });

    const [validateRegisterCompany] = useValidateRegisterCompanyMutation();
    const [isFetching, setIsFetching] = useState(false);
    const { user } = useAuth();


    const validateAlreadyRegistered = async (fields: any, values: any) => {
        return user.getAsyncToken()
            .then(token => {
                setIsFetching(true)
                return validateRegisterCompany({ params: fields, token })
                    .unwrap()
                    .then(response => {

                        const validForm = Object.values(response).every(value => value === false)
                        if (validForm) {
                            values.phone_number = trimPhone(values.phone_number)
                            return values
                        }

                        formik.setErrors({
                            email: response?.company_email ? 'Email already registered' : undefined,
                            phone_number: response?.company_phone ? 'Phone number already registered' : undefined,
                            name: response?.company_name ? 'Name already registered' : undefined,
                            address: response?.company_address ? 'Address already registered' : undefined
                        })
                        return false
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        setIsFetching(false)
                    })
            }).catch(err => {
                console.log(err)
            })
    }

    const getSubmit = async () => {
        if (isFetching) return

        formik.handleSubmit()
        const values = companyDetailsSchema.cast(formik.values);
        if (companyDetailsSchema.isValidSync(values)) {
            const fields = {
                company_name: values.name,
                company_email: values.email,
                company_address: values.address,
                company_phone: trimPhone(values.phone_number)
            }
            return validateAlreadyRegistered(fields, values)

        } else return false
    }

    useImperativeHandle(refDetails, () => ({
        getSubmit,
    }));

    const [files, setFiles] = useState<File[]>([]);

    const handleUploadFiles = (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
        formik.setFieldValue('contract_files', acceptedFiles.map(file => file.name))
    }

    return (


        <Box component='form' p={{ xs: 0, md: '0px 4rem' }} maxWidth='45rem' m='0 auto'>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <TextFieldFormik label="Name" name="name" formik={formik} />
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <InputLabel size="small" >Company Type</InputLabel>
                        <Select
                            error={formik.touched.company_type && Boolean(formik.errors.company_type)}
                            value={formik.values.company_type}
                            label="company_type"
                            name="company_type"
                            size="small"
                            onChange={formik.handleChange}
                        >
                            {
                                companyTypes.map((type, index) => {
                                    return <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                                })
                            }

                        </Select>
                        <FormHelperText error>
                            {formik.touched.company_type && formik.errors.company_type}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <PhoneFormik label="Phone number"
                        onlyCountries={['AR', 'BR', 'US']}
                        defaultCountry={'AR'}
                        name="phone_number" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldFormik label="Email" name="email" formik={formik} />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldFormik label="Address" name="address" formik={formik} />
                </Grid>
                <Grid item xs={12}>
                    {
                        useMemo(() => {
                            return (
                                <RangeCalendarField name="signedContract" label="Contract Period" sx={{ width: '100%' }} onRangeSelect={(selection) => {
                                    const isoStart = selection.startDate.toISOString()
                                    const isoEnd = selection.endDate.toISOString()
                                    const formatStart = formatDate(isoStart)
                                    const formatEnd = formatDate(isoEnd)
                                    formik.setFieldValue('signedContract', formatStart + ',' + formatEnd)
                                    formik.setFieldValue('contract_start', `${formatStart}T${isoStart.split('T')[1]}`)
                                    formik.setFieldValue('contract_end', `${formatEnd}T${isoStart.split('T')[1]}`)
                                    formik.setFieldTouched('signedContract', true)
                                    formik.validateField('signedContract')
                                }}
                                    defaultValue={{
                                        startDate: dayjs(formik.values.contract_start).toDate(),
                                        endDate: dayjs(formik.values.contract_end).toDate()
                                    }}
                                    error={formik.touched.signedContract && Boolean(formik.errors.signedContract)}
                                    helperText={formik.touched.signedContract && formik.errors.signedContract}
                                    onBlur={formik.handleBlur}
                                />
                            )
                        }, [formik.values.contract_start, formik.values.contract_end, formik.touched.signedContract, formik.errors.signedContract])
                    }

                </Grid>
                <Grid item xs={12}>

                    <Dropzone onDrop={acceptedFiles => handleUploadFiles(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <Button size='small' variant="contained" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p style={{ margin: 0 }}>Click to upload files</p>
                            </Button>
                        )}
                    </Dropzone>
                    <Typography color="danger" sx={{ color: 'red' }}>
                        {formik.touched.contract_files && formik.errors.contract_files}
                    </Typography>

                    <List>
                        {files.map((file, index) => {
                            return (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                    />
                                </ListItem>
                            )
                        })}


                    </List>
                </Grid>
            </Grid>

            {isFetching && <LinearProgress />}

        </Box >


    );
}

export default FormDetails;