import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Icon, Typography } from '@mui/material';
import './FilterBar.css';
import { title } from 'process';
import { flushSync } from 'react-dom';

const items = [
    {
        img: "https://a0.muscache.com/pictures/248f85bf-e35e-4dc3-a9a1-e1dbff9a3db4.jpg",
        title: "Deptos"
    },
    {
        img: "https://a0.muscache.com/pictures/6ad4bd95-f086-437d-97e3-14d12155ddfe.jpg",
        title: "Locales"
    },
    {
        img: "https://a0.muscache.com/pictures/3b1eb541-46d9-4bef-abc4-c37d77e3c21b.jpg",
        title: "Con Pileta"
    },
    {
        img: "https://a0.muscache.com/pictures/732edad8-3ae0-49a8-a451-29a8010dcc0c.jpg",
        title: "Cabañas"
    },
    {
        img: "https://a0.muscache.com/pictures/f0c5ca0f-5aa0-4fe5-b38d-654264bacddf.jpg",
        title: "Inmobiliaria 1"
    },
    {
        img: "https://a0.muscache.com/pictures/c027ff1a-b89c-4331-ae04-f8dee1cdc287.jpg",
        title: "Terminados"
    },

    {
        img: "https://a0.muscache.com/pictures/78ba8486-6ba6-4a43-a56d-f556189193da.jpg",
        title: "En construcción"
    },
    {
        img: "https://a0.muscache.com/pictures/ca25c7f3-0d1f-432b-9efa-b9f5dc6d8770.jpg",
        title: "MGArqs"
    },
    {
        img: "https://a0.muscache.com/pictures/248f85bf-e35e-4dc3-a9a1-e1dbff9a3db4.jpg",
        title: "Inmobiliaria 2"
    },
    {
        img: "https://a0.muscache.com/pictures/248f85bf-e35e-4dc3-a9a1-e1dbff9a3db4.jpg",
        title: "Inmobiliaria 3"
    },
    {
        img: "https://a0.muscache.com/pictures/248f85bf-e35e-4dc3-a9a1-e1dbff9a3db4.jpg",
        title: "Inmobiliaria 4"
    },
    {
        img: "https://a0.muscache.com/pictures/248f85bf-e35e-4dc3-a9a1-e1dbff9a3db4.jpg",
        title: "Inmobiliaria 5"
    }
]
const SwipItem = ({ img, title }: { img: string, title: string }) => {
    return (<>
        <div style={{ textAlign: 'center' }}>
            <img src={img} alt="img" style={{ width: '1.2rem', objectFit: 'cover' }} />
        </div>
        <Typography variant="subtitle3" component="h6" fontSize={'14px'} letterSpacing={0} style={{ textAlign: 'center' }}>
            {title}
        </Typography>
    </>
    );
};

const SwipperNavButtons = ({ swiperRef }: { swiperRef: any }) => {
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef !== null) {
            swiperRef.on('slideChange', updateNavButtons);
            updateNavButtons();

            return () => {
                swiperRef.off('slideChange', updateNavButtons);
            };
        }
    }, [swiperRef]);

    const updateNavButtons = () => {
        setIsBeginning(swiperRef.isBeginning);
        setIsEnd(swiperRef.isEnd);
    };

    const goNext = () => {
        if (swiperRef !== null) {
            swiperRef.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef !== null) {
            swiperRef.slidePrev();
        }
    };

    return (
        <>
            <div className={`swiper-button-next mw ${isEnd ? 'disabled' : ''}`} onClick={goNext}>
                <Icon>chevron_right</Icon>
            </div>
            <div className={`swiper-button-prev mw ${isBeginning ? 'disabled' : ''}`} onClick={goPrev}>
                <Icon>chevron_left</Icon>
            </div>
        </>
    );
};

const FilterBar = () => {
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [selected, setSelected] = useState<number>(-1);

    const handleSelect = (index: number) => {
        setSelected(index);
    }

    return (
        <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={10}
            spaceBetween={10}
            pagination={{
                type: 'fraction',
            }}
            navigation={items.length > 10}
            modules={[Navigation]}
            slidesPerGroup={1}
            loop={true}
        >
            {swiperRef && <SwipperNavButtons swiperRef={swiperRef} />}
            {items.map((item, index) => (
                <SwiperSlide className={`selectable-slider ${selected === index && 'selected'}`} key={index} onClick={() => handleSelect(index)}>
                    <SwipItem img={item.img} title={item.title} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default FilterBar;
