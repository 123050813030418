import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import './AuthModal.css';
import { Button, styled } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import { googleConfig } from 'config';

export const GoogleButton = styled(Button)(({ theme }) => ({
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[3],
    backgroundColor: "#e0e0e0",
    "&:hover": { backgroundColor: "#d5d5d5" },
}));


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    '&:focus': {
        outline: 'none'
    }
};



type AuthModalProps = {
    state: [{
        open: boolean;
        type: "login" | "register"
    }, Dispatch<SetStateAction<{
        open: boolean;
        type: "login" | "register";
    }>>
    ];
}
export default function AuthModal({ state }: AuthModalProps) {
    const [{ open, type }, setState] = state;
    const captchaRef = useRef<ReCAPTCHA>(null);

    const handleOnClick = (text: "login" | "register") => {
        if (text !== type) {
            setState({ open: true, type: text });
            return;
        }
    };

    const containerClass =
        "container " + (type === "register" ? "right-panel-active" : "");

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setState({ open: false, type })}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} id="modalSignin">
                        <div className={containerClass} id="container">
                            <SignUpForm captchaRef={captchaRef} />
                            <SignInForm onSubmit={() => {
                                setState({ open: false, type })
                            }} />
                            <div className="overlay-container">
                                <div className="overlay">
                                    <div className="overlay-panel overlay-left">
                                        <h1>Ya tenés cuenta?</h1>
                                        <p>

                                        </p>
                                        <Button
                                            variant="contained"
                                            id="signIn"
                                            onClick={() => handleOnClick("login")}
                                        >
                                            Iniciar sesión
                                        </Button>
                                    </div>
                                    <div className="overlay-panel overlay-right">
                                        {/* <h1>¿Deseas crear una cuenta en Renvance?</h1> //? DISABLE SIGN UP
                                    <Button
                                        id="signUp"
                                        onClick={() => handleOnClick("register")}
                                        variant="contained"
                                    >
                                        Registrarse
                                    </Button> */}
                                        <h1>
                                            Somos conscientes de tus ansias de generar dinero.
                                        </h1>
                                        <h1>
                                            Pero pronto podrás registrarte en Renvance
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>

            </Modal>

            {open && <Box id="captcha-google-renvance"
                sx={{
                    '& .grecaptcha-badge': {
                        zIndex: 3001
                    },
                }}
            >
                <ReCAPTCHA
                    ref={captchaRef}
                    size="invisible"
                    sitekey={googleConfig.captcha_site_key}
                />
            </Box>}
        </>
    );
}