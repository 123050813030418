import {
    Token as TokenIcon
} from '@mui/icons-material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import SavingsIcon from '@mui/icons-material/Savings';
import { Box, Grid, Icon, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import ChartComponent from "app/components/ChartComponent";
import DataPack1 from 'app/components/DataPack1';
import TablaRow from 'app/components/TablaRow';
import { formatToUSDValue } from "app/utils/utils";
import OrderBook from 'app/views/agency-pages/properties/viewer/Pucharse/OrderBook/OrderBook';
import { PropertyPageType } from '../PropertyPage';



const OpenMarket = ({ property }: { property: PropertyPageType }) => {
    return (<>
        <Grid container spacing={2} aria-label="description-summary">
            <Grid item md={6} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2">
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="h2">
                        {property.description}
                    </Typography>
                </Grid>

            </Grid>
            <Grid item md={6} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2" textAlign='center'>
                        Summary
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DataPack1 icon={TokenIcon} title={'Token Name'} value={'URB_1234'} />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPack1 icon={CurrencyExchangeOutlinedIcon} title="Appreciation Period" value={'never'.toUpperCase()} />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPack1 icon={SavingsIcon} title="Accumulated Appreciation" value={'9%'} mb={1} />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPack1 icon={AutoGraphIcon} title="Estimated Rent" value={String(property.economics.expected_net_yearly_rent_percentage)} mb={1} />
                        </Grid>

                    </Grid>


                </Grid>
            </Grid>
        </Grid>

        <Grid container spacing={2} aria-label="buy-sell">
            <Grid item md={8}>
                <Box aria-label='property-market' sx={{
                    width: '100%',
                    height: '300px',

                }}>
                    <ChartComponent />
                </Box>
            </Grid>
            <Grid item md={4}>
                 <OrderBook/>
            </Grid>
        </Grid>



        <Grid item xs={12} pt={2} display='flex' pb={5} aria-label="property-legal-information" container spacing={3}>
            <Grid item xs={4}>
                <Typography variant="title2" component="h2" pb={1}>
                    Investment
                </Typography>

                <TablaRow title='Adminitrative Fees' value={formatToUSDValue(9925)} />
                <TablaRow title='Decoration n Furniture' value={formatToUSDValue(25212)} />
                <TablaRow title='Initial Asset Value' value={formatToUSDValue(4123125)} />
                <TablaRow title='Platform Commission Raw' value={formatToUSDValue(17002)} />
                <TablaRow title='Total Investment' value={formatToUSDValue(133332)} />
                <TablaRow title='Total Investment' value={formatToUSDValue(100000)} />
                <TablaRow title='Property Commission Raw' value={formatToUSDValue(100000)} />
                <TablaRow title='Total' value={formatToUSDValue(1000000)} />

                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="h2">
                    </Typography>
                </Grid>

            </Grid>
            <Grid item xs={4}>
                <Typography variant="title2" component="h2" pb={1}>
                    Financial
                </Typography>
                <TablaRow title="Gross rent yearly" value="$14,078 (0.03%)" />
                <TablaRow title="Gross rent monthly" value="$1,173.17" />
                <TablaRow title="Occupancy" value="86.94%" />
                <TablaRow title="Monthly Costs" value="$99,999,999" subitems={[
                    {
                        title: 'Property management',
                        value: '$845 (6%)'
                    },
                    {
                        title: 'Renance commission',
                        value: '$422 (3%)'
                    },
                    {
                        title: 'Apartment expenses',
                        value: '$411'
                    },
                    {
                        title: 'Building expenses',
                        value: '$1,228'
                    },
                    {
                        title: 'Insurance',
                        value: '$282'
                    },
                    {
                        title: 'Taxes',
                        value: '$22'
                    },
                    {
                        title: 'Expected occupancy',
                        value: '$123123'
                    }


                ]} />
                <TablaRow title="Net rent by month" value="$1230.23" />
                <TablaRow title="Net rent by Year" value="12,23" />
            </Grid>
            <Grid item xs={4}>
                <Grid item xs={12}>
                    <Typography variant="title2" component="h2">
                        Statement
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            }
                        >
                            <Icon sx={{ mr: 1 }}>description</Icon>

                            <ListItemText
                                primary="Inicio de Obra"
                                secondary={'Planos, Tareas'}
                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            }
                        >
                            <Icon sx={{ mr: 1 }}>description</Icon>

                            <ListItemText
                                primary="Gastos generales"
                                secondary={'Albanileria y Pintura'}
                            />
                        </ListItem>,
                    </List>
                </Grid>

                <Grid item xs={12}>


                </Grid>

            </Grid>
        </Grid>

    </>);
}

export default OpenMarket;