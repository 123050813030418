import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { formatToUSDValue } from 'app/utils/utils';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';

export type AccountRowsTable = {
    date: string,
    concept: string,
    amount: number,
    balance: number,
    conceptItems?: any[],
}


interface CustomTableCellProps {
    value: number;
}

const CustomTableCell = styled(TableCell)<CustomTableCellProps>(({ value }) => ({
    color: value > 0 ? 'green' : value < 0 ? 'red' : 'inherit',
}));



function Row(props: { row: AccountRowsTable }) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >

                <TableCell> {dayjs(row.date).format('DD/MM/YYYY')} </TableCell>
                <TableCell component="th" scope="row" align="left">{row.concept}</TableCell>
                <CustomTableCell value={row.amount} align="right"> {formatToUSDValue(row.amount)} </CustomTableCell>
                <CustomTableCell value={row.balance} align="right">{formatToUSDValue(row.balance)}</CustomTableCell>
                <TableCell align="right">{""}</TableCell>
                <TableCell
                    align="right"
                >

                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Concept</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.conceptItems && row.conceptItems?.map((d: any, i: number) => (
                                        <TableRow key={i + "asdasd"}>
                                            <TableCell component="th" scope="row">
                                                {"asdasd"}
                                            </TableCell>
                                            <TableCell>{"asdasd"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}



const AccountTable = ({ rows }: { rows: AccountRowsTable[] }) => {

    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof AccountRowsTable>('date');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof AccountRowsTable,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const createSortHandler =
        (property: keyof AccountRowsTable) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow> 
                        <TableCell
                            key={'date'}
                            align='left'
                            sortDirection={orderBy === 'date' ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === 'date'}
                                direction={orderBy === 'date' ? order : 'asc'}
                                onClick={createSortHandler('date')}
                            >
                                Date
                                {orderBy === 'date' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">Concept</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Balance</TableCell>
                        <TableCell align="right" width={'10%'}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: AccountRowsTable, i: number) => (
                        <Row key={`${i}${row.concept}`} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AccountTable;