import useAuth from "app/hooks/useAuth";
import { UserRole } from "app/redux/models";
import { Navigate, useLocation } from "react-router-dom";


const AuthGuard = ({ children, allowRoles, redirect = "/" }: { children: JSX.Element | JSX.Element[], allowRoles: UserRole[], redirect?: string }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, user, isInitialised} = useAuth();

  if (!isInitialised) {
    return null;
  }

  if (isAuthenticated) {
    if (allowRoles.includes(user.role)) {
      return <>{children}</>;
    }
    return <Navigate replace to={redirect} state={{ from: pathname }} />;
  }

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
