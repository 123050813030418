import { OBContainer, OBTableContainer } from "./OBStyles";
import { useState } from 'react'
import OBTitleRow from "./OBTitleRow";
import { Box } from "@mui/material";
import { OrderType } from "./OrderBook";
import OBPriceLevelRow from "./OBPriceLevelRow";
import { formatToUSDValue } from "app/utils/utils";


let currentBids: number[][] = [
    [
        27078,
        20000,
        20000,
        5.680930536421866
    ],
    [
        27074.5,
        3600,
        23600,
        6.703498032977802
    ],
    [
        27074,
        6000,
        29600,
        8.407777193904362
    ],
    [
        27073.5,
        3600,
        33200,
        9.430344690460297
    ],
    [
        27073,
        6000,
        39200,
        11.134623851386857
    ]
]
let currentAsks: number[][] = [
    [
        27080,
        12,
        13,
        0
    ],
    [
        27081,
        6000,
        6000,
        0.7984903209664928
    ],
    [
        27083,
        3700,
        9700,
        1.2908926855624965
    ],
    [
        27083.5,
        9600,
        19300,
        2.568477199108885
    ],
    [
        27084,
        49998,
        69298,
        9.22229704372267
    ]
]

export const formatNumber = (arg: number): string => {
    return new Intl.NumberFormat('en-US').format(arg);
};


const buildPriceLevels = (levels: number[][], orderType: OrderType = OrderType.BIDS): React.ReactNode => {
    const priceColor = orderType === OrderType.BIDS ? 'success.main' : 'error.main';

    const sortedLevelsByPrice: number[][] = [...levels].sort(
        (currentLevel: number[], nextLevel: number[]): number => {
            let result: number = 0;
            if (orderType === OrderType.BIDS) {
                result = nextLevel[0] - currentLevel[0];
            } else {
                result = currentLevel[0] - nextLevel[0];
            }
            return result;
        }
    );

    return (
        sortedLevelsByPrice.map((level, idx) => {
            const calculatedTotal: number = level[2];
            const total: string = formatNumber(calculatedTotal);
            const depth = level[3];
            const size: string = formatNumber(level[1]);
            const price: string = formatToUSDValue(level[0]);

            return (
                <Box aria-label='PriceLevelRowContainer'
                    sx={{ margin: '.155em 0' }}
                    key={idx + depth}>
                    <OBPriceLevelRow key={size + total}
                        total={total}
                        size={size}
                        price={price}
                        reversedFieldsOrder={false}
                        color={priceColor}
                    />
                </Box>
            );
        })
    );
};

const OBContent = () => {
    const [bids] = useState(currentBids);
    const [asks] = useState(currentAsks);



    return (<>
        <OBContainer sx={{ flexDirection: 'column' }}>
            <OBTableContainer>
                <OBTitleRow />
                <div>{buildPriceLevels(bids, OrderType.BIDS)}</div>
            </OBTableContainer>
            <OBTableContainer>
                <OBTitleRow />
                <div>{buildPriceLevels(asks, OrderType.ASKS)}</div>
            </OBTableContainer>

        </OBContainer>
    </>);
}

export default OBContent;