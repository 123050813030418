import { Button, Card, Grid, LinearProgress, Skeleton, Typography, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/system";
import ModalOffer from "app/components/Modals/ModalOffer";
import useAuth from "app/hooks/useAuth";
import { PricesChartInterface, propertiesApi } from "app/redux/api/propertiesApi";
import { formatToUSDValue } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import CrowdFunding from './ContentByMode/CrowdFunding';
import OpenMarket from './ContentByMode/OpenMarket';
import PropertyHeader from './PropertyHeader';
import PropertyImages from "./PropertyImages";


const dummyObject = {
    "address": "670 Oak Street, Sao Paulo",
    "amenities": [],
    "apartment": "A",
    "balance": 0,
    "bathrooms": 5,
    "building_type": "HOUSE",
    "city": "SP",
    "construction_completed": "2027-10-15",
    "construction_type_code": "UNDER_CONSTRUCTION",
    "country": "BRZ",
    "created_at": "2023-12-28T14:50:22.101583",
    "deleted_at": null,
    "description": "Welcome to this luxurious apartment located in the heart of Buenos Aires, Argentina. This modern unit boasts stunning city views from its spacious balcony, perfect for attracting potential tenants seeking a vibrant urban lifestyle. The apartment comes fully furnished with top-notch amenities, including a fitness center, swimming pool, and 24/7 security for added convenience and safety. Its prime location offers easy access to major attractions, shopping centers, and public transportation, making it an excellent investment opportunity for lucrative rental income.",
    "dorms": 6,
    "economics": {
        "active_buying": true,
        "active_renting": false,
        "active_selling": false,
        "cf_interest": 6.97,
        "cf_type": "CF_OUT_RETURN_INTEREST",
        "created_at": "2023-12-28T14:50:22.101583",
        "current_market_asset_value": 478573,
        "deleted_at": null,
        "expected_net_yearly_rent_percentage": 2.3,
        "fund_raising_end": "2028-01-13",
        "fund_raising_start": "2023-12-28",
        "id": 1,
        "investment_type": "CROWD_FUNDING",
        "modified_at": null,
        "re_appreciation_period": "MONTHLY",
        "token_amount": 2393,
        "token_available": 2393,
        "token_price": 200,
        "token_ticker": "LHS_0122",
        "updated_by": null,
        "construction": {
            "asset_value": 426848.12,
            "asset_value_at_completed": null,
            "expected_asset_value": 510139,
            "construction_completed": "2027-10-15",
            "construction_started": "2023-10-16",
            "construction_status": "HALF_CONCRETE"
        },
        "construction_type_code": "UNDER_CONSTRUCTION",
        "current_market_value": 478573,
        "current_reference_value": 478573,
        "estimation": {
            "cost_apartment_expenses": 309.63,
            "cost_building_expenses": 1500.37,
            "cost_insurance": 288.29,
            "cost_taxes": 288.29,
            "gross_rent_yearly_percentage": 0.03,
            "gross_rent_yearly_raw": 14414.64,
            "net_rent_yearly_percentage": 2.3,
            "occupancy": 85.66,
            "platform_management_commission_percentage": 2,
            "platform_management_commission_raw": 288.29,
            "property_management_commission_percentage": 5,
            "property_management_commission_raw": 720.73
        },
        "minimal_permanency_period": "SEMESTER",
        "name": "Aqua Horizon Apartments And Residence_0853",
        "plan_code": "PLN_01",
        "property_code": "PROP_7563404",
        "property_uuid": "cf86eb61-83d2-4db9-82ef-67d378593553",
        "rent_distribution_period": "SEMESTER",
        "total_investment": {
            "administrative_fees": 9444.77,
            "decoration_n_furniture": 20936.9,
            "initial_asset_value": 426848.12,
            "platform_buy_cx": 8536.96,
            "property_buy_cx": 12805.44
        },
        "total_investment_data": {
            "administrative_fees": 9444.77,
            "decoration_n_furniture": 20936.9,
            "initial_asset_value": 426848.12,
            "platform_buy_commission_amount": 8536.96,
            "property_buy_commission_amount": 12805.44
        }
    },
    "floor": 13,
    "manager": {
        "company_name": "Renvance",
        "contact_name": "Stephanie Murray",
        "created_at": "2023-12-28T14:50:22.101583",
        "deleted_at": null,
        "email": "nrodriguez@example.com",
        "id": 1,
        "modified_at": null,
        "phone": "9784868427",
        "updated_by": null
    },
    "modified_at": null,
    "name": "Aqua Horizon Apartments And Residence_0853",
    "parking_lots": 0,
    "pictures": [
        "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/pic3.jpg",
        "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/pic2.jpg",
        "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/pic4.jpg",
        "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/pic8.jpg",
        "https://renvance-images-bucket.s3.us-east-2.amazonaws.com/pic1.jpg"
    ],
    "property_code": "PROP_7563404",
    "property_uuid": "cf86eb61-83d2-4db9-82ef-67d378593553",
    "state": "Sao Paulo",
    "surface": 432,
    "updated_by": "mildo@renvance.com",
    "uuid": "8feeaf51-4fe3-4f7f-aea5-a1033d814e6c",
    "zipcode": "54321",
    "token": {
        "active": false,
        "created_at": "2023-12-28T14:50:22.359963",
        "deleted_at": null,
        "deployment_date": null,
        "id": 1,
        "modified_at": null,
        "property_code": "PROP_7563404",
        "property_uuid": "cf86eb61-83d2-4db9-82ef-67d378593553",
        "token_address": null,
        "token_amount": 2393,
        "token_in_market": 0,
        "token_name": "LeisureHomes_0122",
        "token_price": 200,
        "token_ticker": "LHS_0122",
        "updated_by": "mildo@renvance.com",
        "uuid": "210bfc14-bd76-4560-a22d-e2d99b5410ac"
    }
}

export type PropertyPageType = typeof dummyObject;




const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'primary'
    },
}));


const PropertyInvestmentCard = ({ token, price, onClickBuy }: { token: string, price: number, onClickBuy: () => void }) => {
    return (
        <Card>

            <Grid container sx={{
                backgroundColor: '#9199b830',
                padding: '1rem',
            }}>
                <Grid item xs={12} display='inline-flex' justifyContent='space-between'>
                    <Typography variant="title3" component="p">
                        Starting At
                    </Typography>
                    <Typography variant="title3" component="p">
                        {formatToUSDValue(50)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <BorderLinearProgress variant="determinate" value={50} />
                </Grid>
                <Grid item xs={12} display='inline-flex' justifyContent='space-between'>
                    <Typography variant="subtitle2" component="p">
                        51%
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        2,123 tokens left
                    </Typography>
                </Grid>

            </Grid>
            <Grid item xs={12} display={'flex'} mt={3} px='1rem' pb='1rem'>
                <Grid item xs={6} pr={1}>
                    <Button variant="contained" fullWidth onClick={() => onClickBuy()} >Buy</Button>
                </Grid>
                <Grid item xs={6} pl={1}>
                    <Button variant="outlined" fullWidth >Sell</Button>
                </Grid>
            </Grid>

        </Card >
    );
};



enum Modes {
    OpenMarket = 'OM',
    CrowdFunding = 'CF'
}

const ModesHandler = ({ modes, property, prices, loading }: { modes?: Modes, property: any, prices: any, loading: boolean }) => {
    return !loading && modes ? (
        <>
            {modes === Modes.OpenMarket ? (<OpenMarket property={property} />) : null}
            {modes === Modes.CrowdFunding && (<CrowdFunding property={property} prices={prices} />)}
        </>
    ) : <>


        <Skeleton variant="rectangular" width={'100%'} height={30} animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width={'100%'} height={30} animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width={'100%'} height={250} animation='wave' />

    </>


}




const PropertyPage = () => {

    const { propUUID } = useParams()
    const { user } = useAuth()
    const [getProperty] = propertiesApi.endpoints.getPropertyDetails.useLazyQuery()
    const [getPrices] = propertiesApi.endpoints.getPropertyPrices.useLazyQuery()

    const [property, setProperty] = useState<PropertyPageType>()
    const [prices, setPrices] = useState<PricesChartInterface>()
    const [modes, setModes] = useState<Modes>()
    const [loading, setLoading] = useState(true)

    const getPropertyDetails = async (propUUID: string) => {
        const token = user ? await user.getAsyncToken() : ''
        getProperty({ uuid: propUUID, token: token }).unwrap()
            .then((res) => {
                setProperty(res.page[0] as any)
                setModes(res.page[0].economics.investment_type === 'CROWD_FUNDING' ? Modes.CrowdFunding : Modes.OpenMarket)
                getPropertyPrices(propUUID)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const getPropertyPrices = async (propUUID: string) => {
        const token = user ? await user.getAsyncToken() : ''
        getPrices({ uuid: propUUID, token: token }).unwrap()
            .then((res) => {
                setPrices(res);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                //    Should add loading animation end 
            })
    }

    useEffect(() => {
        propUUID && getPropertyDetails(propUUID)
        propUUID && getPropertyPrices(propUUID)
    }, [propUUID])



    const [isModalOfferOpen, setIsModalOfferOpen] = useState(false);


    return (<PageContainer >
        {property && <PropertyHeader uuid={property.property_uuid} title={property.address} property={property}/>}
        <PropertyImages images={property?.pictures} loading={loading} />

        <Grid container aria-label='property-details' sx={{ mt: 2 }} component='section'>

            <ModesHandler loading={loading} modes={modes} property={property} prices={prices} />
        </Grid>

        {isModalOfferOpen && <ModalOffer open={isModalOfferOpen} onClose={() => setIsModalOfferOpen(false)} />}
    </PageContainer>);
}


{/* 
<Grid item xs={6}>
    <PropertyInvestmentCard token="TuToken" price={100000} onClickBuy={() => {
        setIsModalOfferOpen(true);
    }} />
</Grid>
 */}


export default PropertyPage;