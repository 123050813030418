import { Box, Button, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, Icon, InputLabel, MenuItem, Select, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useEffect, useState } from "react";
import { date, number, object, string, boolean } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { DateRangeOutlined } from "@mui/icons-material";
import TextFieldFormik from "../FormikUI/TextFieldFormik";


const MAX_COMMISSION = 10
const MIN_COMMISSION = 0.51



const validationSchema = object().shape({
    plan_name: string().required("Plan Name is required"),
    plan_code: string().required("Plan Code is required"),
    cf_return_type: string().required("CF Resolution Policy is required"),
    cf_return_interest: string().required("CF Return Interest is required"),
    platform_buying_commission_in_token: boolean(),
    property_buying_commission_in_token: boolean(),
    platform_buying_commission_percentage: number().required("Platform Buying Commission is required")
        .min(0, `Min Commission is ${0}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),
    property_buying_commission_percentage: number().required("Property Buying Commission is required")
        .min(0, `Min Commission is ${0}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),


    platform_trading_commission_in_percentage: number().required("Platform Trading Commission is required")
        .min(MIN_COMMISSION, `Min Commission is ${MIN_COMMISSION}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),
    property_trading_commission_in_percentage: number().required("Property Trading Commission is required")
        .min(MIN_COMMISSION, `Min Commission is ${MIN_COMMISSION}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),

    property_management_commission_percentage: number().required("Property Management Commission is required")
        .min(MIN_COMMISSION, `Min Commission is ${MIN_COMMISSION}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),
    platform_management_commission_percentage: number().required("Platform Management Commission is required")
        .min(MIN_COMMISSION, `Min Commission is ${MIN_COMMISSION}`)
        .max(MAX_COMMISSION, `Max Commission is ${MAX_COMMISSION}`),

    // annual_cost: number().required("Annual Cost is required"),
    // discount: number().required("Discount is required"),
    // min_properties_to_publish: number().min(0, 'At Least 0')

});

const TextFieldForm = ({ name, label, formik, type = 'text', min, max }: { name: any, label: string, formik: any, type?: 'text' | 'number', min?: number, max?: number }) => {
    return (
        <TextField fullWidth label={label} name={name} variant="outlined" size="small"
            type={type}
            inputProps={{
                min: min,
                max: max,
            }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    )
}

//class CFReturnType(str, Enum):
//     OUT_OWNER_KEEP_DIFF = "CF_OUT_OWNER_KEEP_DIFF"
//     CF_OUT_RETURN_INTEREST = "CF_OUT_RETURN_INTEREST"
//     CF_IN_RETURN_MONEY = "CF_IN_RETURN_MONEY"

const resolutionTypes = [
    {
        label: 'CF OUT OWNER KEEP DIFF',
        value: 'CF_OUT_OWNER_KEEP_DIFF'
    },
    {
        label: 'CF OUT RETURN INTEREST',
        value: 'CF_OUT_RETURN_INTEREST'
    },
    {
        label: 'CF IN RETURN MONEY',
        value: 'CF_IN_RETURN_MONEY'
    }
]



function ModalPlan({ open, onClose, onSubmit, data, mode }: { open: any; onClose: any; onSubmit: (plan: any) => void; data?: any, mode: 'edit' | 'add' }) {

    useEffect(() => {
        if (mode === 'add') {
            return formik.resetForm()
        }
        if (data) {
            formik.setValues(data)
        }
    }, [data, open])


    const formik = useFormik({
        initialValues: {
            plan_name: '',
            plan_code: '',
            cf_return_type: '',
            cf_return_interest: '',
            platform_buying_commission_in_token: false,
            property_buying_commission_in_token: false,
            platform_trading_commission_in_percentage: 0,
            property_trading_commission_in_percentage: 0,
            property_management_commission_percentage: 0,
            platform_management_commission_percentage: 0,
            platform_buying_commission_percentage: 0,
            property_buying_commission_percentage: 0,
            //min_properties_to_publish: 0,
            //annual_cost: 0,
            //discount: 0,
            //@ts-ignore
            ...data
        },
        validationSchema: validationSchema,
        onSubmit: () => { },
    });


    const handleSubmit = () => {
        formik.handleSubmit()
        const values = validationSchema.cast(formik.values);
        const isValid = validationSchema.isValidSync(values) ? values : false;
        if (isValid) {
            onSubmit(isValid)
        }
    }
    const handleClose = () => {
        formik.resetForm()
        onClose()
    }


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <Typography variant="title2" component='p'>{mode} plan</Typography>
                <Divider sx={{ mb: 2 }} />

                <Box component='form' p={{ xs: 0, md: '0px 4rem' }} maxWidth='45rem' m='0 auto'>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextFieldForm label="Plan Name" name='plan_name' formik={formik} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Plan Code" name='plan_code' formik={formik} />
                        </Grid>
                        {/*
                            In the future, we will add these fields
                        * */}
                        {/* <Grid item xs={4}>
                            <TextFieldForm label="Min Properties to publish" name='min_properties_to_publish' type='number' min={0} formik={formik} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Annual Cost" name='annual_cost' formik={formik} type='number' min={0} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Discount" name='discount' formik={formik} type='number' min={0} />
                        </Grid> */}





                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel size="small">CF Resolution Policy</InputLabel>
                                <Select
                                    error={formik.touched.cf_return_type && Boolean(formik.errors.cf_return_type)}
                                    value={formik.values.cf_return_type}
                                    label="CF Resolution Policy"
                                    name="cf_return_type"
                                    size="small"
                                    onChange={formik.handleChange}
                                >
                                    {
                                        resolutionTypes.map((type, index) => {
                                            return <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                                        })
                                    }

                                </Select>
                                <FormHelperText error>
                                    {/** @ts-ignore */}
                                    {formik.touched.cf_return_type && formik.errors.cf_return_type}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldForm type="number" label="CF return interest" name="cf_return_interest" formik={formik} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" component='p'>Commissions</Typography>
                        </Grid>


                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" component='p'>Company</Typography>

                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" component='p'>Platform</Typography>
                        </Grid>



                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component='span'>Buying</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldFormik label="Buying Company" type='number' name="property_buying_commission_percentage" formik={formik} />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={formik.values.property_buying_commission_in_token} onChange={() => {
                                        formik.setFieldValue('property_buying_commission_in_token', !formik.values.property_buying_commission_in_token)
                                    }} name="property_buying_commission_in_token" />
                                }
                                label="In Tokens"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldFormik label="Buying Renvance" name="platform_buying_commission_percentage" type='number' formik={formik} />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={formik.values.platform_buying_commission_in_token} onChange={() => {
                                        formik.setFieldValue('platform_buying_commission_in_token', !formik.values.platform_buying_commission_in_token)
                                    }} name="platform_buying_commission_in_token" />
                                }
                                label="In Tokens"
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component='span'>Trading</Typography>

                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Trading Company" name='property_trading_commission_in_percentage' formik={formik} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Trading Renvance" name='platform_trading_commission_in_percentage' formik={formik} />
                        </Grid>



                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component='span'>Managing</Typography>

                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Managing Your Company" name='property_management_commission_percentage'
                                formik={formik}
                                type="number"
                                min={0.6}
                                max={10}


                            />

                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldForm label="Managing Renvance" name='platform_management_commission_percentage' formik={formik} />

                        </Grid>


                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button onClick={handleSubmit} variant="contained" startIcon={<SaveIcon />} color="primary"
                                title="Save"
                            >
                                Save
                            </Button>
                            <Button onClick={handleClose} variant="outlined" sx={{ ml: 1 }} title="Cancel">
                                Cancel
                            </Button>

                        </Box>



                    </Grid>


                </Box>

            </DialogContent>
        </Dialog >
    );
}

export default ModalPlan;
