import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import DropZone from 'app/components/DropZone';
import { object, string } from 'yup';


const AddExpenseModal = ({ open, onClose }: { open: boolean, onClose: () => void, onSubmit: () => void }) => {
    const [files, setFiles] = useState<File[]>([])

    const handleClose = () => {
        onClose()

        //Hago esto para arreglar un pequeño bug que hace que el dialogo
        // se cierre antes de que se termine de hacer la animacion
        const  cleanUp =  async () =>  {
            setTimeout(() => {
                setFiles([])
            }, 200)

        }
        cleanUp()
    };



    const AddStatementSchema = object().shape({
        description: string()
            .min(5, 'This provider name is too short')
            .max(50, 'This provider name is too long')
            .required('You must provide a description'),
    });




    return (
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Date: {dayjs(new Date()).format('DD/MM/YY')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add the statement description and upload the file
                </DialogContentText>
                <Formik
                    initialValues={{
                        description: '',
                    }}
                    validationSchema={AddStatementSchema}
                    onSubmit={(values: any) => { console.log(values) }}

                >
                    {({ errors, touched }) => (
                        <Form>

                            <TextField
                                autoFocus
                                margin="dense"
                                id="description"
                                label="Description"
                                type="text"
                                fullWidth
                                multiline
                                rows={1}
                                name="description"
                                error={errors.description !== '' && touched.description as boolean}
                                helperText={errors.description !== '' ? errors.description as string : ''}

                            />

                            <DropZone images files={files} onSetFiles={(files) => setFiles(files)}/>

                            <Button type="submit" color="primary">Add Statement</Button>
                        </Form>
                    )}




                </Formik>

            </DialogContent>
        </Dialog >
    );
}

export default AddExpenseModal;