import { Icon, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { stableSort } from '../../../../utils/utils';


interface Data {
    propertyCode: string;
    tokenTicker: string;
    previousDistribution: string;
    nextDistribution: string;
    status: string;
    adminFees: string;
    plataformFee: number;
    receiptDeposit: number;
    amountDeposit: number;
    expectedAmountDeposit: string;
}



function createData(
    propertyCode: string,
    tokenTicker: string,
    previousDistribution: string,
    nextDistribution: string,
    status: string,
    adminFees: string,
    plataformFee: number,
    receiptDeposit: number,
    amountDeposit: number,
    expectedAmountDeposit: string,


): Data {
    return {
        propertyCode,
        tokenTicker,
        previousDistribution,
        nextDistribution,
        status,
        adminFees,
        plataformFee,
        receiptDeposit,
        amountDeposit,
        expectedAmountDeposit,

    };
}

const rows: Data[] = [
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
    createData("APT101", "Re$", "10/07/23", "10/08/23", "Pending", "35", 17.5, 100, 100, "465"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | any },
    b: { [key in Key]: number | string | any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    colSpan?: number;
    rowSpan?: number;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'propertyCode',
        numeric: false,
        disablePadding: true,
        label: 'Property Code',
        rowSpan: 2,
    },
    {
        id: 'tokenTicker',
        numeric: false,
        disablePadding: true,
        label: 'Token Ticker',
        rowSpan: 2,
    },
    {
        id: 'previousDistribution',
        numeric: false,
        disablePadding: true,
        label: 'Previous Distribution',
        rowSpan: 2,
    },
    {
        id: 'nextDistribution',
        numeric: false,
        disablePadding: true,
        label: 'Next Distribution',
        rowSpan: 2,
    },
    {

        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
        rowSpan: 2,
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ wordBreak: 'break-word' }}
                        colSpan={headCell.colSpan}
                        rowSpan={headCell.rowSpan}
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                    Fees
                </TableCell>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                    Deposit
                </TableCell>
                <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                    Actions
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    align={'center'}
                    padding={'normal'}


                >
                    Admin
                </TableCell>
                <TableCell
                    align={'center'}
                    padding={'normal'}
                >
                    Plataform
                </TableCell>
                <TableCell
                    align={'center'}
                    padding={'normal'}
                >
                    Recepit
                </TableCell>
                <TableCell
                    align={'center'}
                    padding={'normal'}
                >
                    Amount
                </TableCell>
                <TableCell
                    align={'center'}
                    padding={'normal'}
                >
                    Expected
                </TableCell>
            </TableRow>
        </TableHead>
    );
}




export default function DistRegisterTable() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('propertyCode');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.propertyCode);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );


    const MyRow = ({ row, index }: { row: Data, index: number }) => {
        const [isEditable, setIsEditable] = React.useState(false);
        return (<>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{ cursor: 'pointer' }}
                key={'row' + index}
            >
                <TableCell>
                    {row.propertyCode}
                </TableCell>
                <TableCell>
                    {row.tokenTicker}
                </TableCell>
                <TableCell>
                    {row.previousDistribution}
                </TableCell>
                <TableCell>
                    {row.nextDistribution}
                </TableCell>
                <TableCell>
                    {row.status}
                </TableCell>
                <TableCell align='center'>
                    {row.adminFees}
                </TableCell>
                <TableCell align='center'>
                    {row.plataformFee}
                </TableCell>
                <TableCell align="center">
                    {
                        isEditable ?
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={row.receiptDeposit}
                                onChange={(e) => {
                                    row.receiptDeposit = parseInt(e.target.value);
                                }} />
                            :
                            row.receiptDeposit
                    }
                </TableCell>
                <TableCell align="center">
                    {
                        isEditable ?
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={row.amountDeposit}
                                onChange={(e) => {
                                    row.amountDeposit = parseInt(e.target.value);
                                }} />
                            :
                            row.amountDeposit
                    }
                </TableCell>
                <TableCell align='center'>
                    {row.expectedAmountDeposit}
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="edit row"
                        size="small"
                        onClick={() => setIsEditable(!isEditable)}
                    >
                        {isEditable ? <Icon>save_as</Icon> : <Icon>edit</Icon>}

                    </IconButton>
                </TableCell>
            </TableRow>
        </>);

    }



    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                return <MyRow row={row} index={index} key={'mapRows' + index} />
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}