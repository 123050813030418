import { TextField, TextFieldProps, TextFieldVariants } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    min?: number;
    max?: number;
    suffix?: string;
    prefix?: string;
}


const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, min, max, prefix, suffix, ...other } = props;
        return (
            <NumericFormat
                {...other}
                suffix={suffix}
                prefix={prefix}
                getInputRef={ref}
                isAllowed={({ floatValue }) => {
                    const isUndefined = floatValue === undefined;
                    if (isUndefined) return false;

                    if (min !== undefined) {
                        if (floatValue < min) return false
                    }
                    if (max !== undefined) {
                        if (floatValue > max) return false
                    }

                    return true

                }}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                valueIsNumericString
                decimalSeparator=','

                min={min}
                max={max}
            />
        );
    },
);

export interface NumberFieldProps {
    value?: number;
    onChange?: (number: number) => void;
    name?: string;
    label?: TextFieldProps['label'];
    size?: "small" | "medium";
    variant?: TextFieldVariants;
    fullWidth?: boolean;
    min?: number;
    max?: number;
    endAdornment?: JSX.Element | string;
    disabled?: TextFieldProps['disabled']
    error?: TextFieldProps['error']
    helperText?: TextFieldProps['helperText']
    prefix?: string;
    suffix?: string;
    readOnly?: boolean
}


const NumberField = ({ value, onChange, name, label, size = "small", variant, fullWidth, min, max, endAdornment, disabled, readOnly, error, helperText, prefix, suffix }: NumberFieldProps) => {




    return <TextField
        label={label}
        value={value}
        onChange={({ target: { value } }) => onChange && onChange(Number(value))}
        name={name}
        InputProps={{
            inputComponent: NumericFormatCustom as any,
            endAdornment,
            readOnly,
            inputProps: { min, max, prefix, suffix }
        }}
        size={size}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        type='text'
        error={error}
        helperText={helperText}


    />
}

export default NumberField;