import { Icon, Typography } from "@mui/material";
import CrowdFundingBar from "app/components/CrowdFundingBar";
import CardProperty from "app/components/cards/CardProperty";
import { Property } from "app/redux/models";
import { formatToUSDValue } from "app/utils/utils";

const CrowdfundingContent = ({ investData }: { investData: Property }) => {
    return <>
        <div>
            <Typography gutterBottom variant="title3" component="h3" fontSize='1rem' sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '&:hover': {
                    whiteSpace: 'normal',
                }
            }}>
                {investData.address}, {investData.city}
            </Typography>

            <Typography variant="subtitle1" component='h4' sx={{ fontSize: '1.2em !important' }}>
                Valor terminada: <span style={{ color: '#19BF52' }}>{investData.economics.expected_net_yearly_rent_percentage}% <Icon sx={{ pt: '7px' }}>trending_up</Icon></span>
            </Typography>
            <Typography mb={2} variant="subtitle1" component='h4' sx={{ fontSize: '1.2em !important' }}>
                Renta esperada: <span style={{ color: '#19BF52' }}>{investData.economics.expected_net_yearly_rent_percentage}% <Icon sx={{ pt: '7px' }}>trending_up</Icon></span>
            </Typography>
        </div>

        <CrowdFundingBar
            noAmountColected
            title=''
            remaining={investData.economics.current_market_asset_value - 9999}
        />

    </>
}

const OpenMarketContent = ({ investData }: { investData: Property }) => {
    return <div>

        <Typography gutterBottom variant="title3" component="h3" fontSize='initial'>
            {investData.address}, {investData.city}
        </Typography>

        <Typography variant="subtitle1" component='h4' sx={{ fontSize: '1.2em !important' }}>
            Retornos por año: <span style={{ color: '#19BF52' }}>+{investData.economics.expected_net_yearly_rent_percentage}% <Icon sx={{ pt: '7px' }}>trending_up</Icon></span>
        </Typography>
        <Typography variant="title3" component='h4' fontSize='initial'>
            Desde {formatToUSDValue(25)} ReUSD <p style={{ margin: 0 }}>por token</p>
        </Typography>
        <Typography variant="subtitle3" component='h5'>
            Fecha de pago  12/12/2003
        </Typography>
    </div>

}


const InvestCard = ({ investData, onClick }: { investData: Property, onClick?: () => void }) => {

    return <CardProperty
        chipType={investData.economics.investment_type}
        onClick={onClick && onClick}
        image={investData.pictures[0]}
        uuid={investData.property_uuid}
        content={
            investData.economics.investment_type === 'CROWD_FUNDING' ?
                <CrowdfundingContent investData={investData} />
                :
                <OpenMarketContent investData={investData} />
        }
    />
}

export default InvestCard;