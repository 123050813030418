import { JSXElementConstructor, ReactElement, useEffect, useReducer } from "react";

import { firebaseConfig, googleConfig } from "config";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile
} from "firebase/auth";

import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore/lite';
import { IUser, UserRole } from "app/redux/models";
import AuthContext from "./FirebaseAuthContext";
import { useNavigate } from "react-router-dom";


export const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(googleConfig.captcha_enterprice_site_key),
  isTokenAutoRefreshEnabled: true 
});

const auth = getAuth(app);
//const analytics = getAnalytics(app);

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case "FB_AUTH_STATE_CHANGED": {
            const { isAuthenticated, user } = action.payload;
            return { ...state, isAuthenticated, isInitialised: true, user };
        }

        default: {
            return state;
        }
    }
};


interface IInitialAuthState {
    user: any;
    isInitialised: boolean;
    isAuthenticated: boolean;
}

const initialAuthState: IInitialAuthState = {
    user: null,
    isInitialised: false,
    isAuthenticated: false,
};


export const MyAuthProvider = ({ children }: { children: JSX.Element | JSX.Element[] | ReactElement<any, string | JSXElementConstructor<any>> }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const navigate = useNavigate();

    const signInWithEmail = (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);


    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    };

    const createUserWithEmail = async (email: string, password: string, name: string, lastname: string) => {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        updateProfile(userCredential.user, {
            displayName: name + " " + lastname
        }).then(() => {
        }).catch((error) => {
            console.log("HUBO UN ERROR, NO SE ACTUALIZO EL PERFIL " + error)
        });
    };


    const getFavourites = async (uid: string) => {
        try {
            const db = getFirestore();
            const userDocRef = doc(db, `users/${uid}/information/features`);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (!userData.favorites) {
                    await setDoc(userDocRef, { favorites: [] });
                    return [];
                }
                return userData.favorites;
            } else {
                await setDoc(userDocRef, { favorites: [] });
                return [];
            }
        } catch (error) {
            return []
        }
    }

    const _addFavourite = async (uid: string, property_uuid: string) => {
        const db = getFirestore();
        getFavourites(uid).then(favs => {
            if (favs.includes(property_uuid)) return;
            favs.push(property_uuid);
            const userDocRef = doc(db, `users/${uid}/information/features`);
            setDoc(userDocRef, { favorites: favs });
        });
    }

    const _deleteFavourite = async (uid: string, property_uuid: string) => {
        const db = getFirestore();
        getFavourites(uid).then(favs => {
            favs = favs.filter((fav: string) => fav !== property_uuid);
            const userDocRef = doc(db, `users/${uid}/information/features`);
            setDoc(userDocRef, { favorites: favs });
        });
    }

    const updateDispatchUser = (user: IUser | null, auth = true) => {
        dispatch({
            type: "FB_AUTH_STATE_CHANGED",
            payload: {
                isAuthenticated: auth,
                user: user,

            },
        })
    }

    const logout = () => {
        signOut(auth).finally(() => {
            navigate('/', { replace: true });
        });
    };

    useEffect(() => {
        // escucha si hay algun cambio en el estado de autenticacion
        const unsubscribe = onAuthStateChanged(auth, user => {

            //si hay usuario lo guarda en la store de redux   
            if (user) {

                let miUser: IUser = {
                    accessToken: '',
                    ...user,
                    role: UserRole.GUEST,
                    bookings: [],
                    favorites: [],
                    claims: {},
                    toggleFavourite: (property_uuid: string) => {
                        if (miUser.favorites.includes(property_uuid)) {
                            _deleteFavourite(user.uid, property_uuid)
                            miUser.favorites = miUser.favorites.filter((fav: string) => fav !== property_uuid);
                        }
                        else {
                            _addFavourite(user.uid, property_uuid)
                            miUser.favorites.push(property_uuid);
                        };
                        updateDispatchUser(miUser);
                    },


                    getAsyncToken: async (forceRefresh) => {
                        const token = await user.getIdToken(forceRefresh);
                        miUser.accessToken = token;
                        return token;
                    }
                }

                user.getIdTokenResult().then(async idTokenResult => {
                    const claims = idTokenResult.claims;
                    miUser.claims = claims;
                    miUser.accessToken = idTokenResult.token;
                    if (claims.role) {
                        miUser.role = claims.role;
                    }
                    miUser.favorites = await getFavourites(user.uid);
                    updateDispatchUser(miUser);
                })
            }
            else {
                updateDispatchUser(null, false);
            }
        });

        return () => unsubscribe();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    return (
        <AuthContext.Provider value={{
            ...state,
            logout,
            signInWithGoogle,
            method: "FIREBASE",
            signInWithEmail,
            createUserWithEmail,
        }}
        >
            {children}
        </AuthContext.Provider>);
};