import { Grid, Icon, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const CashTranfer = () => {

    const email = 'depositos@renvance.com'
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid container spacing={2}>
            <Typography variant="subtitle1" mb={3} mx={2}>
                Para hacer un deposito en efectivo debe coordinar una reunión en nuestras oficinas. <br/>
                Por favor contactese con nosotros a <strong>{email}</strong>
                <IconButton onClick={() => {
                    navigator.clipboard.writeText(email)
                    enqueueSnackbar('Email copied to clipboard', {
                        variant: 'success', autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',

                        }
                    })
                }}>
                    <Tooltip title="Copy" placement="top">
                        <Icon>email</Icon>
                    </Tooltip>
                </IconButton>
            </Typography>



        </Grid>

    );
}

export default CashTranfer;