import { Box, Chip, Icon } from '@mui/material';
import React from 'react';
import FavouriteButton from '../FavouriteButton';
import useAuth from 'app/hooks/useAuth';
import { Property } from 'app/redux/models';

type CardPropertyProps = {
    image: string;
    content: React.ReactNode | JSX.Element;
    uuid: string;
    onClick?: () => void;
    chipType?: Property['economics']['investment_type'];
};

const chips = {
    CROWD_FUNDING: {
        label: 'En construcción',
        color: '#BF1A33',
        textColor: 'white',
    },
    OPEN_MARKET: {
        label: 'En venta',
        color: '#19BF52',
        textColor: 'white',
    },
    MANAGE: {
        label: 'Manage',
        color: '#f2f2f2',
        textColor: 'black',
    },
}


const CardProperty = ({ image, content, uuid, onClick, chipType }: CardPropertyProps) => {
    const previewImage = image;
    const { user } = useAuth();

    const handleOnClick = () => {
        if (onClick) onClick();
    }

    const IMG_RADIUS = '1rem';


    return (
        <Box component="article" sx={{ maxWidth: '23rem' }}>
            <Box
                sx={{
                    position: 'relative',
                    '&:hover': {
                        '& .img-hover': {
                            transform: 'scale(1.001)',
                            display: 'block',
                            opacity: 0.3,
                            transition: 'transform 1.3s ease',
                        },
                    },
                }}>
                {chipType && <Box
                    sx={{
                        position: 'absolute',
                        bottom: '5px',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.5rem',
                        zIndex: 3,
                        borderBottomLeftRadius: IMG_RADIUS,
                        borderBottomRightRadius: IMG_RADIUS,
                        backgroundColor: chips[chipType].color,
                        color: chips[chipType].textColor,
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column'
                    }}>{chips[chipType].label}</Box>}
                <Box
                    component="img"
                    src={previewImage}
                    alt="Property"
                    width="100%"
                    height="auto"
                    sx={{
                        aspectRatio: '10/9',
                        objectFit: 'cover',
                        borderRadius: IMG_RADIUS,
                        position: 'inherit',
                        zIndex: 2,
                        '&:hover': {
                            opacity: 0.9,
                            cursor: 'pointer',

                        }
                    }}
                    onClick={handleOnClick}
                />

                <Box
                    className="img-hover"
                    sx={{
                        height: '100%',
                        width: '100%',
                        backgroundImage: `url(${previewImage})`,
                        aspectRatio: '10/9',
                        borderRadius: '1rem',
                        backgroundSize: 'cover',
                        filter: 'blur(4px)',
                        position: 'absolute',
                        top: 0,
                        display: 'none',
                        zIndex: 1,
                        transition: 'transform 1.3s ease, opacity 1.3s ease',
                        opacity: 0,
                        '&:hover': {
                            transform: 'scale(1.001)',
                            display: 'block',
                            opacity: 0.9,
                        },
                    }}
                />

                {user && <Box aria-label="icons" sx={{ position: 'absolute', top: '12px', right: '10px', zIndex: 3 }}>
                    <FavouriteButton propertyUUID={uuid} />
                </Box>}
            </Box>

            <Box aria-label="container" sx={{
                px: '5px',
            }}>
                {content}
            </Box>
        </Box>
    );
};

export default CardProperty;
