import { FunctionComponent } from 'react';
import { OBContainer } from './OBStyles';
import { Typography } from '@mui/material';



const OBHeader: FunctionComponent = () => {
    return (
        <OBContainer>
            <Typography variant='title3' sx={{ pt: '8px' }}>
                Order book
            </Typography>

        </OBContainer>
    );
};

export default OBHeader;
