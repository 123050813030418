import { SxProps, TextField } from "@mui/material";
import { formatToUSDValue } from "app/utils/utils";
import { useFormikContext } from "formik";
import { memo, useEffect } from "react";

const arePropsEqual = (prevProps: any, nextProps: any) => {
    return (
        prevProps.formik.values[prevProps.name] === nextProps.formik.values[nextProps.name] &&
        prevProps.formik.touched[prevProps.name] === nextProps.formik.touched[nextProps.name] &&
        prevProps.formik.errors[prevProps.name] === nextProps.formik.errors[nextProps.name]
    );
};

const TextFieldFormik = memo(
    ({ name, label, type = "text", formik, sx, min = 0, max }: { name: any, label: string, formik: any, type?: 'number' | 'text' | 'usdt', sx?: SxProps<any>, min?: number, max?: number }) => {



        return (
            <TextField
                sx={sx}
                fullWidth
                label={label}
                name={name}
                variant="outlined"
                size="small"
                value={type !== 'usdt' ? formik.values[name] : formatToUSDValue(formik.values[name])}
                onChange={type !== 'usdt' ? formik.handleChange : undefined}
                onKeyDown={type === 'usdt' ? (e) => {
                    const isNumber = /^[0-9]$/i.test(e.key)
                    if (isNumber) {
                        const value = formik.values[name]
                        const newValue = value * 10 + Number(e.key)
                        formik.setFieldValue(name, newValue)
                    }
                    if (e.key === 'Backspace') {
                        const value = formik.values[name]
                        const newValue = value / 10 | 0

                        formik.setFieldValue(name, newValue)
                    }

                } : undefined}
                onBlur={formik.handleBlur}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name]}
                type={type}
                // inputProps={{
                //     min, max,
                //     pattern: type === 'number' ? "[0-9]*" : undefined,
                //     inputMode: type === 'number' ? "numeric" : undefined,
                // }}
                rows={name.includes('description') ? 10 : 1}
                multiline={name.includes('description')}
            />
        );
    },
    arePropsEqual  // Pasa la función de comparación como segundo argumento a memo
);

export default TextFieldFormik;
