import styled from "@emotion/styled";
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Skeleton } from "@mui/material";
import { memo, useMemo, useState } from "react";
import { Accept, useDropzone, } from "react-dropzone";
import { Document, Page } from 'react-pdf';

const ThumbsContainer = styled('aside')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
}));

const Thumb = styled('div')(({ images }: { images: boolean }) => ({
    display: 'inline-flex',
    marginBottom: 8,
    marginRight: 8,
    width: images ? 200 : 150,
    height: images ? 130 : 250,
    boxSizing: 'border-box',
    position: 'relative',
}));
const ThumbInner = styled('div')(() => ({
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    flexDirection: 'column',
    width: '100%',
}));

const ThumbImage = styled('img')(({ img, images }: { img: any, images: boolean }) => ({
    display: 'block',
    width: '100%',
    height: images ? '100%' : '80%',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    borderRadius: '10px',


}));



type DropZoneProps = {
    files: File[],
    onSetFiles: (files: File[]) => void,
    images?: boolean,
    documents?: boolean,
    label?: string,
    multiple?: boolean,
    nothumbs?: boolean,
    loading?: boolean

}

interface DropFile extends File {
    preview?: string,
}
const DocThumb = ({ file }: { file: DropFile }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        setNumPages(numPages);
    }

    return (
        <div aria-label='doc-thumb'>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
            <p>
                Page {pageNumber} of {numPages}
            </p>
        </div>
    );
}


const ThumbComponent = ({ file, images, onDelete }: { file: DropFile, images: boolean, onDelete: (file: any) => void }) => {

    return (
        <Thumb key={file.name} images={images} >
            <IconButton onClick={() => onDelete(file)}
                size="small"
                sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    backgroundColor: '#efefef',
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'white'
                    }
                }}
            >
                <ClearIcon />
            </IconButton>

            <ThumbInner>
                {file.type === 'application/pdf' ? <DocThumb file={file} />
                    : <>
                        <ThumbImage images={images}
                            img={file.preview}
                        />
                        <span style={{ textAlign: 'center' }}>{file.name}</span>
                    </>
                }

            </ThumbInner >
        </Thumb >
    )

}


const DropZone = ({ files, onSetFiles, images = false, documents = false, label = 'Drag drop some files here, or click to select files', multiple = true, nothumbs = false, loading = false }: DropZoneProps) => {








    const { getRootProps, getInputProps } = useDropzone({

        accept: {
            ...(images && { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] }),
            ...(documents && { 'application/pdf': ['.pdf'] })
        } as Accept | undefined,
        multiple: multiple,

        onDrop: acceptedFiles => {
            //@ts-ignore
            onSetFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    });

    const handleDeleteClick = (file: any) => {
        onSetFiles(files.filter((f: any) => f.name !== file.name))
    }



    return (

        <section className="container" style={{ width: '100%' }}>
            <Box component={'div'} sx={{
                '&:hover': {
                    cursor: 'pointer'
                }

            }}  {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p style={{
                    borderStyle: 'solid', padding: '1rem', borderWidth: '2px',
                    borderColor: 'lightgray', borderRadius: '3px'
                }}>
                    {label}
                </p>
            </Box>
            {
                loading ? <>
                    <Box sx={{ pt: 0.5 }} display='flex'>
                        <Skeleton variant="rectangular" width={250} height={100} />
                    </Box>

                </> :
                    !nothumbs &&
                    <ThumbsContainer>
                        {files.map((file: DropFile) => (
                            <ThumbComponent key={file.name} file={file} images={images} onDelete={handleDeleteClick} />
                        ))}
                    </ThumbsContainer>
            }
        </section>
    );
}

export default DropZone