import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { MouseEvent, ChangeEvent, ReactNode } from 'react';
import { visuallyHidden } from '@mui/utils';
import { RenvanceRowProps } from './RenvanceRow';

type Order = 'asc' | 'desc';

export interface RenvanceHeaders {
    padding?: 'normal' | 'checkbox' | 'none';
    id: string;
    label: string;
    align?: 'right' | 'left' | 'center';
    sortable?: boolean;
    sx?: any;
}


interface RenvanceHeaderProps {
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, item: any) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: readonly RenvanceHeaders[];
    actions?: RenvanceRowProps['actions'],
    customActions?: RenvanceRowProps['customActions'],
    upperTableRow?: ReactNode
}




export function RenvanceHeader(props: RenvanceHeaderProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, actions, customActions } = props;


    const createSortHandler =
        (item: any) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, item);
        };

    return (
        <TableHead>
            {props.upperTableRow}
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={headCell.sx}
                        key={headCell.id}
                        align={headCell.align ? headCell.align : 'left'}
                        padding={headCell.padding ? headCell.padding : 'none'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {
                            headCell.sortable != undefined && !headCell.sortable ? headCell.label : <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>

                        }

                    </TableCell>
                ))}
                {
                    (actions && actions.length > 0) || (customActions && customActions.length > 0) ? <TableCell align="right">
                        Actions
                    </TableCell> : <></>

                }
            </TableRow>
        </TableHead>
    );
}