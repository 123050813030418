import { LoadingButton } from "@mui/lab";
import { Grid, Icon, Paper, TextField, Typography } from "@mui/material";
import RangeCalendarField from "app/components/RangeCalendarField";
import AccountTable from "app/components/Tables/AccountTable";
import useAuth from "app/hooks/useAuth";
import { PropertyAccountMovementsResponse, propertiesApi } from "app/redux/api/propertiesApi";
import { useEffect, useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";


const PropertyMovementsPage = () => {
    const { user } = useAuth();
    const [filters, setFilters] = useState({
        property_code: '',
        start_date: '',
        end_date: '',
        token: 'COMPANY_USER'
    });
    const [movements, setMovements] = useState<PropertyAccountMovementsResponse[]>([]);
    const [fetchGetMovements, { data, isLoading, isUninitialized }] = propertiesApi.endpoints.getPropertyAccountMovements.useLazyQuery();
    const [searching, setSearching] = useState(false);

    const getMovements = () => {
        user.getAsyncToken().then((token) => {
            fetchGetMovements({ ...filters, token }, true).unwrap().then((movs) => {
                setMovements(movs);
            }).catch((err) => {
                setMovements([]);
            }).finally(() => {
                setSearching(false);
            });
        }).catch((err) => { console.log(err) });
    }

    useEffect(() => {
        if (user) {
            isUninitialized && getMovements();
            !isLoading && data && console.log(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);




    return (<PageWrapper >
        <Typography variant="title1">Movements</Typography>
        <Paper aria-label='buscador' sx={{ p: 3, mb: 5 }}>
            <Typography variant="title2" pb={1} pt={3} component={'p'}>Filter your movements</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Property code" variant="outlined" size="small"
                        onChange={(e) => setFilters({ ...filters, property_code: e.target.value as string })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RangeCalendarField
                        sx={{ width: '100%' }}
                        onRangeSelect={({ startDate, endDate }) => {
                            setFilters({ ...filters, start_date: startDate.toISOString(), end_date: endDate.toISOString() })
                        }}
                    />

                </Grid>
                <Grid item xs={12} md={2}>
                    <LoadingButton
                        onClick={() => { setSearching(true); getMovements() }}
                        loading={searching}
                        loadingPosition="start"
                        startIcon={<Icon>search</Icon>}
                        variant="contained"
                    >
                        <span>Search</span>
                    </LoadingButton>
                </Grid>
            </Grid>

        </Paper>

        <AccountTable movements={movements} isLoading={isLoading} />
    </PageWrapper>);
}

export default PropertyMovementsPage;