import { Button, Card, Typography } from "@mui/material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import FormContract from "./Forms/FormContract";
import FormDetails from "./Forms/FormDetails";
import FormResume from "./Forms/FormResume";
import FormUsers from "./Forms/FormUsers";
import { LoadingButton } from "@mui/lab";
import useAuth from "app/hooks/useAuth";
import { useRegisterCompanyMutation } from "app/redux/api/companiesApi";
import { useSnackbar } from "notistack";

const steps = [
    'Details',
    'Contract',
    'Users',
    'Review'
];



const CompanyAddPage = () => {
    const formDetailsRef = useRef();
    const formContractRef = useRef();
    const formUsersRef = useRef();
    const { user } = useAuth();
    const [registerCompany, { isLoading }] = useRegisterCompanyMutation();
    const { enqueueSnackbar } = useSnackbar();
    const forms = [formDetailsRef, formContractRef, formUsersRef];

    const formsNames = ['details', 'plans', 'users'];

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [answers, setAnswers] = useState<any>(
        {
            details: {},
            plans: {},
            users: {}
        }
    )

    const [activeStep, setActiveStep] = useState(0);
    const formsComponents = [
        FormDetails({ refDetails: formDetailsRef }),
        FormContract({ refContract: formContractRef }),
        FormUsers({ refUsers: formUsersRef }),
        FormResume({ answers: answers })]

    const handleSubmit = () => {
        console.log('submit', answers)
        user.getAsyncToken().then((token) => {
            const data = { ...answers, ...answers.details }
            console.log('sending data:', data)
            registerCompany({ data, token: token }).unwrap()
                .then((data) => {
                    setIsSubmitted(true)
                })
                .catch((error) => {
                    enqueueSnackbar("Error creating company", { variant: "error" })
                })
        })


    }


    return (
        <PageWrapper sx={{
            maxWidth: '70rem',
            margin: '0 auto'
        }}>
            <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
                Create company
            </Typography>
            <Typography variant="subtitle1" display='flex' justifyContent='center' flexDirection='column' margin='0px 3rem' >
                <p>
                    Use your skills to create a new company.
                    it is not easy but you can do it. The force is with you.
                </p>
                <span>Att. The Renvance Team</span>
            </Typography>
            {isSubmitted ?
                <Box sx={{ mt: 5, mx: 10 }}>
                    <Typography variant="h5" gutterBottom>
                        Company created!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your company has been created successfully. You can now start using the platform.
                    </Typography>
                </Box> : <></>
            }

            <Box sx={{ width: '100%', mt: 5 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {!isSubmitted ?
                <Card elevation={3} sx={{ p: '2rem' }}>
                    {formsComponents[activeStep]}
                    <Box aria-label='form-actions' sx={{ display: 'flex', justifyContent: 'end', mt: 3 }}>
                        <Button variant="outlined" sx={{ mr: 1 }}
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            disabled={activeStep === 0}

                        >
                            Back
                        </Button>
                        <LoadingButton variant="contained"
                            loading={isLoading}
                            onClick={() => {
                                if (activeStep === steps.length - 1) {
                                    handleSubmit()
                                    return;
                                }


                                //? All forms need to have a getSubmit method, otherwise it will throw an error
                                //@ts-ignore
                                const formData = forms[activeStep].current.getSubmit()
                                Promise.resolve(formData).then((data) => {
                                    console.log('data:', data)
                                    if (data) {
                                        console.log('Formulario válido:', data);
                                        setAnswers((prevAnswers: { [x: string]: any; }) => {
                                            prevAnswers[formsNames[activeStep]] = data;
                                            return { ...prevAnswers }
                                        })

                                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                                    } else {
                                        console.log('Formulario no válido');
                                    }
                                })



                            }}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </LoadingButton>
                    </Box>
                </Card> : <></>
            }

        </PageWrapper>
    );
}

export default CompanyAddPage;