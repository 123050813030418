import { Box } from "@mui/material";

const GridCardsContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    return (<Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
        gridAutoFlow: 'row dense',
        columnGap: '20px',
        rowGap: '30px',
        mt: '1rem',

    }}>
        {children}

    </Box>);
}

export default GridCardsContainer;