import { Box, Grid, TextField, styled, } from "@mui/material";
import RangeCalendarField from "app/components/RangeCalendarField";
//import { useGetPropertyAccountingQuery } from "app/redux/api/propertiesApi";
import { Formik } from "formik";
import { useState } from "react";
import AccountTable, { AccountRowsTable } from "./AccountTable";

// styled components
const StyledInput = styled(TextField)({
  width: "100% !important",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 14px !important",
    },
  },
});





const Account = ({ uuid }: { uuid: string }) => {

 // const { data: movements, error } = useGetPropertyAccountingQuery(uuid, { skip: !uuid });


  const date = new Date();
  const initialValues = {
    customerType: "",
    startDate: (new Date(date)).setMonth(date.getMonth() - 1),
    endDate: date,
  };
  const handleSubmit = async (values: any) => {
    console.log(values);
  };
  // const [expanded, setExpanded] = useState(false);

  const [tableRows] = useState<AccountRowsTable[]>([
    {
      date: "2021-10-10",
      concept: "Rent",
      amount: 1000,
      balance: 1000,
    }
  ]);


return (
  <>
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {({
        values,
        handleChange,
        handleSubmit,
      }) => (
        <form style={{ padding: "16px" }} onSubmit={handleSubmit}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={2} sm={4} xs={12}>
              Search by
            </Grid>

            <Grid item md={10} sm={8} xs={12}>
              <StyledInput
                size="small"
                name="keyword"
                label="Search by keyword or amount"
                variant="outlined"
                //@ts-ignore
                value={values.keyword}
                onChange={handleChange}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item md={2} sm={4} xs={12}>
              Time period
            </Grid>

            <Grid item md={10} sm={8} xs={12}>

              <RangeCalendarField onRangeSelect={(d) => console.log(d)} sx={{
                width: "100%",
                "& label": { fontSize: "14px" },

                "& .MuiOutlinedInput-input": {
                  fontSize: "14px",
                  padding: "10px 14px !important",
                },
                "& button": { padding: "6px" },
              }} />

            </Grid>
          </Grid>
        </form>
      )}
    </Formik>

    <Box sx={{ p: 3 }}>

      {<AccountTable rows={tableRows} />}

    </Box>
  </>
);
};

export default Account;
