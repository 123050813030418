import { createApi } from '@reduxjs/toolkit/query/react';
import { ITokenData, PaginationObject, Property, PropertyFiltersType } from 'app/redux/models';
import { PathApis } from 'config';
import dayjs from 'dayjs';
import { axiosBaseQuery, buildHeaders } from './baseApi';


export type PropertyAccountMovementsRequest = {
    property_code: string;
    start_date?: string;
    end_date?: string;
    token: string
}
export type PropertyAccountMovementsResponse = {
    propertyUUID: string;
    propertyCode: string;
    movementDate: string;
    description: string;
    filePathLink: string;
    amount: number;
    balance: number;
    propertyName: string;
}

export type PropertyStatementHistoryRequest = {
    propertyUUID: string
    startDate?: string
    endDate?: string
    keyword?: string
    token: string
}

export type PropertyStatementHistoryResponse = {
    propertyUUID: string
    propertyCode: string
    statementDate: string | Date
    description: string
    filePathLink: string
    propertyName: string
    id: number
}

export type ExpectedPriceCurveType = {
    [date: string]: number; // Un objeto donde las claves son fechas y los valores son números
};

export type CheckpointsListType = {
    [date: string]: number; // Un objeto donde las claves son fechas y los valores son números
};

export interface PricesChartInterface {
    start_value: number;
    expected_value: number;
    period_end: string;
    period_start: string;
    checkpoints: CheckpointsListType;
    prices: ExpectedPriceCurveType; // Utiliza el tipo definido anteriormente para los datos de precios
};

function isPricesChartInterface(obj: any): obj is PricesChartInterface {
    return (
        obj &&
        typeof obj.start_value === 'number' &&
        typeof obj.expected_value === 'number' &&
        typeof obj.period_end === 'string' &&
        typeof obj.period_start === 'string' &&
        isCheckpointsListType(obj.checkpoints) &&
        isExpectedPriceCurveType(obj.prices)
    );
}

function isCheckpointsListType(obj: any): obj is CheckpointsListType {
    if (obj === null) {
        return true;
    }
    if (!obj || typeof obj !== 'object') {
        return false;
    }

    for (const date in obj) {
        if (!obj.hasOwnProperty(date) || typeof obj[date] !== 'number') {
            return false;
        }
    }

    return true;
}

function isExpectedPriceCurveType(obj: any): obj is ExpectedPriceCurveType {
    if (!obj || typeof obj !== 'object') {
        return false;
    }

    for (const date in obj) {
        if (!obj.hasOwnProperty(date) || typeof obj[date] !== 'number') {
            return false;
        }
    }

    return true;
}


export const propertiesApi = createApi({
    reducerPath: 'propertiesApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    endpoints: ({ query, mutation }) => ({
        getAllProperties: query<PaginationObject<Property>, { filters: PropertyFiltersType | {}, token: string }>({
            query: ({ filters, token }) => ({
                url: '/properties/query',
                method: 'GET',
                params: filters,
                headers: buildHeaders(token)
            }),
        }),
        getPropertyDetails: query<PaginationObject<Property>, { uuid: string, token: string }>({
            query: ({ uuid, token }) => ({
                url: `/properties/details`,
                method: 'GET',
                params: { property_uuid: uuid },
                headers: buildHeaders(token)
            }),

        }),
        getPropertyPrices: query<any, { uuid: string, token: string }>({
            query: ({ uuid, token }) => ({
                url: `/properties/prices`,
                method: 'GET',
                params: { property_uuid: uuid },
                headers: buildHeaders(token)
            }),
            transformResponse: (response: any) => {
                if (!isPricesChartInterface(response)) {
                    console.log('Response does not match PricesChartInterface');
                    return { start_value: 0, expected_value: 0, period_end: "", period_start: "", checkpoints: {}, prices: {} };
                }
                return response
            },
            transformErrorResponse: (response: any) => {
                return { start_value: 0, expected_value: 0, period_end: "", period_start: "", checkpoints: {}, prices: {} };
            },

        }),
        getPropertyEconomics: query<any, string>({
            query: (uuid) => ({ url: `/economics/${uuid}`, method: 'GET' }),
        }),
        getPropertyAccountMovements: query<PropertyAccountMovementsResponse[], PropertyAccountMovementsRequest>({
            query: ({ property_code: property_uuid, start_date, end_date, token }) => {
                const params = {
                    'start': start_date || undefined,
                    'end': end_date || undefined,
                    'property_uuid': property_uuid
                };
                return {
                    url: `/properties/account`,
                    params: params,
                    method: 'GET',
                    headers: buildHeaders(token)
                };
            },
            transformResponse: (response: any) => {
                const propertyMovements: PropertyAccountMovementsResponse[] = response.map((movement: any) => {
                    return {
                        propertyUUID: movement.property_uuid,
                        propertyCode: movement.property_code,
                        movementDate: dayjs(movement.movement_date).toDate().toLocaleDateString(),
                        description: movement.description,
                        filePathLink: movement.file_path_link,
                        amount: movement.amount,
                        balance: movement.balance,
                        propertyName: movement.property_name
                    }
                })
                return propertyMovements
            }
        }),
        getPropertyStatementsMovements: query<PropertyStatementHistoryResponse[], PropertyStatementHistoryRequest>({
            query: ({ propertyUUID, startDate, endDate, token, keyword }) => {
                const params = {
                    'property_uuid': propertyUUID,
                    'start': startDate || undefined,
                    'end': endDate || undefined,
                    'keyword': keyword || undefined
                };
                return {
                    url: `/properties/statements`,
                    params: params,
                    method: 'GET',
                    headers: buildHeaders(token)
                };
            },
            transformResponse: (response: any) => {
                const propertyMovements: PropertyStatementHistoryResponse[] = response.map((movement: any) => {
                    return {
                        propertyUUID: movement.property_uuid,
                        propertyCode: movement.property_code,
                        statementDate: movement.statement_date,
                        description: movement.description,
                        filePathLink: movement.file_path_link,
                        propertyName: movement.property_name,
                        id: movement.id

                    }
                })
                return propertyMovements
            }
        }),
        getPropertyToken: query<ITokenData, string>({
            query: (uuid) => ({ url: `/token/property/${uuid}`, method: 'GET' }),
        }),
        uploadFile: mutation<any, any>({
            query: (files) => ({
                contentType: 'multipart/form-data',
                url: '/upload',
                method: 'POST',
                data: files,
            }),
        }),
        getAllPropertyCodes: query<string[], string>({
            query: (uuid) => ({ url: `/token/property/${uuid}`, method: 'GET' }),
        }),
        registerProperty: mutation<any, { data: any, token: string }>({
            query: ({ data, token }) => (
                {
                    contentType: 'multipart/form-data',
                    url: `/bo/properties/register`, method: 'POST', data: data, headers: buildHeaders(token)
                }),
        }),
        getAppreciation: mutation<any, { token: string }>({
            query: ({ token }) => ({ url: `/bo/properties/appreciate`, method: 'GET', headers: buildHeaders(token) }),
        }),
    }),

    keepUnusedDataFor: 3600,
});

export const {
    useGetAllPropertiesQuery,
    useGetPropertyDetailsQuery,
    useGetPropertyEconomicsQuery,
    useGetPropertyAccountMovementsQuery,
    useGetPropertyStatementsMovementsQuery,
    useGetPropertyTokenQuery,
    useUploadFileMutation,
    useGetAllPropertyCodesQuery,
    useRegisterPropertyMutation,
    useGetAppreciationMutation,
} = propertiesApi;

const GetAllPropertiesLazy = propertiesApi.endpoints.getAllProperties.useLazyQuery
export { GetAllPropertiesLazy };

