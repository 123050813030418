import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';


interface CustomProps {
    onChange: (event: { target: { name: string; value: number } }) => void;
    name: string;
}
const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.floatValue || 0,
                        },
                    });
                }}
                thousandSeparator
                prefix="$"
            />
        );
    },
);


type MoneyTextFieldProps = TextFieldProps & {
    onValueChange?: (value: number) => void
}

const MoneyTextField = (props: MoneyTextFieldProps) => {
    return (<TextField
        size='small'
        {...props}
        InputProps={{
            inputComponent: NumericFormatCustom as any,
        }}
        onChange={(e) => {
            props.onChange && props.onChange(e);
            props.onValueChange && props.onValueChange(parseFloat(e.target.value));
        }}

    />);
}

export default MoneyTextField;