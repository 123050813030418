import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const RangePicker = ({ onChange, state }: { onChange: (selection: any) => void, state: any }) => {
    const theme = useTheme();
    const themeColor = theme.palette.primary.main
    return (
        <DateRange
            rangeColors={[themeColor, '#3ecf8e', '#fed14c']}
            onChange={onChange}
            //@ts-ignore
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            
        />
    );
};

RangePicker.propTypes = {
    onChange: PropTypes.func
};

export default RangePicker;
