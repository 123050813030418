import IdentificationPage from "./IdentificationPage";
import SecurityPage from "./SecurityPage";
import InvestmentPage from "./InvestmentPage";
import { RouteObject } from "react-router-dom";
import AuthGuard from "app/auth/AuthGuard";
import { UserRole } from "app/redux/models";


const profilePagesRoutes: RouteObject[] = [
  {
    path: "profile", element:
      <AuthGuard allowRoles={[UserRole.INVESTOR]} redirect="identification">
        <InvestmentPage />
      </AuthGuard>
  },
  {
    path: "profile/investment", element:
      <AuthGuard allowRoles={[UserRole.INVESTOR]}>
        <InvestmentPage />
      </AuthGuard>

  },
  {
    path: "profile/security", element:
      <SecurityPage />
  },
  { path: "profile/identification", element: <IdentificationPage /> },
];


export default profilePagesRoutes;