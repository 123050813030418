import { Box, SxProps, Typography } from "@mui/material";

const TitleWrapper = ({ title, sx }: { title: string, sx?: SxProps<any> }) => {
    return (<Box aria-label='title-profile-wrapper' sx={{
        width: '100%',
        backgroundColor: 'white',
        padding: '1rem 3rem 1rem 3rem',
        fontSize: '2rem',
        ...sx
    }}>
        <Typography variant="title1">
            {title}
        </Typography>
    </Box>);
}

export default TitleWrapper;