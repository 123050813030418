import { Box, Button } from "@mui/material";
import ModalPlan from "app/components/Modals/ModalPlan";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import { useSnackbar } from "notistack";
import { RefObject, useImperativeHandle, useState } from "react";
import { string } from "yup";


const dummyValues: any[] =
    [
        {
            "platform_management_commission_percentage": 4,
            "property_management_commission_percentage": 4,
            "property_trading_commission_in_percentage": 4,
            "platform_trading_commission_in_percentage": 5,
            "property_buying_commission_in_token": false,
            "platform_buying_commission_in_token": true,
            "cf_return_interest": "4",
            "cf_return_type": "CF_OUT_RETURN_INTEREST",
            "plan_code": "77",
            "plan_name": "777",
            "property_buying_commission_percentage": 1,
            "platform_buying_commission_percentage": 1
        }
    ]




export const contractTableSchema: ITableSchema[] =
    [
        {
            id: 'plan_code',
            label: 'Plan Code',
            validation: string().required('Required'),
        },
        {
            id: 'plan_name',
            label: 'Plan Name',
            validation: string().required('Required'),
        },
        {
            id: 'cf_return_type',
            label: 'Cf Resolution',
            validation: string().required('Required'),
        },
        {
            id: 'platform_management_commission_percentage',
            label: 'Cx Platform (%)',
            validation: string().required('Required'),
        },
        {
            id: 'property_management_commission_percentage',
            label: 'Cx Company',
            validation: string().required('Required'),
        }
    ]




const FormContract = ({ refContract }: { refContract: RefObject<any> }) => {
    const [openModal, setOpenModal] = useState(false);

    const [editableData, setEditableData] = useState<any>(null);
    const [editableDataIndex, setEditableDataIndex] = useState<number>(0);

    const [modalMode, setModalMode] = useState<'edit' | 'add'>('add');

    const [rows, setRows] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const getSubmit = () => {
        if (rows.length === 0) {
            enqueueSnackbar('You must add at least one plan', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            });
            return false;
        }

        return rows;
    }

    useImperativeHandle(refContract, () => ({
        getSubmit,
    }));


    const handleEditRow = async (editedRow: any, index: number) => {
        setModalMode('edit');
        setEditableData(editedRow);
        setEditableDataIndex(index);
        setOpenModal(true);
    };

    const handleAddRow = async (row: any) => {
        setRows((prevRows) => [...prevRows, row]);
    }

    const handleDeleteRow = async (row: any, index: number) => {
        setRows((prevRows) => prevRows.filter((row, i) => i !== index));
    }



    return (
        <Box component='form'>
            <Button variant='contained' color='primary' size='small' onClick={() => {
                setModalMode('add');
                setOpenModal(true)
            }}>Add Plan</Button>
            <RenvanceTable
                schema={contractTableSchema}
                actions={['edit', 'delete']}
                editionMode="external"
                rows={rows}
                onEditRow={handleEditRow}
                onAddRow={handleAddRow}
                onDeleteRow={handleDeleteRow}
                pagination={false}
                loading={false}
            />

            <ModalPlan
                mode={modalMode}
                open={openModal}
                data={editableData}
                onClose={() => setOpenModal(false)}
                onSubmit={(plan) => {
                    if (modalMode === 'edit') {
                        setRows((prevRows) => prevRows.map((row, index) => {
                            if (index === editableDataIndex) {
                                return plan;
                            }
                            return row;
                        }));
                    }
                    else {
                        setRows((prevRows) => [...prevRows, plan]);
                    }
                    setOpenModal(false);
                }} />
        </Box>

    );
}

export default FormContract;