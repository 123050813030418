import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Icon, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { stableSort } from '../../../utils/utils';
import useAuth from 'app/hooks/useAuth';
import { useGetAppreciationMutation } from 'app/redux/api/propertiesApi';
import { useEffect } from 'react';

interface DataItems {
    date?: string;
    currentPropertyValue?: number;
    furnitureValue?: number;
    appreciatedPropertyValue?: number;
    furnitureAmortization?: number;
    OldTokenValue?: number;
    NewTokenValue?: number;

}
interface Data {
    propertyCode: string;
    tokenTicker: string;
    date: string;
    currentPropertyValue: number;
    furnitureValue: number;
    appreciatedPropertyValue: number;
    furnitureAmortization: number;
    OldTokenValue: number;
    NewTokenValue: number;
    items: DataItems[];
}



function createData(
    propertyCode: string,
    tokenTicker: string,
    date: string,
    currentPropertyValue: number,
    furnitureValue: number,
    appreciatedPropertyValue: number,
    furnitureAmortization: number,
    OldTokenValue: number,
    NewTokenValue: number,
    items?: DataItems[],

): Data {
    return {
        propertyCode,
        tokenTicker,
        date,
        currentPropertyValue,
        furnitureValue,
        appreciatedPropertyValue,
        furnitureAmortization,
        OldTokenValue,
        NewTokenValue,
        items: items ? items : [],
    };
}

const rowsDummy: Data[] = [
    createData('P-0001', 'T-0001', '01/01/2021', 100000, 10000, 110000, 1000, 1000, 1100),
    createData('P-0001', 'T-0001', '01/02/2021', 100000, 10000, 110000, 1000, 1000, 1100),
    createData('P-0001', 'T-0001', '01/03/2021', 100000, 10000, 110000, 1000, 1000, 1100, [
        { date: '01/03/2021', currentPropertyValue: 100000, furnitureValue: 10000, appreciatedPropertyValue: 110000, furnitureAmortization: 1000, OldTokenValue: 1000, NewTokenValue: 1100 },
    ])
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | any },
    b: { [key in Key]: number | string | any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    colSpan?: number;
    rowSpan?: number;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'propertyCode',
        numeric: false,
        disablePadding: true,
        label: 'Property Code',
        rowSpan: 2,
    },
    {
        id: 'tokenTicker',
        numeric: false,
        disablePadding: true,
        label: 'Token Ticker',
        rowSpan: 2,
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
        rowSpan: 2,
    },
    {
        id: 'currentPropertyValue',
        numeric: true,
        disablePadding: false,
        label: 'Current Property Value',
        rowSpan: 2,
    },
    {
        id: 'furnitureValue',
        numeric: true,
        disablePadding: false,
        label: 'Furniture Value',
        rowSpan: 2,
    },
    {
        id: 'appreciatedPropertyValue',
        numeric: true,
        disablePadding: false,
        label: 'Appreciated Property Value',
        rowSpan: 2,
    },
    {
        id: 'furnitureAmortization',
        numeric: true,
        disablePadding: false,
        label: 'Furniture Amortization',
        rowSpan: 2,
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ wordBreak: 'break-word' }}
                        colSpan={headCell.colSpan}
                        rowSpan={headCell.rowSpan}
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                    Token Value
                </TableCell>
                <TableCell align={'right'} rowSpan={2}>
                    Actions
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    align={'center'}
                    padding={'normal'}


                >
                    New
                </TableCell>
                <TableCell
                    align={'center'}
                    padding={'normal'}
                >
                    Old
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="title1"
                    id="tableTitle"
                    component="div"
                >
                    Appreciation
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (<></>)}
        </Toolbar>
    );
}

export default function AppreciationTable() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('propertyCode');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState<any[]>([]);
    const [getAppreciation] = useGetAppreciationMutation()
    const { user } = useAuth();
    
/* 
Campos que faltan/no se muy bien cual es la propiedad
{
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date',
    rowSpan: 2,
},
{
    id: 'appreciatedPropertyValue',
    numeric: true,
    disablePadding: false,
    label: 'Appreciated Property Value',
    rowSpan: 2,
},
{
    id: 'furnitureAmortization',
    numeric: true,
    disablePadding: false,
    label: 'Furniture Amortization',
    rowSpan: 2,
}
*/ 

useEffect(() => {
        user.getAsyncToken().then(token => {
            getAppreciation({ token })
                .unwrap()
                .then(( properties ) => 
                    setRows(properties.map(
                        (request: any) => ({
                            propertyCode: request.property_code,
                            tokenTicker: request.token.token_ticker,
                            currentPropertyValue: request.appreciation.current_asset_value,
                            furnitureValue: request.appreciation.current_furniture_value,
                            appreciatedPropertyValue: 7500,
                            date: request.appreciation.appreciation_date,
                            furnitureAmortization: 500,
                        })
                ))
                )
                .catch((err) => {
                    console.log(err);
                });
        });
    
    }, []);
    


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.propertyCode);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // Acá se renderizan las rows
    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );


    const MyRow = ({ row, index }: { row: Data, index: number }) => {
        const [open, setOpen] = React.useState(false);
        const [isEditable, setIsEditable] = React.useState(false);
        return (<>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{ cursor: 'pointer' }}
                key={'row' + index}
            >
                <TableCell>
                    {row.propertyCode}
                </TableCell>
                <TableCell align="left">{row.tokenTicker}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.currentPropertyValue}</TableCell>
                <TableCell align="left">{row.furnitureValue}</TableCell>
                <TableCell align="left">
                    {
                        isEditable ?
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={row.appreciatedPropertyValue}
                                onChange={(e) => {
                                    row.appreciatedPropertyValue = parseInt(e.target.value);
                                }} />
                            :
                            row.appreciatedPropertyValue
                    }
                </TableCell>
                <TableCell align="left">
                    {isEditable ?
                        <TextField
                            size="small"
                            variant="outlined"
                            defaultValue={row.furnitureAmortization}
                            onChange={(e) => {
                                row.furnitureAmortization = parseInt(e.target.value);
                            }} />
                        :
                        row.furnitureAmortization
                    }



                </TableCell>
                <TableCell align="center">{row.NewTokenValue}</TableCell>
                <TableCell align="center">{row.OldTokenValue}</TableCell>

                <TableCell align="right">
                    <IconButton
                        aria-label="edit row"
                        size="small"
                        onClick={() => setIsEditable(!isEditable)}
                    >
                        {isEditable ? <Icon>save_as</Icon> : <Icon>edit</Icon>}

                    </IconButton>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow key={'expandRow' + index}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Operation</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items && row.items.map((data, index) => (
                                        <TableRow key={'expandRowItem' + index}>
                                            <TableCell component="th" scope="row">
                                                {data.date}
                                            </TableCell>
                                            <TableCell align="right" >ghj</TableCell>
                                            <TableCell align="right">gh</TableCell>
                                            <TableCell align="right">klñ</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>);

    }



    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row: any, index: number) => {
                                return <MyRow row={row} index={index} key={'mapRows' + index} />
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}