import { Box } from "@mui/material";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import { UserRole } from "app/redux/models";
import { useSnackbar } from "notistack";
import { RefObject, useImperativeHandle, useState } from "react";
import { string, date } from "yup";

const dummyRows: any[] =


    [
        {
            "name": "23423423424",
            "lastname": "delete property",
            "email": "imanemail@gmail.com",
            "role": "AGENCY_ADMIN",
            "password": "2023-05-17",
            "phone_number": "64646",
            "date_of_birth": "1997-12-21"
        }
    ]




export const usersTableSchema: ITableSchema[] = [
    {
        id: 'name',
        label: 'Name',
        validation: string().required('Required'),
    },
    {
        id: 'lastname',
        label: 'Last Name',
        validation: string().required('Required'),
    },
    {
        id: 'email',
        label: 'Email',
        validation: string().required('Required').email('Invalid email'),
    },
    {
        id: 'role',
        label: 'Role',
        validation: string().required('Required'),
        type: 'select',
        selectOptions: [
            {
                label: 'Agency Admin',
                value: UserRole.AGENCY_ADMIN
            },
            {
                label: 'Agency User',
                value: UserRole.AGENCY_USER
            }
        ]
    },
    {
        id: 'password',
        label: 'Password',
        validation: string().required('Required').min(6, "Password must be at least 6 characters")
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
        validation: string().required('Required'),
    },
    {
        id: 'date_of_birth',
        label: 'Date Of Birth',
        type: 'date',
        sx: {
            width: '9rem'
        },
        validation: string().required('Required').test('is-older-than-18', 'Must be older than 18', (value) => {
            const today = new Date();
            const birthDate = new Date(value);
            const age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();

            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                return age - 1 >= 18;
            }

            return age >= 18;
        }),
        DateProps: {
            maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        }
    }
]



const FormUsers = ({ refUsers }: { refUsers: RefObject<any> }) => {
    const [rows, setRows] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    const getSubmit = () => {
        if (rows.length === 0) {
            enqueueSnackbar('You must add at least one User', {
                variant: 'error'
            });
            return false;
        }
        if (!rows.some(row => row.role === UserRole.AGENCY_ADMIN)) {
            enqueueSnackbar('You must add at least one Agency Admin', {
                variant: 'error'
            });
            return false;
        }

        return rows;
    }

    useImperativeHandle(refUsers, () => ({
        getSubmit,
    }));


    const handleEditRow = async (editedRow: any, index: number) => {
        setRows((prevRows) => {
            prevRows[index] = editedRow;
            return [...prevRows]
        });
    };

    const handleAddRow = async (row: any) => {
        setRows((prevRows) => [...prevRows, row]);
    }

    const handleDeleteRow = async (row: any, index: number) => {
        setRows((prevRows) => prevRows.filter((row, i) => i !== index));
    }



    return (
        <Box component='form'>

            <RenvanceTable
                schema={usersTableSchema}
                toolbar={
                    { addRow: true, addRowName: 'Add new User' }
                }
                actions={['edit', 'delete']}
                rows={rows}
                onEditRow={handleEditRow}
                onAddRow={handleAddRow}
                onDeleteRow={handleDeleteRow}
                pagination={false}
                loading={false}
            />

        </Box>

    );
}

export default FormUsers;

