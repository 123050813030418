import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import DropZone from 'app/components/DropZone';
import { DatePicker } from '@mui/lab';
import { string, object } from 'yup';


const StatementAddModal = ({ open, onClose }: { open: boolean, onClose: () => void, onSubmit: () => void }) => {
    const [files, setFiles] = useState<File[]>([])

    const handleClose = () => {
        onClose()

        const cleanUp = async () => {
            setTimeout(() => {
                setFiles([])
            }, 200)

        }
        cleanUp()
    };



    const AddStatementSchema = object().shape({
        description: string()
            .min(5, 'This provider name is too short')
            .max(50, 'This provider name is too long')
            .required('You must provide a description'),
    });




    return (
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Add Expense</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add the Expense description and upload the file
                </DialogContentText>
                <Formik
                    initialValues={{
                        description: '',
                    }}
                    validationSchema={AddStatementSchema}
                    onSubmit={(values: any) => { console.log(values) }}

                >
                    {({ errors, touched }) => (
                        <Form>
                            <Grid container >
                                <DatePicker />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="category"
                                    label="Category"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="description"
                                    label="Description"
                                    type="text"
                                    fullWidth
                                />
                                <Grid xs={4}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="Value"
                                        label="Value"
                                        type="number"
                                    />
                                </Grid>
                                <Grid xs={4}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="Tax"
                                        label="Tax"
                                        type="number"
                                    />
                                </Grid>
                                <Grid xs={4}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="Total"
                                        label="Total"
                                        type="number"
                                    />
                                </Grid>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="Suplier"
                                    label="Suplier"
                                    type="number"
                                    fullWidth
                                />
                                <Grid xs={12}>
                                    <DropZone files={files} onSetFiles={setFiles} />
                                </Grid>
                                <Grid xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Paid</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="Not yet" />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Button type="submit" color="primary">Add</Button>
                            </Grid>
                        </Form>
                    )}




                </Formik>

            </DialogContent>
        </Dialog >
    );
}

export default StatementAddModal;