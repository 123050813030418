import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { SettingsProvider } from "app/contexts/SettingsContext";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "app/components";
import routes from "./routes";
import 'moment/locale/es';
import dataStore from "./redux/dataStore";
import { MyAuthProvider } from "./contexts/MyAuthProvider";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReactElement } from 'react';


const App = () => {
  const content = useRoutes(routes) as ReactElement;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={dataStore}>
        <SettingsProvider>
          <MatxTheme>
            <MyAuthProvider>
              {content}
            </MyAuthProvider>
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </LocalizationProvider>
  );
};


export default App;
