import {
    KingBedOutlined as BedIcon,
    DriveEtaOutlined as CarIcon, SatelliteAltOutlined as SateliteIcon,
    ShoppingCartOutlined as ShoppingCartIcon,
    Token as TokenIcon
} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ConstructionIcon from '@mui/icons-material/Construction';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import PoolSharpIcon from '@mui/icons-material/PoolSharp';
import SportsGymnasticsOutlinedIcon from '@mui/icons-material/SportsGymnasticsOutlined';
import { Box, Divider, Grid, Icon, IconButton, LinearProgress, Skeleton, Typography, styled } from "@mui/material";
import { linearProgressClasses } from '@mui/material/LinearProgress';
import FavouriteButton from "app/components/FavouriteButton";
import { Property } from "app/redux/models";
import { formatToUSDValue } from "app/utils/utils";
import ImagesCarrousel from "./ImagesCarrousel";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    paddingTop: 1,
    paddingButtom: 1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));




const DataPack1 = ({ icon: IconComponent, title, value, type = 1, mb: mbStyle = '0.3rem' }: { icon?: any, title: string, value: string, type?: 1 | 2, mb?: any }) => {


    const typeDisplay = type === 1 ? 'row' : 'column';
    return <Box sx={{ display: 'flex', flexDirection: 'column', mb: mbStyle, mt: mbStyle, justifyContent: 'flex-start' }}>
        <div style={{ width: '100%', display: typeDisplay, textAlign: 'center' }}>
            {IconComponent && <Icon>{<IconComponent />}</Icon>}
            <Typography variant='body2' alignSelf={'center'}> {title} </Typography>
        </div>
        <Typography variant="subtitle2" fontSize={'1.2em'} textAlign={'center'}>{value}</Typography>
    </Box>


}

const LabelIconText = ({ icon: IconCompontent, text }: { icon: any, text: string }) => {

    if (text.toUpperCase() === 'GYM') {
        IconCompontent = SportsGymnasticsOutlinedIcon;
    }
    else if (text.toUpperCase() === 'POOL') {
        IconCompontent = PoolSharpIcon;
    }


    return <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
        <Icon>
            <IconCompontent />
        </Icon>
        <Typography sx={{ paddingLeft: '5px', placeSelf: 'center', textTransform: 'capitalize' }}> {text} </Typography>
    </Box>

}

const HomeInfo = ({ property, isLoading = false }: { property: Property, isLoading?: boolean }) => {

    return (<>
        <Grid container >
            <Grid item xs={12} width={'100%'} aria-label="home-details" display={'flex'} flexDirection={'row'} justifyContent={'space-between'} pb={2}>
                <Grid item aria-label="texts">
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="title1" component={'p'} >{isLoading ? <Skeleton /> : property.address} </Typography>
                        <Typography variant="subtitle1"> {isLoading && <Skeleton />} {!isLoading && property.country} {!isLoading && property.city} {!isLoading && property.zipcode}</Typography>
                        <Box aria-label='icons' display={'flex'}>
                            {isLoading ? <>
                                <Skeleton variant="rectangular" width={'29rem'} height={20} />

                            </> : <>
                                <LabelIconText icon={BedIcon} text={`${property.dorms} Dorms`} />
                                <LabelIconText icon={PoolSharpIcon} text={`${property.bathrooms} Baths`} />
                                <LabelIconText icon={CarIcon} text={`${property.parking_lots} Parks`} />
                                <LabelIconText icon={SateliteIcon} text={`Surface ${property.surface} sqmts`} />
                                {!isLoading && property?.amenities && property.amenities.map((amenity) => <LabelIconText icon={LensBlurIcon} text={amenity} key={'k' + amenity} />)}
                            </>}
                        </Box>
                    </Box>
                </Grid>
                <Grid alignContent={'flex-end'} item aria-label="iconButtons" >
                    {property && <FavouriteButton propertyUUID={property.property_uuid} />}
                    <IconButton>
                        <Icon>
                            <ShoppingCartIcon />
                        </Icon>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} aria-label="home-carrousel" display={'flex'} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                <Grid item xs={12} md={5} display={'flex'} justifyContent={'center'}>
                    {isLoading ? <Skeleton variant="rectangular" width={'100%'} height={300} /> : <ImagesCarrousel images={property.pictures} />}

                </Grid>
                <Grid item xs={12} md={7} pl={'1rem'}>
                    {
                        isLoading ? <>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />

                        </> :
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box aria-label="description">
                                    <Typography variant='title2' > {property.name}</Typography>
                                    <Typography padding={'0.5rem'} minHeight={'5rem'}>{property.description}</Typography>
                                </Box>
                                <Box aria-label="data2">
                                    <Grid container aria-label="data-pack-container">
                                        <Grid xs={6} md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={TokenIcon} title="Token" value={formatToUSDValue(property.token.token_price)} />
                                        </Grid>
                                        <Grid xs={6} md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={AttachMoneyIcon} title="Asset Value" value={formatToUSDValue(property.economics.current_market_asset_value)} />
                                        </Grid>
                                        <Grid xs={6} md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={CurrencyExchangeOutlinedIcon} title="Appreciation Period" value={property.economics.re_appreciation_period} />
                                        </Grid>
                                        <Grid xs={6} md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={AutoGraphIcon} aria-label="gross_rent_monthly_percentage" title="Estimated Rent" value={'9%'} mb={1} />
                                        </Grid>
                                        <Grid md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={CurrencyBitcoinIcon} title="Token Name" value={property.token.token_ticker} mb={1} />
                                        </Grid>
                                        <Grid md={4} item display='flex' justifyContent={'space-around'}>
                                            <DataPack1 icon={ConstructionIcon} title="Under Construction" value="" mb={1} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider sx={{ marginTop: 0, mb: 1 }} />
                                <Box aria-label="data2">
                                    <Typography variant='title3' sx={{ mb: 2 }} >Fund Raising</Typography>
                                    <Box display='flex' justifyContent={'space-between'} sx={{ mt: 1 }}>
                                        <Typography variant="subtitle2"> Amount collected Money: 2000$ </Typography>
                                    </Box>
                                    <BorderLinearProgress variant="determinate" value={80} />
                                    <Box display='flex' justifyContent={'space-between'}>
                                        <Typography variant="subtitle2"></Typography>
                                        <Typography variant="subtitle2">Token Remaining: {property.token.token_price * property.token.token_available} Tokens ReUSD</Typography>
                                    </Box>
                                </Box>
                            </Box>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>);
}

export default HomeInfo;