import { Outlet } from "react-router-dom";
import Header from "../../Layout/Header";


const MainLayout = () => {

    return (<>
        <Header />
        <main>
            <Outlet />
        </main>
    </>);
}

export default MainLayout;