import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, Collapse, Typography, Grid } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

export type StatementRowsTable = {
    date: string;
    description: string;
    file_id: number;
}
export type ExpenseRowProps = {
    date: Date;
    category: string;
    description: string;
    value: number;
    tax: number;
    total: number;
    supplier: string;
    paid: boolean;
}



const StatementRow = ({ date, description, file_id }: StatementRowsTable) => {
    const [open] = useState(false);
    const onDownload = (file_id: number) => {
        console.log(file_id, 'descargar..')
    }
    const onDelete = (file_id: number) => {
        console.log(file_id, 'eliminar..')
    }
    /*const onHelp = (file_id: number) => {
        console.log(file_id, 'ayuda..')
    }*/



    return <>

        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" align="left">{date.toString()}</TableCell>
            <TableCell align="left">
                <Grid container>
                    <Grid xs={12} aria-label='title'>
                        {description}
                    </Grid>
                    <Grid xs={12} aria-label='description'>
                        <Typography variant='caption' color='text.secondary'>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse sit assumenda dolor eos voluptate ducimus nostrum vero necessitatibus quae iusto dolorum repudiandae voluptatem, distinctio numquam? Nam iste beatae eum temporibus?
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="right" >
                <Box>
                    <IconButton onClick={() => onDownload(file_id)}>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(file_id)}>
                        <DeleteIcon />
                    </IconButton>


                </Box>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const StatementTable = ({ rows }: { rows: StatementRowsTable[] }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Date</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: StatementRowsTable, i: number) => (
                        <StatementRow {...row} key={i} />
                    ))}


                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default StatementTable;