import { Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import { array } from "prop-types";


const NoRows = ({ span }: { span: number }) => {
    return (
        <TableRow>
            <TableCell colSpan={span} align="center">
                <Typography variant="body2" color="text.secondary">
                    No data available
                </Typography>
            </TableCell>
        </TableRow>
    );

}



export default NoRows;