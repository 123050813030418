import { googleConfig } from "config";
import { useEffect } from "react";

const useCaptcha = () => {
    const captchaKey = googleConfig.captcha_site_key
    const loadCaptchaScript = () => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaKey}`;
        script.async = true;
        
        document.head.appendChild(script);
    };

    const executeCaptcha = async (action: string) => {
        return new Promise((resolve, reject) => {
            //@ts-ignore
            grecaptcha.enterprise.ready(() => {
                //@ts-ignore
                grecaptcha.enterprise.execute(captchaKey, { action })
                    .then((token: any) => {
                        resolve(token);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            });
        }) as PromiseLike<string>;
    };

    useEffect(() => {
        loadCaptchaScript();
    }, []);

    return { executeCaptcha };
};

export default useCaptcha;
