import { ColorBasedOnValue } from "app/components/ColorBasedOnValue";
import RenvanceTable, { ITableSchema } from "../RenvanceTable";





const schema: ITableSchema[]  = [
    {
        id: 'movementDate',
        label: 'Date',
        align: 'left',
    },
    {
        id: 'propertyCode',
        label: 'Property Code',
        align: 'left',
    },
    {
        id: 'propertyName',
        label: 'Property Name',
        align: 'left',
    },
    {
        id: 'description',
        label: 'Description',
        align: 'left',
        sortable: false
    },
    {
        id: 'amount',
        label: 'Amount',
        align: 'right',
        customComponent: ColorBasedOnValue
    },
    {
        id: 'balance',
        label: 'Balance',
        align: 'right',
        customComponent: ColorBasedOnValue
    },
]


const AccountTable = ({ movements, isLoading }: { movements: any, isLoading: boolean }) => {

    return (
        <RenvanceTable rows={movements} schema={schema} loading={isLoading} />
    );
}

export default AccountTable;