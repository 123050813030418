import { Property, UserRole } from "app/redux/models";
import { differenceInSeconds } from "date-fns";

export const convertHexToRGB = (hex: string): string | null | undefined => {
  return /^#?([A-F\d]{3}){1,2}$/i.test(hex)
    ? hex.match(/\w{2}/g)?.map(h => +`0x${h}`).join(', ')
    : null;
};

export function getTimeDifference(date1: number | Date, date2: number | Date) {
  let difference = Math.abs(differenceInSeconds(date1, date2));

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function getTimeDifferenceInSeconds(date1: number | Date, date2: number | Date) {
  return Math.abs(differenceInSeconds(date1, date2));
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substring(2, tempId.length - 1);
  return uid;
}

export function capitalizeString(word: string): string {
  return word.replace(/\b\w/g, (match: string) => match.toUpperCase());
}

export function formatToUSDValue(value: number) {
  // Format the price above to USD using the locale, style, and currency.
  const digits = (value < 100) ? 2 : 0;
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return USDollar.format(value);
}

export function formatToPercetageValue(value: number) {

  let percent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return percent.format(value);
}
//utils for table - material ui

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export const authErrorMessages: { [errorCode: string]: string } = {
  "auth/claims-too-large": "The claim payload delivered to setCustomUserClaims() exceeds the maximum size of 1,000 bytes.",
  "auth/email-already-exists": "Another user is already using the provided email. Each user must have a unique email.",
  "auth/id-token-expired": "The Firebase ID token provided has expired.",
  "auth/id-token-revoked": "The Firebase ID token has been revoked.",
  "auth/insufficient-permission": "The credential used to initialize the Admin SDK does not have sufficient permissions to access the requested Authentication resource. Refer to the Firebase project setup documentation for creating a credential with the appropriate permissions for authenticating the Admin SDK.",
  "auth/internal-error": "The Authentication server encountered an unexpected error when processing the request. For additional information, review the Authentication server response, which should be included in the error message. If the error persists, report it through our error reporting channel.",
  "auth/invalid-argument": "An invalid argument was provided for an authentication method. The error message should include additional information.",
  "auth/invalid-claims": "The custom claims attributes delivered to setCustomUserClaims() are not valid.",
  "auth/invalid-continue-uri": "The continue URL must be a valid URL string.",
  "auth/invalid-creation-time": "The creation time must be a valid UTC-format date string.",
  "auth/invalid-credential": "The credential used to authenticate the Admin SDK cannot be used for the desired action. Some authentication methods, such as createCustomToken() and verifyIdToken(), require initializing the SDK with a certificate credential instead of an app's default credential. Refer to Initialize the SDK for documentation on authenticating the Admin SDK with a certificate credential.",
  "auth/invalid-disabled-field": "The value provided for the user's disabled property is not valid. It must be a boolean.",
  "auth/invalid-display-name": "The value provided for the user's displayName property is not valid. It must be a non-empty string.",
  "auth/invalid-dynamic-link-domain": "The provided dynamic link domain has not been configured or authorized for the current project.",
  "auth/invalid-email": "The value provided for the user's email property is not valid. It must be a valid email address string.",
  "auth/invalid-email-verified": "The value provided for the user's emailVerified property is not valid. It must be a boolean.",
  "auth/invalid-hash-algorithm": "The hash algorithm must match one of the supported algorithm strings.",
  "auth/invalid-hash-block-size": "The hash block size must be a valid number.",
  "auth/invalid-hash-derived-key-length": "The derived key length must be a valid number.",
  "auth/invalid-hash-key": "The hash key must be a valid byte buffer.",
  "auth/invalid-hash-memory-cost": "The hash memory cost must be a valid number.",
  "auth/invalid-hash-parallelization": "The hash parallelization must be a valid number.",
  "auth/invalid-hash-rounds": "The hash rounds must be a valid number.",
  "auth/invalid-hash-salt-separator": "The salt separator field of the hash algorithm must be a valid byte buffer.",
  "auth/invalid-id-token": "The provided ID token is not a valid Firebase ID token.",
  "auth/invalid-last-sign-in-time": "The last sign-in time must be a valid UTC-format date string.",
  "auth/invalid-page-token": "The next page token provided in listUsers() is not valid. It must be a valid non-empty string.",
  "auth/invalid-password": "The value provided for the user's password property is not valid. It must be a string with at least six characters.",
  "auth/invalid-password-hash": "Password hashes must be a valid byte buffer.",
  "auth/invalid-password-salt": "Salted password must be a valid byte buffer.",
  "auth/invalid-phone-number": "The value provided for phoneNumber is not valid. It must be a non-empty identifier string that conforms to the E.164 standard.",
  "auth/invalid-photo-url": "The value provided for the user's photoURL property is not valid. It must be a valid URL string.",
  "auth/invalid-provider-data": "providerData must be an array of UserInfo objects.",
  "auth/invalid-provider-id": "providerId must be a valid supported provider identifier string.",
  "auth/invalid-oauth-responsetype": "Only one OAuth responseType should be set as true.",
  "auth/invalid-session-cookie-duration": "The session cookie duration must be a valid number in milliseconds, ranging from 5 minutes to 2 weeks.",
  "auth/invalid-uid": "The provided uid must be a non-empty string with a maximum of 128 characters.",
  "auth/maximum-user-count-exceeded": "The maximum allowed user import count has been exceeded.",
  "auth/missing-android-pkg-name": "If Android app install is required, an Android package name must be provided.",
  "auth/missing-continue-uri": "A valid continue URL must be provided in the request.",
  "auth/missing-hash-algorithm": "To import users with password hashes, the hash algorithm and its parameters must be provided.",
  "auth/missing-ios-bundle-id": "A bundle ID is missing in the request.",
  "auth/missing-uid": "A uid identifier is required for the current operation.",
  "auth/missing-oauth-client-secret": "The OAuth client secret from the OAuth configuration is required to enable OIDC code flow.",
  "auth/operation-not-allowed": "The provided access provider is disabled for your Firebase project. Enable it in the Firebase console's Sign-in Method section.",
  "auth/phone-number-already-exists": "Another user is already using the provided phone number. Each user must have a unique phone number.",
  "auth/project-not-found": "No Firebase project was found for the credential used to initialize the Admin SDK. Refer to the Firebase project setup documentation for generating your project's credential and using it to authenticate the Admin SDK.",
  "auth/reserved-claims": "One or more custom user claims delivered to setCustomUserClaims() are reserved. For example, specific OIDC claims (e.g., sub, iat, iss, exp, aud, or auth_time) should not be used as keys for custom claims.",
  "auth/session-cookie-expired": "The provided Firebase session cookie has expired.",
  "auth/session-cookie-revoked": "Firebase session cookies have been revoked.",
  "auth/too-many-requests": "The number of requests exceeds the allowed maximum.",
  "auth/uid-already-exists": "Another user is already using the provided uid. Each user must have a unique uid.",
  "auth/unauthorized-continue-uri": "The continuation URL domain is not whitelisted. Include it in the whitelist in the Firebase console.",
  "auth/user-not-found": "No user record corresponds to the provided identifier.",
  "auth/wrong-password": "The password is invalid for the given email.",
};

export function isAgency(role: UserRole): boolean {
  return role === UserRole.AGENCY_ADMIN || role === UserRole.AGENCY_USER;
}

export function isRenvance(role: UserRole): boolean {
  return role === UserRole.RENVANCE_ADMIN || role === UserRole.RENVANCE_USER;
}

export function isInvestor(role: UserRole): boolean {
  return role === UserRole.INVESTOR;
}

export function isDeveloper(role: UserRole): boolean {
  return role === UserRole.DEVELOPER_USER || role === UserRole.DEVELOPER_ADMIN;
}
export function isGuest(role: UserRole): boolean {
  return role === UserRole.GUEST;
}

export function calculateDivisors(totalInvestment: number): number[] {
  const investment: number = Math.ceil(totalInvestment / 100) * 100;
  const divisors: number[] = [];

  for (let d = 1; d <= investment; d++) {
    if (investment % d === 0 && d >= 50 && d <= investment / 50) {
      divisors.push(d);
    }
  }
  return divisors;
}

/**
  * Returns a formatted date string in the format YYYY-MM-DD
  * @param isoDate
  */

export const formatServerDate = (isoDate: string) => {
  let date = new Date(isoDate);

  let day = String(date.getUTCDate()).padStart(2, '0');
  let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript comienzan desde 0
  let year = date.getUTCFullYear();

  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
export const parseFormattedDate = (formattedDate: string) => {
  const [year, month, day] = formattedDate.split('-').map(Number);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return undefined;
  }

  // Los meses en JavaScript comienzan desde 0, por lo que restamos 1 al mes
  return new Date(Date.UTC(year, month - 1, day + 1));
}


export const validateJSONProperty = (property: any) => {
  if (typeof property !== 'object') {
    return false;
  }
}

 