import { Box, Grid, SxProps, Typography } from "@mui/material";
import { landing_photos } from "assets/images";
import PageContainer from "../../components/PageContainer";
const BusinessCard2 = ({ img, title, description, sxImg }: { img: string, title: string, description: string, sxImg?: SxProps<any> }) => {
    return (
        <Grid item xs={12} md={4} justifyContent='space-between'
            sx={{
                backgroundColor: '#FFF',
                borderRadius: '1rem',
                borderWidth: '1px',
                border: 'thin',
                overflowX: 'hidden',
                overflowY: 'hidden'


            }}>
            <Box display='flex' flexDirection='column'
                sx={{
                    margin: '0 auto',
                    height: "300px",
                    width: "310px",
                    position: "relative",
                    marginBottom: '2rem',
                }}
            >
                <Typography aria-label="title"
                    textAlign='center'
                    component='p'
                    variant='title3'
                >{title}
                </Typography>
                <div aria-label="description" style={{
                    position: "absolute",
                    top: "50%",
                    left: "40%",
                    width: "14rem",
                    zIndex: 2,
                    borderRadius: "10px",
                    backgroundColor: "white",
                    padding: "10px",

                }}>
                    <Typography variant='subtitle1' sx={{ lineHeight: 1.2, fontWeight: 400, color: "#2d6ea5", fontSize: "0.9rem" }}>{description}</Typography>
                </div>
                <Box
                    component="img"
                    src={img}
                    alt={title}
                    sx={{
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "1rem",
                        position: "relative",
                        ...sxImg
                    }}
                />
            </Box>
        </Grid>
    );
};

const SectionHowToInvest = () => {


    return (
        <section id='how-to-invest' style={{ paddingTop: '2rem' }}>
            <PageContainer>
                <Box aria-label="business-letters" style={{ display: 'flex'}}>
                    <Box aria-label="titulos">
                        <Typography variant="title1" zIndex={3} position='relative' component='h3'
                            sx={{ textAlign: { xs: 'center', sm: 'center'}}} >
                            ¿Cómo invertír?
                        </Typography>
                        <Typography variant="subtitle3" m='1rem' zIndex={3} position='relative' sx={{ backgroundColor: 'white', borderRadius: '5px', fontWeight: 400, lineHeight:1.5  }}>
                            {/* <strong style={{display: 'block', paddingBottom:'0.5rem'}}>Convertite en un inversor inmobiliario en unos pocos clicks.</strong>  */}
                            {`Selecciona de nuestro catalogo la propiedad que más te interese. Ofrecemos propiedades en construcción o terminadas, para todos los perfiles de inversores.
                            Luego, si eliges invertir en propiedades terminadas, puedes generar una oferta (orden de compra) sobre las cuotapartes de la propiedad. 
                            En cambio si prefieres invertir en propiedades en construcción debes comprar tu cuotaparte directamente.\n
                            Para poder comprar los tokens debes registrarte y depositar dinero en tu cuenta. Al generar una oferta por uno o varios tokens, ese dinero queda inmovilizado, 
                            esperando que coincida con lo que busca un vendedor. Cuando un vendedor acepta la oferta, hay match y felicitaciones eres uno de los inversores de la propiedad.\n 
                            Ahora solo tienes que relajarte y disfrutar de los retornos que genera!!!`}
                        </Typography>
                        <Typography variant="subtitle3" m='1rem' zIndex={3} position='relative' sx={{ backgroundColor: 'white', borderRadius: '5px', fontWeight: 400, lineHeight:1.5  }}>
                            
                        </Typography>
                    </Box>
                </Box>

            <div aria-label="business-card">
                <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
                    <BusinessCard2 sxImg={{ top: '-2rem', left: '-2rem' }} img={landing_photos.choosing} title="Busca la propiedad de tu interés" description="En cada propiedad puedes ver su descripción así como un análisis de la inversíon y decidir cual es mejor para tí." />
                    <BusinessCard2 sxImg={{ top: '-3rem', left: '-4rem' }} img={landing_photos.addToPortfolio} title="Agrega a tus inversiones" description="Cada propiedad se divide en partes, comprando una, la estas agregando a tu portfolio y diversificando tu inversiones." />
                    <BusinessCard2 sxImg={{ top: '-4rem', left: '-3rem' }} img={landing_photos.seeReturns} title="Disfruta de los rendimientos" description="Listo ya tienes tu portfolio solo debes relajarte y disfrutar de los rendimientos hasta que decidas vender tus activos." />
                </Grid>
            </div>
        </PageContainer>
        </section >
    );
}


export default SectionHowToInvest;