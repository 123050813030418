import SectionCarrousels from "./sections/SectionCarrousels";
import SectionHowToInvest from "./sections/SectionHowToInvest";
import SectionOurBusiness from "./sections/SectionOurBusiness";
import SectionPresentation from "./sections/SectionPresentation";
import ContactSection from "./sections/TemplateSectionContact";

const HomePage = () => {
  return (
    <>
      <SectionPresentation />
      <SectionOurBusiness />
      <SectionHowToInvest />
      <SectionCarrousels />
      <ContactSection />

    </>);
}

export default HomePage;