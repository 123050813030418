import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { stableSort } from '../../../../utils/utils';


interface Data {
    bookingCode: string;
    propertyCode: string;
    checkInCheckOut: string;
    priceNight: number;
    extras: number;
    tax: number;
    total: number;
}



function createData(
    bookingCode: string,
    propertyCode: string,
    checkInCheckOut: string,
    priceNight: number,
    extras: number,
    tax: number,
    total: number
): Data {
    return {
        bookingCode,
        propertyCode,
        checkInCheckOut,
        priceNight,
        extras,
        tax,
        total
    };
}

const rows: Data[] = [
    createData('BKG001', 'APT101', '10/07/23 - 10/08/23', 100, 0, 0, 100),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | any },
    b: { [key in Key]: number | string | any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    colSpan?: number;
    rowSpan?: number;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'bookingCode',
        numeric: false,
        disablePadding: true,
        label: 'Booking code',
    },
    {
        id: 'propertyCode',
        numeric: false,
        disablePadding: false,
        label: 'Property code',
    },
    {
        id: 'checkInCheckOut',
        numeric: false,
        disablePadding: false,
        label: 'Check in - Check out',
    },
    {
        id: 'priceNight',
        numeric: true,
        disablePadding: false,
        label: 'Price night',
    },
    {
        id: 'extras',
        numeric: true,
        disablePadding: false,
        label: 'Extras',
    },
    {
        id: 'tax',
        numeric: true,
        disablePadding: false,
        label: 'Tax',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    
    }


];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ wordBreak: 'break-word' }}
                        colSpan={headCell.colSpan}
                        rowSpan={headCell.rowSpan}
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

            </TableRow>

        </TableHead>
    );
}




export default function BookingHistoryTable() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('propertyCode');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.propertyCode);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );


    const MyRow = ({ row, index }: { row: Data, index: number }) => {
        return (<>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{ cursor: 'pointer' }}
                key={'row' + index}
            >
                <TableCell align="left">{row.bookingCode}</TableCell>
                <TableCell align="left">{row.propertyCode}</TableCell>
                <TableCell align="left">{row.checkInCheckOut}</TableCell>
                <TableCell align="left">{row.priceNight}</TableCell>
                <TableCell align="left">{row.extras}</TableCell>
                <TableCell align="left">{row.tax}</TableCell>
                <TableCell align="left">{row.total}</TableCell>
            </TableRow>
        </>);

    }



    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                return <MyRow row={row} index={index} key={'mapRows' + index} />
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}