import PageWrapper from "../../Layout/PageWrapper";

const SeeCompanyPage = () => {
    return ( <PageWrapper>
        <>
            im seeing a company, you dont see me?
        </>
    
    </PageWrapper> );
}
 
export default SeeCompanyPage;