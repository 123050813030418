import { Typography } from "@mui/material";
import PageWrapper from "../../Layout/PageWrapper";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import { Box } from "@mui/system";
import { useGetAllUsersQuery, usersApi } from "app/redux/api/usersApi";
import useAuth from "app/hooks/useAuth";
import { useEffect, useState } from "react";



const usersTableSchema: ITableSchema[] = [
    {
        id: 'uid',
        label: 'UID',
    },
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'lastname',
        label: 'Lastname',
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'role',
        label: 'Role',
    },
    {
        id: 'company',
        label: 'Company',
    }
]



const ListAppUsers = () => {

    const { user } = useAuth();
    const [getAllUsers, { data, error, isLoading }] = usersApi.endpoints.getAllUsers.useLazyQuery();
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        user.getAsyncToken().then(token => {
            getAllUsers({ token }, true)
                .unwrap()
                .then(({ page: users }) => setUsers(users.map(
                    (user: any) => ({
                        uid: user.uid,
                        name: user.name,
                        lastname: user.lastname,
                        phoneNumber: user.phone_number,
                        role: user.role,
                        email: user.email,
                        company: user?.company?.name
                    })

                )))
                .catch((err) => {
                    console.log(err);
                });
        });

    }, []);

    return (<PageWrapper>
        <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
            App Users
        </Typography>

        <Box mt={4}>
            <RenvanceTable
                schema={usersTableSchema}
                rows={users}
                loading={isLoading}

            />
        </Box>

    </PageWrapper>);
}

export default ListAppUsers;