import React, { FunctionComponent } from 'react';
import { OBContainer, OBRowItem } from './OBStyles';


interface PriceLevelRowProps {
  total: string;
  size: string;
  price: string;
  reversedFieldsOrder: boolean;
  color?: string;
}

const OBPriceLevelRow: FunctionComponent<PriceLevelRowProps> = ({
  total,
  size,
  price,
  reversedFieldsOrder = false,
  color
}) => {
  return (
    <OBContainer data-testid='price-level-row' >
      {reversedFieldsOrder ?
        <>
          <OBRowItem className='price' sx={{ color: color }} >{price}</OBRowItem>
          <OBRowItem>{size}</OBRowItem>
          <OBRowItem>{total}</OBRowItem>
        </> :
        <>
          <OBRowItem sx={{ color: color }} >{'$'+ total}</OBRowItem>
          <OBRowItem>{size}</OBRowItem>
          <OBRowItem className='price' >{price}</OBRowItem>
        </>}
    </OBContainer>
  );
};

export default OBPriceLevelRow;
