import { BaseQueryFn } from "@reduxjs/toolkit/query"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

/**
 * 
 * @param auth_token  The token to be used for auth the current request
 * @param captcha_token  The token to be used for captcha validation
 * @returns 
 */

export const buildHeaders = (auth_token: string, captcha_token?: string) => (
    {
        'Authorization': `Bearer ${auth_token}`,
        ...(captcha_token ? { 'g-recaptcha-response': captcha_token } : {})
    })
export const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }):
    BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            headers?: AxiosRequestConfig['headers']
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axios({ url: baseUrl + url, method, data, params, headers })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError as AxiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }