import { IUser } from "app/redux/models";
import { createContext } from "react";

interface IInitialAuthState {
  user: IUser;
  isInitialised: boolean;
  isAuthenticated: boolean;
}

const initialAuthState: IInitialAuthState = {
  user: undefined as any,
  isInitialised: false,
  isAuthenticated: false,
};

export const AuthContext = createContext({
  ...initialAuthState,
  method: "FIREBASE",
  createUserWithEmail: (email: string, password: string, name: string, lastname: string) => Promise.resolve(),
  signInWithEmail: (email: string, password: string) => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});


export default AuthContext;


