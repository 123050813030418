import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { FlexBox } from "app/components/FlexBox";
import useAuth from "app/hooks/useAuth";
import { authErrorMessages } from "app/utils/utils";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { RefObject, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { object, string } from 'yup';
import { GoogleButton } from "./AuthModal";
import { Box } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";
import { appConfig } from "config";



// inital login credentials
const initialValues = {
    email: "",
    password: "",
    remember: true,
};

// form field validation schema
const validationSchema = object().shape({
    password: string()
        .min(6, "Password must be 6 character length")
        .required("Password is required!"),
    email: string().email("Invalid Email address").required("Email is required!"),
});

const SignInForm = ({ onSubmit }: { onSubmit: () => void }) => {
    const { signInWithEmail, signInWithGoogle, isAuthenticated } = useAuth();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleFormSubmit = async (values: any) => {

        setLoading(true);
        try {
          await signInWithEmail(values.email, values.password)
        } catch (error: any) {
            const message = authErrorMessages[error.code] || error.message
            enqueueSnackbar(message, {
                variant: "error", autoHideDuration: 1500,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            });
            setLoading(false);
        }
    };

    const DummyLogin = async (credentials: { email: string, password: string }) => {
        await handleFormSubmit(credentials)
    }

    useEffect(() => {
        if (!isAuthenticated) return
        setLoading(false)

        enqueueSnackbar("Logged In Successfully", {
            variant: "success", autoHideDuration: 1300,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }

        });

        return onSubmit()
    }, [isAuthenticated])


    const handleGoogleLogin = async () => {
        setLoading(true);
        try {
            await signInWithGoogle();
        } catch (e) {
            setLoading(false);
        }
    };


    return (
        <div className="form-container sign-in-container">
            <form>
                <h1>Iniciar sesión</h1>
                <div className="social-container">
                    <GoogleButton
                        fullWidth
                        variant="contained"
                        onClick={handleGoogleLogin}
                        startIcon={<img src="/assets/images/logos/google.svg" alt="google" />}
                    >
                        Seguir con Google
                    </GoogleButton>
                </div>
                <Typography my='1rem'>or use your account</Typography>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} style={{
                            justifyContent: 'flex-start'
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                type="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                                sx={{ mb: 3 }}
                            />

                            <TextField
                                fullWidth
                                size="small"
                                name="password"
                                type="password"
                                label="Password"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(errors.password && touched.password)}
                                sx={{ mb: 1.5 }}
                            />

                            <FlexBox justifyContent="space-between">


                                <NavLink
                                    to="/session/forgot-password"
                                >
                                    Forgot password?
                                </NavLink>
                            </FlexBox>

                            <LoadingButton
                                type="submit"
                                color="primary"
                                loading={loading}
                                variant="contained"
                                sx={{ my: 2 }}
                            > Iniciar sesión </LoadingButton>

                        </form>
                    )}
                </Formik>
                {appConfig.env === 'UAT' && <Box>
                    <Button
                        onClick={() => DummyLogin({ email: "investor1@gmail.com", password: "secret" })}
                        color="primary"
                        variant="outlined"
                        sx={{ my: '2px', mr: '5px' }}
                    > Login as Investor </Button>
                    <Button
                        onClick={() => DummyLogin({ email: "inmobiliaria@renvance.com", password: "secret" })}
                        color="primary"
                        variant="outlined"
                        sx={{ my: '2px' }}
                    > Login as Company</Button>
                    <Button
                        onClick={() => DummyLogin({ email: "mildo@renvance.com", password: "secret" })}
                        color="primary"
                        variant="outlined"
                        sx={{ my: '2px' }}
                    > Login as Renvance</Button>
                </Box>}


            </form>
        </div>
    );
}

export default SignInForm;
