import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { PathApis } from 'config';


const buildHeaders = (token: string) => ({ 'Authorization': `Bearer ${token}` })


const axiosBaseQuery = ({ baseUrl, token }: { baseUrl: string, token?: string } = { baseUrl: '' }):
    BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            headers?: AxiosRequestConfig['headers']
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axios({ url: baseUrl + url, method, data, params, headers })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError as AxiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }




export const utilitiesApi = createApi({
    reducerPath: 'utilitiesApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    tagTypes: ['Users'],
    endpoints: ({ query, mutation }) => ({
        uploadFile: mutation<any, { token: string, data: any }>({
            query: ({ token, data }) => ({
                url: '/bo/utilities/upload-file',
                method: 'POST',
                data,
                headers: buildHeaders(token)
            })
        }),
    })
});

export const {
    useUploadFileMutation
} = utilitiesApi;
