import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const FirebaseLogin = Loadable(lazy(() => import("./login/FirebaseLogin")));
const FirebaseRegister = Loadable(lazy(() => import("./register/FirebaseRegister")));
const Landing = Loadable(lazy(() => import("./landing/Landing")));

const sessionRoutes: RouteObject[] = [
  { path: "/session/signup", element: <FirebaseRegister /> },
  { path: "/session/signin", element: <FirebaseLogin /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/session/404", element: <NotFound /> },
  { path: "/landing", element: <Landing /> },
];

export default sessionRoutes;
