import { createApi } from '@reduxjs/toolkit/query/react';
import { IUser, PaginationObject } from 'app/redux/models';
import { PathApis } from 'config';
import { axiosBaseQuery, buildHeaders } from './baseApi';


enum PaymentMethod {
    CASH = "CASH",
    CARD = "CARD",
    TRANSFER = "TRANSFER",
    CRYPTO = "CRYPTO"
}

type RegisterDepositType = {
    deposit_date: Date,
    deposit_amount: number,
    payment_method: PaymentMethod,
    user_email?: string,
    token_ticker?: string,
    wallet_address?: string
}

export const usersApi = createApi({
    reducerPath: 'UsersApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    tagTypes: ['Users'],
    endpoints: ({ query, mutation }) => ({
        getAllUsers: query<PaginationObject<IUser>, { token: string, params?: any }>({
            query: ({ token, params }) => ({
                url: '/bo/users',
                method: 'GET',
                params: params,
                headers: buildHeaders(token)
            }),
            providesTags: ['Users'],
        }),
        registerUser: mutation<any, { token: string, data: any }>({
            query: ({ token, data }) => ({
                url: '/bo/users/register',
                method: 'POST',
                data,
                headers: buildHeaders(token)
            }),
            invalidatesTags: ['Users'],
        }),
        registerDeposit: mutation<any, { token: string, data: RegisterDepositType }>({
            query: ({ token, data }) => ({
                url: '/users/user_purchase',
                method: 'POST',
                data,
                headers: buildHeaders(token)
            })
        }),
        approveUserPurchase: mutation<any, { token: string, data: any }>({
            query: ({ token, data }) => ({
                url: '/bo/users/approve_user_purchase',
                method: 'POST',
                data,
                body: data,
                headers: buildHeaders(token)
            })
        }),
        verifyUserIdentification: mutation<any, { token: string, data: any, captcha: string }>({
            query: ({ token, data, captcha }) => ({
                url: '/bo/users/submit_user_identification_request',
                method: 'POST',
                data,
                body: data,
                headers: buildHeaders(token, captcha)
            })
        }),
        getPendingPurchases: query<any[], { token: string }>({
            query: ({ token }) => ({
                url: '/bo/users/pending_purchases',
                method: 'GET',
                headers: buildHeaders(token)
            }),
            transformResponse: (response: any) => {
                return response.purchases
            }
        }),
        getIdentificationRequests: query<any[], { token: string }>({
            query: ({ token }) => ({
                url: '/bo/users/identification_requests',
                method: 'GET',
                headers: buildHeaders(token)
            }),
            transformResponse: (response: any) => {
                return response.requests
            }
        }),
        getWallet: query<any[], { token: string, uid?: string, wallet_address?: string }>({
            query: ({ token, uid, wallet_address }) => ({
                url: '/wallet',
                method: 'GET',
                params: {
                    'user_uid': uid,
                    'user_wallet_address': wallet_address
                },
                headers: buildHeaders(token)


            }),
            transformResponse: (response: any) => {
                return response.wallets
            }
        })


    })
});

export const {
    useGetAllUsersQuery,
    useRegisterUserMutation,
    useRegisterDepositMutation,
    useApproveUserPurchaseMutation,
    useVerifyUserIdentificationMutation,
    useGetPendingPurchasesQuery,
    useGetIdentificationRequestsQuery,
    useGetWalletQuery
} = usersApi;
