import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import RangeCalendarField from "app/components/RangeCalendarField";
import { useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import BookingHistoryTable from "./BookingHistoryTable";

const BookingHistory = () => {
    const [filters, setFilters] = useState({
        property_code: '',
        start_date: '',
        end_date: '',
    });

    return (<PageWrapper nocard>
        <Paper aria-label='buscador' sx={{ p: 3, mb: 5 }}>
            <Typography variant="title1" pb={1} pt={3} component={'p'}>Booking history</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Property code" variant="outlined" size="small"
                        onChange={(e) => setFilters({ ...filters, property_code: e.target.value as string })}
                        value={filters.property_code}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RangeCalendarField
                        sx={{ width: '100%' }}
                        onRangeSelect={({ startDate, endDate }) => {
                            setFilters({ ...filters, start_date: startDate.toISOString(), end_date: endDate.toISOString() })
                        }}
                    />

                </Grid>
                <Grid item xs={12} md={2}>
                    <Button fullWidth variant="contained" color="primary">Search</Button>
                </Grid>
            </Grid>

        </Paper>

        <Paper sx={{ p: 3 }} elevation={3}>
            <BookingHistoryTable/>
        </Paper>

    </PageWrapper>);
}

export default BookingHistory;