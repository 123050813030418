import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import App from "./app/App";

// third party css
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

// main root element
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
);
