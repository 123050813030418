
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Icon, IconButton, Modal } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import { wrap } from "popmotion"
import { useState } from "react"


const ArrowButton = ({ direction, onClick }: { direction?: 'left' | 'right', onClick: () => void }) => {
    return <Box sx={{
        position: 'absolute',
        top: 0,
        ...(direction === 'left' ? { left: 0 } : { right: 0 }),
        bottom: 0,
        padding: '0 16px',
        borderRadius: '0',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',

    }}>
        <IconButton onClick={onClick} sx={{ backgroundColor: 'white' }}>
            {direction === 'left' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
    </Box>


}


const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
}

const variants: any = {

    enter: (direction: number) => {
        return {
            x: direction > 0 ? "100%" : "-100%",
            opacity: 0
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,

        }
    }
}
const PropertyCarrousel = ({ images, open, onClose }: { images: string[], open: boolean, onClose: () => void }) => {
    const [[page, direction], setPage] = useState([0, 0])
    const imageIndex = wrap(0, images.length, page)
    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection])
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                backgroundColor: 'rgb(112 108 108 / 80%)',
            }}

        >
            <>
                <Box aria-label="topbar" sx={{ p: '10px', height: '100%' }}>
                    <IconButton onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0, p: '1rem' }}>
                        <Icon>close</Icon>
                    </IconButton>

                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        border: 'none',
                        textAlign: '-webkit-center',

                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            margin: '0 auto',
                            maxWidth: '80%',
                            maxHeight: '80%',
                            height: '100%',
                        }}
                    >

                        <AnimatePresence mode="popLayout" initial={false} custom={direction}>
                            <motion.img
                                key={page}
                                src={images[imageIndex]}
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: { type: "spring", stiffness: 250, damping: 100 },
                                    opacity: { duration: 0.2 }
                                }}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={1}
                                onDragEnd={(e, { offset, velocity }) => {
                                    const swipe = swipePower(offset.x, velocity.x)

                                    if (swipe < -swipeConfidenceThreshold) {
                                        paginate(1)
                                    } else if (swipe > swipeConfidenceThreshold) {
                                        paginate(-1)
                                    }
                                }}

                                style={{
                                    position: 'relative', width: '100%', objectFit: 'contain', margin: '0 auto', borderRadius: '10px', height: '100%'
                                }}
                            />
                        </AnimatePresence>


                        <ArrowButton direction='left' onClick={() => paginate(-1)} />
                        <ArrowButton direction='right' onClick={() => paginate(1)} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '16px',
                            overflowX: 'auto',  
                        }}
                    >
                        {images.map((img, i) => (
                            <Box
                                component='img'
                                key={i}
                                src={img}
                                alt={`Thumbnail ${i + 1}`}
                                sx={(theme) => ({
                                    width: '6rem',
                                    height: 'auto',
                                    margin: '0 4px',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                    border: i === imageIndex ? `2px solid ${theme.palette.primary.main}` : 'none', // Resalta la miniatura activa
                                })}
                                onClick={() => setPage([i, i - imageIndex])}
                            />
                        ))}
                    </Box>
                </Box>
            </>
        </Modal>
    )
}



export default PropertyCarrousel;