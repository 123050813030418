import HomePage from "./Home/HomePage";
import HowItWorksPage from "./HowItWorks/HowItWorksPage";
import InvestPage from "./Invest/InvestPage";
import RentPage from "./Rent/RentPage";
import PropertyPage from "./Property/PropertyPage";
import DepositPage from "./Deposit/DepositPage";
interface IPage {
  path: string;
  element: JSX.Element;
}

const mainPageRoutes: IPage[] = [
  { path: "/", element: <HomePage /> },
  { path: "rent", element: <RentPage /> },
  { path: "how-it-works", element: <HowItWorksPage /> },
  { path: "invest", element: <InvestPage /> },
  { path: "property/:propUUID", element: <PropertyPage /> },
  { path: "deposit", element: <DepositPage /> }
];


export default mainPageRoutes;
