import { OBContainer, OBSPanHeader } from "./OBStyles";

const OBTitleRow = () => {
    return (
        <OBContainer>
            <OBSPanHeader>PRICE</OBSPanHeader>
            <OBSPanHeader>AMOUNT</OBSPanHeader>
            <OBSPanHeader>TOTAL</OBSPanHeader>
        </OBContainer>);
}

export default OBTitleRow;