import { Toolbar, Typography, IconButton, Tooltip, Button } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';

export interface RenvanceToolbarProps {
    numSelected: number
    options?: { addRow: boolean, addRowName?: string }
    onClickAddRow: () => void
}

export function RenvanceToolbar(props: RenvanceToolbarProps) {
    const { numSelected, options, onClickAddRow } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {options?.addRow && <Button variant='contained' color='primary' size="small" sx={{ mb: 2 }}
                onClick={onClickAddRow}
            >
                {options.addRowName || 'Add new Row'}
            </Button>}
        </Toolbar>
    );
}