import { Card, Grid } from "@mui/material";

import { useCreateOrderMutation } from "app/redux/api/walletApi";
import Actions from "./Actions/Actions";
import PucharseChart from "./Chart/PucharseChart";
import OrderBook from "./OrderBook/OrderBook";


const Purchase = ({ tokenTicker = 'UN_TOKEN_TICKER' }: { tokenTicker?: any }) => {

  const [createOrder] = useCreateOrderMutation();
  // const [cancelOrder, { data: cancelData, isLoading: cancelIsLoading, isUninitialized: cancelIsUninitialized }] = useCancelOrderMutation();


  const handleBuyOrder = (buyOrder: any) => {
    createOrder({
      token_ticker: tokenTicker,
      order_quantity: buyOrder.amount,
      order_price: buyOrder.price,
      order_type: 'BUY',
      token: 'INVESTOR_USER'
    })
      .unwrap()
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSellOrder = (sellOrder: any) => {
    console.log(sellOrder)
  }

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={12} aria-label='graphAndActions'>
          <Card elevation={1}>

            <PucharseChart />
          </Card>
        </Grid>
        <Grid item xs={12} md={12} aria-label='sellBuyOrders&Actions' sx={{ display: 'flex' }}>
          <Grid item md={4} xs={12} >
            <Card elevation={5} sx={{ maxWidth: '18rem' }}>
              <OrderBook />
            </Card>
          </Grid>
          <Grid item md={8} xs={12}>
            <Card elevation={5}>
              <Actions onSellOrder={handleSellOrder} onBuyOrder={handleBuyOrder} />
            </Card>
          </Grid>


        </Grid>



      </Grid>


    </>
  );
};


export default Purchase;
