import { Box, Chip, Icon, Stack, Typography } from "@mui/material";
import RenvanceTable, { ITableSchema } from "app/components/Tables/RenvanceTable";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import useAuth from "app/hooks/useAuth";
import { usersApi } from "app/redux/api/usersApi";
import { useSnackbar } from "notistack";
import ModalReviewUserApproval from "app/components/Modals/ModalReviewUserApproval";
import ModalRejectUserApproval from "app/components/Modals/ModalRejectUserApproval";



const dummyApproval = [
    {
        "name": "James P",
        "lastname": "Sullivan",
        "phone_number": "+54 9 11 5695 8336",
        "passport": "EXD46721",
        "created_at": "2023-12-26T16:43:03.800238",
        "modified_at": null,
        "deleted_at": null,
        "updated_by": null,
        "country": "AR",
        "state": "Monstropolis",
        "address": "324 Scare Road",
        "zipcode": "80012",
        "email": "jamespsullivan@monsterinc.com",
        "id": 1,
        "official_id_frontside_photo": "https://i.ebayimg.com/images/g/Pj8AAOSw9V1ahvIq/s-l1200.webp",
        "official_id_backside_photo": "https://i.pinimg.com/474x/40/fd/7d/40fd7d63ab9d120f8d19e689f5fc29a3.jpg",
        "selfie_photo": "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/170b27b7-cc33-4967-9cd2-493c793a852e/dgcgdmv-028fef22-5ef1-4926-a415-418f274ed414.png/v1/fill/w_1280,h_694,q_80,strp/james_p__sullivan_by_futdiversoesrj_dgcgdmv-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9Njk0IiwicGF0aCI6IlwvZlwvMTcwYjI3YjctY2MzMy00OTY3LTljZDItNDkzYzc5M2E4NTJlXC9kZ2NnZG12LTAyOGZlZjIyLTVlZjEtNDkyNi1hNDE1LTQxOGYyNzRlZDQxNC5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.hpwaTkNa4DuOB4uhaCBjlxJdQ1O2Cdc6CKuD0NxRK1A",
        "user_uid": "f5c4959b-da4e-4f10-a708-05ba6460150a",
        "wallet_address": "0x146e9bbdb4daf23f24tg59f",
    }
]

const approvalSchema: ITableSchema[] = [
    {
        id: 'request_date',
        label: 'Request Date',
        customComponent: ({ value }) => {
            return dayjs(value).format('DD/MM/YYYY')
        },
    },
    {
        id: 'name',
        label: 'Name',
        customComponent: ({ value, row }) => {
            return `${row.name}`
        },
    },
    {
        id: 'lastname',
        label: 'Lastname',
        customComponent: ({ value, row }) => {
            return `${row.lastname}`
        },
    },
    {
        id: 'id_passport',
        label: 'ID/Passport',
        customComponent: ({ value, row }) => {
            return `${row.passport}`
        },
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
        customComponent: ({ value, row }) => {
            return `${row.phone_number}`
        },
    },
    {
        id: 'email',
        label: 'Email',
        customComponent: ({ value, row }) => {
            return `${row.user_email}`
        },
    }
]

const UserApprovalPage = () => {
    const { user } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [rows, setRows] = useState<any[]>(dummyApproval);
    const [openReviewModal, setOpenReviewModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [requestSelected, setRequestSelected] = useState<any>(null)

    const [identificationRequests]  = usersApi.endpoints.getIdentificationRequests.useLazyQuery()
    const [isLoading, setIsLoading] = useState(true)

    const updateTableRows = (updatedRows: any[]) => {
        console.log(updatedRows)
        setRows(updatedRows);
    }

    useEffect(() => {
        user.getAsyncToken()
            .then((token) => {
                identificationRequests({ token, }, true)
                .unwrap()
                .then(( data ) =>
                    setRows(data.map(
                        (request: any) => ({
                            name: request.name,
                            lastname: request.lastname,
                            passport: request.passport,
                            phone_number: request.phone_number,
                            country: request.country,
                            address: request.address,
                            zipcode: request.zipcode,
                            state: request.state,
                            user_uid: request.user_uid,
                            user_email: request.user_email,
                            id: request.id,
                            wallet_address: request.wallet_address,
                            official_id_frontside_photo: request.official_id_frontside_photo? request.official_id_frontside_photo : undefined,
                            official_id_backside_photo: request.official_id_backside_photo ? request.official_id_backside_photo : undefined,
                            selfie_photo: request.selfie_photo ? request.selfie_photo : undefined
                        })
                    ))
                )
                    .catch((err) => {
                        enqueueSnackbar(err?.message || 'Error getting user requests ', { variant: 'error' })
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            })
    }, [])

    return (
        <PageWrapper>
            <Typography variant="title1" display='flex' justifyContent='center' component='h1'>
                User approval requests
            </Typography>

            <Box mt={4}>
                <RenvanceTable
                    schema={approvalSchema}
                    rows={rows}
                    loading={isLoading}
                    customActions={[
                        {
                            text: 'Approve',
                            icon: 'visibility',
                            onClick: (row: any) => {
                                setRequestSelected(row)
                                setOpenReviewModal(true)
                            },
                            component: ({ onClick }: { onClick: () => void }) => {
                                return <Chip
                                    color='success'
                                    onClick={onClick}
                                    icon={<Icon fontSize="small">thumb_up_alt</Icon>}
                                    label='Approve'
                                />
                            }
                        },
                        {
                            text: 'reject',
                            icon: 'visibility',
                            onClick: (row: any) => {
                                setRequestSelected(row)
                                setOpenRejectModal(true)
                            },
                            component: ({ onClick }: { onClick: () => void }) => {
                                return <Chip
                                    color='error'
                                    onClick={onClick}
                                    icon={<Icon fontSize="small">thumb_down_alt</Icon>}
                                    label='Reject'
                                />
                            }

                        }
                    ]}

                />

                <ModalReviewUserApproval
                    open={openReviewModal}
                    onClose={() => { setOpenReviewModal(false); setRequestSelected(null) }}
                    approval={requestSelected}
                    onSubmit={() => {
                    }}
                    rows={rows}
                    updateTableRows={updateTableRows}
                />
                <ModalRejectUserApproval
                    open={openRejectModal}
                    onClose={() => { setOpenRejectModal(false); setRequestSelected(null) }}
                    approval={requestSelected}
                    onSubmit={() => {
                    }}
                    rows={rows}
                    updateTableRows={updateTableRows}
                />
            </Box>

        </PageWrapper>
    );
}

export default UserApprovalPage;