import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import StatementTable, { StatementRowsTable } from "./StatementTable";
import { useState } from "react";
import dayjs from "dayjs";
import StatementAddModal from "./StatementAddModal";
import AddExpenseModal from "./AddExpenseModal";

const Statements = ({ statements }: { statements: any }) => {

  const [tableStatements] = useState<StatementRowsTable[]>(
    statements.map((s: any) => {
      return {
        date: dayjs(s.date).format('DD/MM/YYYY'),
        description: s.description,
        file_id: s.file_id,
      }
    })
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExpenseOpen, setIsModalExpenseOpen] = useState(false);
  const [year, setYear] = useState('2023');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }


  const handleOnSubmit = (data: any) => {
    console.log(data, 'recibiendo datos..')

  }

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  return (
    <>
      <StatementAddModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        onSubmit={() => handleOnSubmit}

      />
      <AddExpenseModal
        open={isModalExpenseOpen}
        onClose={() => setIsModalExpenseOpen(!isModalExpenseOpen)}
        onSubmit={() => handleOnSubmit}
      />
      <Box aria-label='card-content' sx={{ pr: 3, pl: 3 }}>

        <Box aria-label='header' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl sx={{ m: 2 }}>
            <InputLabel>Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year.toString()}
              label="Year"
              onChange={handleChangeYear}
            >
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
            </Select>
          </FormControl>

        </Box>


        <StatementTable rows={tableStatements} />

        <Box aria-label='actions'
          sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant='contained'
            color='error'
            onClick={handleOpenModal}
            sx={{ mr: 2 }}
          >
            Add Expense
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={handleOpenModal}
          >
            Add Statement
          </Button>
        </Box>
      </Box>


    </>
  );
};

export default Statements;
