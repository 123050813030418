import { User } from "firebase/auth";
import { Token } from "./types";

export enum UserRole {
    RENVANCE_ADMIN = 'RENVANCE_ADMIN',
    RENVANCE_USER = 'RENVANCE_USER',
    AGENCY_ADMIN = 'AGENCY_ADMIN',
    AGENCY_USER = 'AGENCY_USER',
    DEVELOPER_ADMIN = 'DEVELOPER_ADMIN',
    DEVELOPER_USER = 'DEVELOPER_USER',
    INVESTOR = 'INVESTOR',
    GUEST = 'GUEST'
}

export interface IUser extends User, Guest {
    role: UserRole;
    accessToken: string;
    claims: any;
    getAsyncToken: (forceRefresh?: boolean) => Promise<string>;
}

export interface Investor {
    balance: number;
    orders: { sell_orders: any[], buy_orders: any[] };
    account: { account_id: number, movement: [{ token: Token, amount: number, date: Date }] };
    bookings: any[];
    favorites: any[];
}

export interface Guest {
    bookings: any[];
    favorites: any[];
    toggleFavourite: (property: string) => void;

}
