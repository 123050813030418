import { RouteObject } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import DashboardLayout from "./components/DashboardLayout/DashboardLayout";
import { UserRole } from "app/redux/models";
import agencyPagesRoutes from "./views/agency-pages/agencyPagesRoutes";
import MainLayout from "./views/main-pages/Layout/MainLayout";
import mainPageRoutes from "./views/main-pages/mainPageRoutes";
import ProfileLayout from "./views/profile-pages/ProfileLayout/ProfileLayout";
import profilePagesRoutes from "./views/profile-pages/profilePagesRoutes";
import NotFound from "./views/sessions/NotFound";
import sessionRoutes from "./views/sessions/SessionRoutes";

const routes: RouteObject[] = [
  {
    element: <AuthGuard allowRoles={[UserRole.AGENCY_ADMIN, UserRole.AGENCY_USER, UserRole.RENVANCE_ADMIN, UserRole.RENVANCE_USER]}><DashboardLayout /></AuthGuard>,
    children: [...agencyPagesRoutes],
  },
  {
    element: <AuthGuard allowRoles={[UserRole.INVESTOR, UserRole.GUEST]}><ProfileLayout /></AuthGuard>,
    children: [...profilePagesRoutes]
  },
  {
    element: <MainLayout />,
    children: [...mainPageRoutes]
  },
  ...sessionRoutes,
  { path: "*", element: <NotFound /> },
];

export default routes;
