import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Fade, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { formatToUSDValue } from 'app/utils/utils';
import dayjs from 'dayjs';
import useAuth from 'app/hooks/useAuth';
import { useApproveUserPurchaseMutation } from 'app/redux/api/usersApi';
import { useSnackbar } from 'notistack';
import { useState, Fragment } from 'react';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{
                width: { xs: '100%', md: '40rem' },
            }} {...props} />
        </Draggable>
    );
}

const ModalRejectDeposit = ({ open, onClose, onSubmit, deposit }: { open: boolean, onClose: () => void, onSubmit: (deposit: any) => void, deposit: any }) => {


    const { user } = useAuth();
    const [approveUserPurchase, { isLoading }] = useApproveUserPurchaseMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [rejected_reason, setRejectedReason] = useState('');


    const handleClose = () => {
        !isLoading &&
            onClose();
    };

    const handleReject = async () => {
        user.getAsyncToken()
            .then((token) => {
                approveUserPurchase({
                    token, data: {
                        purchase_uuid: deposit.uuid,
                        status: 'REJECTED',
                        token_ticker: 'REUSD',
                        currency: deposit.currency,
                        conversion_rate: 0,
                        rejected_reason: rejected_reason,


                    }
                }).unwrap()
                    .then((data) => {
                        enqueueSnackbar('Deposit rejected process received', { variant: 'success', autoHideDuration: 3000 });
                        onSubmit(data);
                        handleClose();
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.message || 'Error rejecting deposit', { variant: 'error' });
                    });
            })

    }


    return deposit && (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                maxWidth="xl"
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Reject Deposit
                    <Fade in={isLoading} unmountOnExit>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Fade>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6} >
                            <Typography variant="body1">Account</Typography>
                            <Typography variant="body1" sx={{ textWrap: 'no-wrap' }}><strong>{deposit.investor.display_name} {deposit.wallet_address}</strong></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">Date Notified</Typography>
                            <Typography variant="body1"><strong>{dayjs(deposit.deposit_date).format('DD/MM/YYYY')}</strong></Typography>

                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">Date accepted</Typography>
                            <Typography variant="body1"><strong>{dayjs().format('DD/MM/YYYY')}</strong></Typography>

                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">Amount Informed</Typography>
                            <Typography variant="body1"><strong>{formatToUSDValue(deposit.deposit_amount)} USDT</strong></Typography>

                        </Grid>
                        <Grid item xs={8}>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">Reject Reason</Typography>
                            <TextField fullWidth multiline rows={4}
                                value={rejected_reason}
                                onChange={(e) => setRejectedReason(e.target.value)}
                            />

                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleReject()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Fragment>

    );
}

export default ModalRejectDeposit;