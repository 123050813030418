import { Box, ButtonBase, Icon, styled, useMediaQuery } from "@mui/material";
import useSettings from "app/hooks/useSettings";
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Paragraph, Span } from "../Typography";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import useAuth from "app/hooks/useAuth";
import { UserRole } from "app/redux/models";

//@ts-ignore
const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
  fontSize: "12px",
  marginTop: "20px",
  marginLeft: "15px",
  marginBottom: "10px",
  textTransform: "uppercase",
  display: mode === "compact" && "none",
  color: theme.palette.text.secondary,
}));

const ExtAndIntCommon = {
  display: "flex",
  overflow: "hidden",
  borderRadius: "4px",
  height: 44,
  whiteSpace: "pre",
  marginBottom: "8px",
  textDecoration: "none",
  justifyContent: "space-between",
  transition: "all 150ms ease-in",
  "&:hover": { background: "rgba(255, 255, 255, 0.08)" },
  "&.compactNavItem": { overflow: "hidden", justifyContent: "center !important" },
  "& .icon": {
    fontSize: "18px",
    paddingLeft: "16px",
    paddingRight: "16px",
    verticalAlign: "middle",
  },
};
//@ts-ignore

const ExternalLink = styled("a")(({ theme }) => ({
  ...ExtAndIntCommon,
  color: theme.palette.text.primary,
}));

const InternalLink = styled(Box)(({ theme }) => ({
  "& a": { ...ExtAndIntCommon, color: theme.palette.text.primary },
  "& .navItemActive": { backgroundColor: "rgba(255, 255, 255, 0.16)" },
}));
//@ts-ignore
const StyledText = styled(Span)(({ mode }: { mode: any }) => ({
  fontSize: "0.875rem",
  paddingLeft: "0.8rem",
  display: mode === "compact" && "none",
}));

const BulletIcon = styled("div")(({ theme }) => ({
  padding: "2px",
  marginLeft: "24px",
  marginRight: "8px",
  overflow: "hidden",
  borderRadius: "300px",
  background: theme.palette.text.primary,
}));

const BadgeValue = styled("div")({
  padding: "1px 8px",
  overflow: "hidden",
  borderRadius: "300px",
});

export interface INavItem {
  name: string;
  icon?: any;
  iconText?: string;
  badge?: {
    value: string;
    color: string;
  };
  children?: INavItem[];
  type?: 'extLink' | 'label';
  path?: string;
  label?: string;
  allowRoles?: UserRole[]

}

const MatxVerticalNav = ({ items, onCloseSidebar }: { items: INavItem[], onCloseSidebar: () => void }) => {
  const { settings } = useSettings();
  const { user } = useAuth();
  const { mode } = settings.layoutSettings.leftSidebar;
  //@ts-ignore
  const downLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const renderLevels = (data: any, sub = 0) => {
    return data.map((item: any, index: any) => {
      // Render the titles of the menu sections as well as the head of nested menus 
      if (item.allowRoles && !item.allowRoles.includes(user?.role)) {
        return null;
      }


      if (item.type === "label") {
        return (
          //@ts-ignore
          <ListLabel key={index} mode={mode} className="sidenavHoverShow" sx={{
            ml: `${sub + 1}rem !important`,
          }}>
            {item.label}
          </ListLabel>
        );
      }
      // Render the titles of the nested menu sections 
      if (item.children) {
        return (
          <MatxVerticalNavExpansionPanel mode={mode} item={item} key={index}>
            {renderLevels(item.children, sub + 1)}
          </MatxVerticalNavExpansionPanel>
        );
      }

      if (item.type === "extLink") {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            target="_blank"
            rel="noopener noreferrer"
            className={`${mode === "compact" && "compactNavItem"}`}
          >
            <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
              {(() => {
                if (item.icon) {
                  return <Icon className="icon">{item.icon}</Icon>;
                } else {
                  return <span className="item-icon icon-text">{item.iconText}</span>;
                }
              })()}
              {/*@ts-ignore*/}
              <StyledText mode={mode} className="sidenavHoverShow">
                {item.name}
              </StyledText>

              <Box mx="auto" />

              {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
            </ButtonBase>
          </ExternalLink>
        );
      } else {
        return (
          <InternalLink key={index}>
            <NavLink
              end
              to={item.path}
              {...(downLg && { onClick: onCloseSidebar })}
              className={({ isActive }) =>
                isActive
                  ? `navItemActive ${mode === "compact" && "compactNavItem"}`
                  : `${mode === "compact" && "compactNavItem"}`
              }
            >
              <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
                {item?.icon ? (
                  <Icon className="icon" sx={{ width: 36, ml: `${10 * sub}px !important` }}>
                    {item.icon}
                  </Icon>
                ) : (
                  <Fragment>
                    <BulletIcon
                      className="nav-bullet"
                      sx={{ display: `${mode === "compact" && "none"}` }}
                    />
                    <Box
                      className="nav-bullet-text"
                      sx={{ ml: "20px", fontSize: 11, display: `${mode !== "compact" && "none"}` }}
                    >
                      {item.iconText}
                    </Box>
                  </Fragment>
                )}
                {/*@ts-ignore*/}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>

                <Box mx="auto" />

                {item.badge && (
                  <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                )}
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(MatxVerticalNav);
