import { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { criptoConfig } from "config";
import reUsd from 'assets/contracts/ReUSD.json';
import usdt from 'assets/contracts/USDT.json';
import { LogoBig } from "assets/images";
import { useSnackbar } from 'notistack';

const network = 'publicPolygon'

declare global {
    interface Window {
        ethereum: any;
    }
}
export enum connectionStatus {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    ERROR = 'error',
    LOADING = 'loading'
}
const _getReUsdContract = (provider: any) => {
    return new ethers.Contract(criptoConfig.reUsdAddress, reUsd.abi, provider);
}
const _getUsdtContract = (provider: any) => {
    return new ethers.Contract(criptoConfig.usdtAddress, usdt.abi, provider);
}
const _getBalance = async (contract: ethers.Contract, account: string) => {
    const balance = await contract.balanceOf(account);
    return Number(ethers.formatUnits(balance, 2));
}
const _getAllowance = async (contract: ethers.Contract, account: string) => {
    const allowance = await contract.allowance(account, criptoConfig.reUsdAddress);
    return Number(ethers.formatUnits(allowance, 2));
}

export interface IReUSDContract {
    address: string;
    holder: {
        address: string;
        balance: number;
    },
    paused: boolean;
    
    //allow to manage the wallets on the sc ReUsd contract
    wallets: {
        getAllKnownWallets: () => Promise<string[]>;
        isKnownWallet: (address: string) => Promise<boolean>;
        addKnownWallet: (address: string) =>  Promise<any>;
        removeKnownWallet: (address: string) => Promise<any>;
    }
}

const useContract = () => {

    const [providerConnState, setProviderConnState] = useState<connectionStatus>(connectionStatus.LOADING);
    const [currentAccount, setCurrentAccount] = useState<string>('');
    const [balanceUSDT, setBalanceUSDT] = useState<number>(0);
    const [balanceREUSD, setBalanceREUSD] = useState<number>(0);
    const provider = new ethers.BrowserProvider(window.ethereum);

    const { enqueueSnackbar } = useSnackbar();
    const messageSnackbar = (message: string, variant: 'error' | 'success' | 'warning' | 'info') => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, autoHideDuration: 3000 });
    }



    const BuyReUsd = async (amount: number) => {
        const signer = await provider.getSigner();
        const reusdContract = _getReUsdContract(signer);
        const usdtContract = _getUsdtContract(signer);


        let allowance = await _getAllowance(usdtContract, signer.address);

        try {
            if (allowance < amount) {
                if (allowance > 0) {
                    messageSnackbar('You need to restart the allowance', 'warning');
                    const tx = await usdtContract.approve(criptoConfig.reUsdAddress, 0);
                    messageSnackbar('Aproving USDT', 'info');
                    await tx.wait();
                }
                // x100 because of decimals
                const tx = await usdtContract.approve(criptoConfig.reUsdAddress, amount * 100);
                messageSnackbar('Aproving USDT', 'info');
                await tx.wait();
            }
        }
        catch (error: any) {
            throw { code: 'ACTION_APROVAL_REJECTED' };
        }
        try {
            // x100 because of decimals
            const tx = await reusdContract.pucharse("USDT", amount * 100);
            messageSnackbar('Buying ReUSD', 'info');
            await tx.wait();
            messageSnackbar('ReUSD bought', 'success');
            setBalanceREUSD(await _getBalance(reusdContract, signer.address));
            setBalanceUSDT(await _getBalance(usdtContract, signer.address));
        } catch (error: any) {
            console.log('Código de error:', error.code);
            console.log('Motivo del error:', error?.reason || error?.message);
        }
    };


    const checkWalletIsConnected = useCallback(async () => {
        if (!window.ethereum) {
            console.log("Make sure you have Metamask installed!");
            return;
        } else {
            console.log("Wallet exists! We're ready to go!")
        }

        const accounts = await window.ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account: ", account);
            setCurrentAccount(account);

            const usdtContract = _getUsdtContract(provider);
            const reusdtContract = _getReUsdContract(provider);

            setBalanceUSDT(await _getBalance(usdtContract, account));
            setBalanceREUSD(await _getBalance(reusdtContract, account));

            setProviderConnState(connectionStatus.CONNECTED);


        } else {
            console.log("No authorized account found");
            setProviderConnState(connectionStatus.DISCONNECTED);
        }
    }, [provider, currentAccount]);

    const AddReUSDTokenToMetamask = async () => {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: criptoConfig.reUsdAddress,
                    symbol: 'ReUSD',
                    decimals: 2,
                    image: LogoBig,
                },
            },
        }).then((success: any) => {
            console.log('Successfully added ReUSD to wallet!')
        }).catch((error: { code: string }) => {
            // catch any errors on the component whre it was imported
        })

    }

    const ConnectWallet = async () => {
        await window.ethereum.request({ method: 'eth_requestAccounts' })
    }

    useEffect(() => {
        checkWalletIsConnected();
    }, [currentAccount]);

    window.ethereum.on('accountsChanged', (accounts: string[]) => {
        setCurrentAccount(accounts[0]);
        checkWalletIsConnected();
    });

    const getReUSDContract = async (): Promise<IReUSDContract> => {
        const signer = await provider.getSigner();
        const reusdtContract = _getReUsdContract(signer);
        const usdtContract = _getUsdtContract(signer);

        const holderAddress = await reusdtContract.getWallet()
        const holderBalance = await _getBalance(usdtContract, holderAddress);

        const paused = await reusdtContract.paused();

        const getAllKnownWallets = async () => {
            return await reusdtContract.getAllKnownWallets();
        }
        const isKnownWallet = async (address: string) => {
            return await reusdtContract.isKnownWallet(address);
        }
        const addKnownWallet = async (address: string) => {
            return await reusdtContract.addKnownWallet(address);
        }
        const removeKnownWallet = async (address: string) => {
            return await reusdtContract.removeKnownWallet(address);
        }

        return {
            address: criptoConfig.reUsdAddress,
            holder: {
                address: holderAddress,
                balance: holderBalance
            },
            wallets: {
                getAllKnownWallets,
                isKnownWallet,
                addKnownWallet,
                removeKnownWallet
            },
            paused
        };


    }


    return {
        providerConnState,
        currentAccount,
        AddReUSDTokenToMetamask,
        balanceUSDT,
        balanceREUSD,
        BuyReUsd,
        ConnectWallet,
        getReUSDContract
    };
};

export default useContract;
