import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Collapse, Grid } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { formatToUSDValue } from 'app/utils/utils';

interface DataItems {
  date: string;
  operation: string;
  amount: number;
  price: number;
  value: number;

}
interface Data {
  amount: number;
  earnings: number;
  currentPrice: number;
  tokenName: string;
  pnl: number;
  items: DataItems[];
}



function createData(
  tokenName: string,
  amount: number,
  currentPrice: number,
  earnings: number,
  pnl: number,
  items?: DataItems[],
): Data {
  return {
    tokenName,
    amount,
    currentPrice,
    earnings,
    pnl,
    items: items ? items : [],
  };
}

const rows: Data[] = [
  createData('ApiI', 305, 3.7, 67, -4.3,
    [{ date: '2021-10-10', operation: 'buy', amount: 100, price: 10, value: 1000 },
    { date: '2021-10-10', operation: 'buy', amount: 100, price: 10, value: 1000 },
    { date: '2021-10-10', operation: 'buy', amount: 100, price: 10, value: 1000 }]),
  createData('APi2', 452, 25.0, 51, 4.9),
  createData('api3', 262, 16.0, 24, -6.0),
  createData('api4', 159, 6.0, 24, 4.0),
  createData('api5', 356, 16.0, 49, 3.9),
  createData('api6', 408, 3.2, 87, 6.5),

];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'tokenName',
    numeric: false,
    disablePadding: true,
    label: 'Token Name',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'currentPrice',
    numeric: true,
    disablePadding: false,
    label: 'Current Price',
  },
  {
    id: 'earnings',
    numeric: true,
    disablePadding: false,
    label: 'Earnings',
  },
  {
    id: 'pnl',
    numeric: true,
    disablePadding: false,
    label: 'PNL',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ wordBreak: 'break-word' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align={'right'} >
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const StyledButton = styled(Button)(() => ({
  color: 'gray',
  fontSize: '1.55em',
}));

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="title1"
          id="tableTitle"
          component="div"
        >
          Transactions
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (<>

        <Grid container spacing={2} justifyContent={'flex-end'} margin={0}>
          <StyledButton size="large">
            Deposit
          </StyledButton>
          <StyledButton size="large">
            Withdraw
          </StyledButton>
        </Grid>

      </>)}
    </Toolbar>
  );
}

const PNLItem = ({ value }: { value: number }) => {
  const color = value > 0 ? 'success.main' : 'error.main';
  const icon = value > 0 ? <ArrowCircleUpIcon fontSize="small" /> : <ArrowCircleDownIcon fontSize="small" />;

  return (
    <Box
      sx={{
        color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: '60%',
        minWidth: '100%',
        '& svg': { mr: 0.5 },
      }}
    >
      {icon}
      {value}%
    </Box>
  );
};

export default function WalletTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('tokenName');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.tokenName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );


  const MyRow = ({ row, index }: { row: Data, index: number }) => {
    const [open, setOpen] = React.useState(false);
    return (<>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        sx={{ cursor: 'pointer' }}
        key={'row' + index}
      >
        <TableCell
          component="th"
          scope="row"
          padding="none"
        >
          {row.tokenName}
        </TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{formatToUSDValue(row.currentPrice)}</TableCell>
        <TableCell align="right">{row.earnings}</TableCell>
        <TableCell align="right"><PNLItem value={row.pnl} /></TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={'expandRow' + index}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Operation</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((data, index) => (
                    <TableRow key={'expandRowItem' + index + data.amount}>
                      <TableCell component="th" scope="row">
                        {data.date}
                      </TableCell>
                      <TableCell align="right" >{data.operation}</TableCell>
                      <TableCell align="right">{data.amount}</TableCell>
                      <TableCell align="right">{formatToUSDValue(data.price)}</TableCell>
                      <TableCell align="right">
                        {Math.round(data.amount * data.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>);

  }



  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return <MyRow row={row} index={index} key={'mapRows' + index} />
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}