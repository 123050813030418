import { Button, CardContent, Dialog, DialogContent, Divider, Grid, Icon, TextField, Typography, Card } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useState } from "react";
import { date, number, object, string } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { formatToUSDValue } from "app/utils/utils";


const validationSchema = object().shape({
    propertyUUID: string().required("Property UUID is required"),
    propertyCode: string().required("Property Code is required"),
    statementDate: date().required("Statement Date is required"),
    description: string().required("Description is required"),
    filePathLink: string().required("File Path Link is required"),
    propertyName: string().required("Property Name is required"),
    id: number().required("ID is required"),
});

function ModalOffer({ open, onClose }: { open: any; onClose: any; }) {

    const [values, setValues] = useState({
        propertyUUID: "",
        propertyCode: "",
        statementDate: new Date(),
        description: "",
        filePathLink: "",
        propertyName: "",
        id: 0,
    });
    const [file, setFile] = useState<File>();
    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleCancel = () => {
        setFile(undefined);
        onClose();
    }
    const handleSubmit = async () => {
        try {
            await validationSchema.validate(values, { abortEarly: false });
            // Aquí puedes realizar la lógica de envío de datos, por ejemplo, una llamada API.
            // Si la validación es exitosa, los datos en "values" son válidos.
        } catch (error) {
            // La validación falló, puedes manejar los errores aquí.
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleCancel}
        >
            <DialogContent>
                <Typography variant="title2" component='p'>Create for sale offer</Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container sx={{
                    backgroundColor: '#9199b830',
                    padding: '1rem',
                    borderRadius: '10px'

                }}>
                    <Grid item xs={3}>
                        <TextField fullWidth label="# of Tokens"
                            helperText="2.2% of total"

                        />
                    </Grid>
                    <Typography variant="subtitle2" component='p'>X</Typography>
                    <Grid item xs={4}>
                        <TextField fullWidth label="Market Value"
                            helperText="+32% versus issue value"
                        />
                    </Grid>
                    <Typography variant="subtitle2" component='p'>=</Typography>
                    <Grid item xs={4}>
                        <TextField fullWidth label="Total Value Amount"
                            helperText="The total value of your investment for sale"

                        />

                    </Grid>

                </Grid>
                <Grid container sx={{
                    backgroundColor: '#9199b830',
                    padding: '1rem',
                    borderRadius: '10px'

                }}>
                    <Grid item xs={3}>
                        <TextField fullWidth label="# of Tokens"
                            helperText="2.2% of total"

                        />
                    </Grid>
                    <Typography variant="subtitle2" component='p'>X</Typography>
                    <Grid item xs={4}>
                        <TextField fullWidth label="Market Value"
                            helperText="+32% versus issue value"
                        />
                    </Grid>
                    <Typography variant="subtitle2" component='p'>=</Typography>
                    <Grid item xs={4}>
                        <TextField fullWidth label="Total Value Amount"
                            helperText="The total value of your investment for sale"

                        />

                    </Grid>

                </Grid>
                <Card sx={{
                    mt: 2, backgroundColor: 'darkgray', display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="subtitle2" component='p' sx={{ mt: 2, ml: 2 }}>Posted Offer amount</Typography>
                    <Typography variant="subtitle2" component='p' sx={{ mt: 2, ml: 2 }}>{formatToUSDValue(98000)}</Typography>
                </Card>

                <Grid xs={12} display='flex'>
                    <Button variant='outlined' fullWidth >Sell</Button>
                    <Button variant='contained' fullWidth >Buy</Button>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default ModalOffer;
