import { Box, LinearProgress, Typography, linearProgressClasses, styled } from "@mui/material";
import { formatToUSDValue } from "app/utils/utils";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    paddingTop: 1,
    paddingButtom: 1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

const CrowdFundingBar = ({ title = 'Crowd Funding', amountColected = 0, remaining, noAmountColected = false }: { title?: string, amountColected?: number, remaining: number, noAmountColected?: boolean }) => {

    const maxVal = amountColected + remaining;
    const value = (amountColected * 100) / maxVal;

    return (
        <Box aria-label="crowdFunding">
            <Typography variant='title3' sx={{ mb: 2 }} >{title}</Typography>
           {!noAmountColected && <Box display='flex' justifyContent='space-between' sx={{ mt: 1 }}>
                <Typography variant="subtitle2"> Amount collected Money: {formatToUSDValue(amountColected)}</Typography>
            </Box>}
            <BorderLinearProgress variant="determinate" value={value} />
            <Box display='flex' justifyContent='space-between' sx={{ pt: '3px' }}>
                <Typography variant="subtitle2">Remaining: {formatToUSDValue(remaining)} ReUSD</Typography>
            </Box>
        </Box>

    );
}

export default CrowdFundingBar;