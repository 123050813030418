import { Box, Collapse, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import RenvanceTable, { ITableSchema } from ".";
import RenderCell from "./RenderCell";


export interface RenvanceRowProps {
    onRowClick: (event: React.MouseEvent<unknown>, id: number) => void;
    isItemSelected: boolean;
    schema: ITableSchema[];
    expandibleSchema?: ITableSchema[];
    expandibleTableName?: string;
    actions?: ('edit' | 'delete' | 'expand')[]
    customActions?: {
        icon: string,
        text: string,
        onClick: (row: any) => void,
        component?: any
    }[]
    row: any
    editionMode?: 'inline' | 'external'
    onEditRow: (row: any) => Promise<any>
    deleteRow: (row: any) => void
    addRow: (row: any) => Promise<any>

}

const renderIconButton = (action: string, icon: string, onClick: (action: string) => void) => (
    <IconButton size='small' onClick={() => onClick(action)}>
        <Icon fontSize='small'>{icon}</Icon>
    </IconButton>
);

const RenderActionButtons = ({ actions, mode, onClick, customActions, expanded }: { actions: RenvanceRowProps['actions'], customActions: RenvanceRowProps['customActions'], mode: string, onClick: (action: string) => void, expanded: boolean }) => {



    return (actions || customActions) && (
        <Box key={`TableVisibleRow-Action`} component='span' sx={{ mx: 1 }}>

            {actions && mode === 'view' && actions.includes('edit') && renderIconButton('edit', 'edit', onClick)}
            {actions && mode === 'view' && actions.includes('delete') && renderIconButton('delete', 'delete', onClick)}
            {actions && mode === 'edit' && renderIconButton('save', 'save', onClick)}
            {actions && mode === 'edit' && renderIconButton('clear', 'clear', onClick)}

            {actions && actions.includes('expand') && renderIconButton('expand', expanded ? 'expand_less' : 'expand_more', onClick)}

            {customActions && customActions.map((item, i) => {
                if (item.component) {
                    const Component = item.component as any
                    return <Component onClick={() => onClick(item.text)} key={`customActionComponent${i}${item.text}`} />
                }
                return renderIconButton(item.text, item.icon, onClick);
            })}
        </Box>

    );
};


const validateRow = (row: any, schema: ITableSchema[]) => {
    let errors: { [key: string]: string } = {};

    Object.keys(row).forEach((key) => {
        const schemaItem = schema.find((item) => item.id === key);
        if (schemaItem && schemaItem.validation) {
            try {
                schemaItem.validation.validateSync(row[key]);
            } catch (error: any) {
                errors[key] = error.message;
            }
        }
    });

    return errors;


}

const noValidationErrors = (errors: { [key: string]: string }) => errors && Object.keys(errors).length === 0;


const ActionCell = ({ actions, customActions, mode, onClick, expanded }: { actions: RenvanceRowProps['actions'], customActions: RenvanceRowProps['customActions'], mode: string, onClick: (action: string) => void, expanded: boolean }) => {

    return <TableCell align="right" >
        <RenderActionButtons
            expanded={expanded}
            actions={actions}
            mode={mode}
            onClick={onClick}
            customActions={customActions}
        />
    </TableCell>


}





const RenvanceRow = ({ onRowClick, isItemSelected, schema, expandibleSchema, expandibleTableName, actions, row, onEditRow, deleteRow, addRow, editionMode, customActions }: RenvanceRowProps) => {
    const [mode, setMode] = useState<'view' | 'edit'>(row._new ? 'edit' : 'view');
    const [expanded, setExpanded] = useState(false);

    const [editableValues, setEditableValues] = useState<{ [key: string]: any }>(
        () => {
            if (row?._new === true) return schema.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {});
            return {}
        }
    );
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});


    //? This state must be used to disable the save button while the row is being saved
    //eslint-disable-next-line
    const [_, setIsSaving] = useState(false);

    const handleSwitchMode = () => {
        setMode((prevMode) => (prevMode === 'view' ? 'edit' : 'view'));
    }

    const handleSave = () => {

        if (!editableValues) return handleSwitchMode();
        let errors = validateRow(editableValues, schema);


        setValidationErrors(errors);

        if (noValidationErrors(errors)) {
            setIsSaving(true);
            const editedRow = { ...row, ...editableValues };
            if (editedRow._new) {
                addRow(editedRow).then(() => { }).catch((error) => { }).finally(() => {
                    setMode('view');
                    setIsSaving(false)
                });
            }
            else {
                onEditRow(editedRow).then(() => {
                    setEditableValues({});
                    setValidationErrors({});
                    handleSwitchMode();
                }).catch((error) => {
                    console.log(error);
                }).finally(() => setIsSaving(false));
            }
        }
    }

    const handleClear = () => {
        if (row._new) return deleteRow(row);

        setEditableValues({});
        setValidationErrors({});
        handleSwitchMode();
    }

    const handleCellChange = (id: string, value: any) => {
        setEditableValues((prevValues) => ({ ...prevValues, [id]: value }));
        setValidationErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
    };



    return (<>
        <TableRow
            hover
            onClick={() => onRowClick}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                verticalAlign: validationErrors && Object.keys(validationErrors).length > 0 ? 'top' : 'middle',
            }}
        >



            {schema.map((cell, index) => <RenderCell
                row={row}
                sx={cell.sx}
                key={`Cell${index}-item${row.id}`}
                cell={cell}
                value={editableValues[cell.id] ?? row[cell.id]}
                error={!!validationErrors[cell.id]}
                helperText={validationErrors[cell.id]}
                mode={mode}
                index={index}
                onChange={handleCellChange}

            />)}

            {(actions && actions.length > 0) || (customActions && customActions.length > 0) ?

                <ActionCell expanded={expanded} customActions={customActions} actions={actions} mode={mode} onClick={(action) => {
                    if (action === 'edit') {
                        if (editionMode === 'external') return onEditRow(row);
                        return handleSwitchMode();
                    }
                    if (action === 'save') return handleSave();
                    if (action === 'clear') return handleClear();
                    if (action === 'delete') return deleteRow(row);
                    if (action === 'expand') return setExpanded((prev) => !prev);

                    const customAction = customActions?.find((item) => item.text === action);
                    if (customAction) return customAction.onClick(row);

                }} /> : <></>
            }
        </TableRow>
        {expandibleSchema && <TableRow sx={{ backgroundColor: '#2c334d14' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="subtitle2" gutterBottom component="div">
                            {expandibleTableName}
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    {expandibleSchema?.map((cell, index) => <TableCell
                                        sx={cell.sx}
                                        key={`ExpandCell${index}-item${row.id}`}
                                        align={cell.align ? cell.align : 'left'}
                                        padding={cell.padding ? cell.padding : 'none'}>
                                        {cell.label}
                                    </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {row.movements.map((movement: any, index: number) => {
                                        console.log(movement)
                                        return expandibleSchema?.map((cell, index) => <TableCell
                                            sx={cell.sx}
                                            key={`ExpandCell${index}-item${row.id}`}
                                            align={cell.align ? cell.align : 'left'}
                                            padding={cell.padding ? cell.padding : 'none'}>
                                            {cell.customComponent ? <cell.customComponent value={movement[cell.id]} row={movement} /> : movement[cell.id]}
                                        </TableCell>
                                        )



                                    }

                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>}

    </>
    );
}


export default RenvanceRow;