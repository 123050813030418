import {
  Box,
  Card,
  Grid
} from "@mui/material";
import WalletTable from "./WalletTable";
import WalletCard from "./WalletCard";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PageWrapper from "../Layout/PageWrapper";


const WalletPage = () => {


  return (
    <PageWrapper headerSize="small" nocard>
      <Grid container spacing={2} justifyContent={'center'} mt={2}>
        <WalletCard icon={<AccountBalanceWalletIcon />} label="Estimated Balance" amount={100} color='blue' />
        <WalletCard icon={<AccountBalanceWalletIcon />} label="Avialable Balance" amount={100} color='#08ad6c' />
        <WalletCard icon={<AccountBalanceWalletIcon />} label="Invested" amount={100} color='red' />

      </Grid>

      <Card sx={{ m: 2 }} elevation={2}>
        <Box sx={{ p: 3 }} >
          <WalletTable />
        </Box>
      </Card>

    </PageWrapper>
  );
};

export default WalletPage;
