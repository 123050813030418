import { Box, Icon, IconButton, Typography } from "@mui/material";
import FavouriteButton from "app/components/FavouriteButton";

const FeatureItem = ({ icon, value, title }: { icon: string, title: string, value: string }) => {
    return <>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton>
                <Icon>
                    {icon}
                </Icon>
            </IconButton>
            <div>
                <Typography variant="subtitle2" component="p">
                    {title}
                </Typography>
                <Typography variant="subtitle2" component="p">
                    {value}
                </Typography>


            </div>


        </Box>
    </>
}

// La dirección de la propiedad debe ir en 1.8rem
// Agregar ciudad y pais (más pequeño que la dirección)
// Si la propiedad esta en construcción va el mensaje en rojo (usar b61730) => Oportunidad: propiedad en construcción <barrio>
// Si la propiedad esta terminada el mensaje en verde => Inversion: propiedad en <barrio>


const PropertyHeader = ({ uuid, title, amenities, property }: { uuid: string, title: string, amenities?: any[], property:any }) => {
    console.log(property)
    title = property.name || property.address
    return (  <Box
        component="header"
        sx={{
            pt: '2rem',
            pb: '1rem',
            display: 'block',
            width: '100%',
            justifyContent: 'space-between',
        }}>
        <div>
            {property.economics.construction_type_code == "UNDER_CONSTRUCTION" && 
                    <Typography variant="title2" component="h1" style={{fontSize:'0.9rem',  color:'#b61730' }}>    
                            Oportunidad: Propiedad en construcción en Palermo, {property.state}  
                    </Typography>
            }
            {property.economics.construction_type_code != "UNDER_CONSTRUCTION" && 
                    <Typography variant="title2" component="h1" sx={{fontSize:'0.9rem',color:'green' }}>    
                            Inversion: Propiedad en Palermo, {property.state}  
                    </Typography>
            }
            
        </div>
        <div style={{ display: 'block' }}>
                <Typography variant="title2" component="h1" sx={{ fontSize:'1.8rem', mr: 2, color:'b61730' }}>    
                          {title}
                </Typography>
        </div>
        <div style={{  fontSize:'0.8rem'}}>
            <Typography variant="title2" component="h1" style={{fontSize:'0.8rem'}}>    
                    {property.address}, {property.state}, {property.country}  
            </Typography>
        </div>

        <div style={{ display: 'flex' }}>
            <div style={{ alignSelf: 'center' }}>
                <FavouriteButton propertyUUID={uuid} />
            </div>
        </div>
    </Box>);
}

export default PropertyHeader;