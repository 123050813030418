import { styled, keyframes } from "@mui/system";
import { LogoBig } from "assets/images";
import { FlexAlignCenter } from "./FlexBox";

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
`;

const textAppearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const dotsAnimation = keyframes`
  0%, 20%, 100% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
`;

const StyledLoading = styled(FlexAlignCenter)(() => ({
  width: "100%",
  height: "100%",
  background: `linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)`,
  backgroundSize: "200% 100%",

  "& #loading": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    animation: `${spinAnimation} 4s linear infinite, ${pulseAnimation} 3s ease-in-out infinite, ${bounceAnimation} 2s ease infinite, ${gradientAnimation} 4s linear infinite`,
  },
  "& h2": {
    position: "relative",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
    animation: `${textAppearAnimation} 1s ease-in-out forwards`,
    "&::after": {
      content: '""',
      width: "20px",
      animation: `${dotsAnimation} 1s steps(1) infinite`,
      position: "absolute",
      top: "0",
      right: "-22px",
    },
  },
}));

const RenvanceLoading = () => {
  return (
    <StyledLoading>
      <div id="loading">
        <img src={LogoBig} alt="Loading content..." />
        <h2>Loading</h2>
      </div>
    </StyledLoading>
  );
};

export default RenvanceLoading;