import { Button, Card, Grid, Icon, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

type Config = {
    accountNumber: string,
    alias: string,
    accountType: string,
    cbuAccount: string,
}

const BankTransfer = ({ config }: { config: Config }) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card sx={{ width: '100%', height: '100%', padding: '1rem' }} elevation={3}>
                    <Typography variant="title3" mb={1}>
                        Account Details
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                        Please transfer the funds to the following account:
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                        Account Number: {config.accountNumber}
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                        Alias: {config.alias}
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                        Account Type: {config.accountType}
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                        CBU Account: {config.cbuAccount}
                    </Typography>

                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card sx={{ width: '100%', height: '100%', padding: '1rem' }} elevation={3}>
                    <Typography variant="title3" component='p' mb={3}>
                        Complete your payment
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField label="Amount" variant="outlined" fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Operation Number" variant="outlined" fullWidth size="small" />
                        </Grid>
                        <Grid item xs={4}>
                            <Button component="label" variant="contained" startIcon={<Icon>document_scanner</Icon>}>
                                Ticket Scan
                                <VisuallyHiddenInput type="file" />
                            </Button>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField label="File"
                                variant="outlined"
                                disabled
                                fullWidth
                                size="small"
                                value="comprobante_mercado_pago_1231245124.png"
                            />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='flex-end'>
                            <Button variant="contained"
                                endIcon={<Icon>outbox</Icon>}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>

                </Card>
            </Grid>
        </Grid>
    )

}

export default BankTransfer;