import { Button, Dialog, DialogContent, Divider, Grid, Icon, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useState } from "react";
import { date, number, object, string } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";


const validationSchema = object().shape({
    propertyUUID: string().required("Property UUID is required"),
    propertyCode: string().required("Property Code is required"),
    statementDate: date().required("Statement Date is required"),
    description: string().required("Description is required"),
    filePathLink: string().required("File Path Link is required"),
    propertyName: string().required("Property Name is required"),
    id: number().required("ID is required"),
});

function ModalAddStatement({ open, onClose }: { open: any; onClose: any; }) {

    const [values, setValues] = useState({
        propertyUUID: "",
        propertyCode: "",
        statementDate: new Date(),
        description: "",
        filePathLink: "",
        propertyName: "",
        id: 0,
    });
    const [file, setFile] = useState<File>();
    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleCancel = () => {
        setFile(undefined);
        onClose();
    }
    const handleSubmit = async () => {
        try {
            await validationSchema.validate(values, { abortEarly: false });
            // Aquí puedes realizar la lógica de envío de datos, por ejemplo, una llamada API.
            // Si la validación es exitosa, los datos en "values" son válidos.
        } catch (error) {
            // La validación falló, puedes manejar los errores aquí.
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleCancel}
        >
            <DialogContent>
                <Typography variant="title2" component='p'>Add Statement</Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={1}>
                    <Grid item xs={12} md={file ? 5 : 12}>

                        <Grid container spacing={2} >
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Property Code"
                                    name="propertyCODE"
                                    onChange={handleChange}
                                    value={values.propertyUUID}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DatePicker
                                    label="Statement Date"
                                    value={values.statementDate}
                                    maxDate={new Date()}
                                    onChange={(newValue) => {
                                        setValues({
                                            ...values,
                                            statementDate: newValue as Date,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    onChange={handleChange}
                                    value={values.description}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" component="label"
                                    startIcon={<Icon>note_add</Icon>}
                                >
                                    {file ? <>Change Statement</> : <>Upload Statement</>}

                                    <input
                                        type="file"
                                        multiple={false}
                                        accept="application/pdf"
                                        hidden
                                        onChange={({ target }) =>
                                            target.files && setFile(target.files[0])
                                        }
                                    />

                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>
                    {file &&
                        <Grid item xs={12} md={7}>
                            <embed
                                src={URL.createObjectURL(file)}
                                width="100%"
                                height="100%"
                                type="application/pdf"
                                title="Preview Statement"
                                contentEditable={false}
                                style={{ minHeight: '600px' }} />

                        </Grid>
                    }
                    <Grid item xs={12}>
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            <span>Save</span>
                        </LoadingButton>
                        <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={handleCancel}>Cancel</Button>

                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog >
    );
}

export default ModalAddStatement;
