import { Box, Button, Grid, InputAdornment, Slider, TextField, Typography, styled } from "@mui/material";
import { formatToUSDValue } from "app/utils/utils";
import { useEffect, useState } from "react";


const TitleStyle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    padding: '1rem',
    color: 'dimgray'
}));

const ValueStyle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    padding: '1rem',
}));



const Actions = ({ onSellOrder, onBuyOrder }: { onSellOrder: (data: any) => void, onBuyOrder: (data: any) => void }) => {

    const available_buy = 2000
    const available_sell = 3000


    const [buyOrder, setBuyOrder] = useState({
        price: 0,
        amount: 0,
        total: 0,
        porcentage: 0
    })
    const [sellOrder, setSellOrder] = useState({
        price: 0,
        amount: 0,
        total: 0,
        porcentage: 0
    })




    useEffect(() => {
        const total = buyOrder.price * buyOrder.amount;
        const porcentage = Math.floor((total / available_buy) * 100);
        setBuyOrder({ ...buyOrder, total, porcentage })
    }, [buyOrder])

    useEffect(() => {
        const total = sellOrder.price * sellOrder.amount;
        const porcentage = Math.floor((total / available_sell) * 100);
        setSellOrder({ ...sellOrder, total, porcentage })
    }, [sellOrder])



    return (
        <Grid container display={'flex'} sx={{ p: '8px' }} >
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleStyle variant='subtitle1' sx={{ p: 1 }}>Next Payment  day </TitleStyle>
                    <ValueStyle variant='subtitle1' sx={{ p: 1 }}>  30/10/2021</ValueStyle>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleStyle variant='subtitle1' sx={{ p: 1 }}>Next Payment ReU$D</TitleStyle>
                    <ValueStyle variant='subtitle1' sx={{ p: 1 }}>  {formatToUSDValue(23)} </ValueStyle>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleStyle variant='subtitle1' sx={{ p: 1 }}>Current valuation</TitleStyle>
                    <ValueStyle variant='subtitle1' sx={{ p: 1 }}> {formatToUSDValue(1000)} </ValueStyle>
                </Box>
            </Grid>
            <Grid item xs={12} display={'flex'} >
                <Grid item xs={6} sx={{ pl: 1 }} aria-label="buy-order">
                    <TitleStyle variant='subtitle1' sx={{ p: 0, pl: 1 }}>
                        Avbl {available_buy} ReU$D
                    </TitleStyle>
                    <TextField
                        label="Price"
                        sx={{ m: 1, width: '100%' }}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">ReUSD</InputAdornment>,
                        }}
                        value={buyOrder.price}
                        onChange={(e) => {
                            setBuyOrder({ ...buyOrder, price: e.target.value as unknown as number })

                        }}


                    />
                    <TextField
                        label="Amount"
                        sx={{ m: 1 }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">BTC</InputAdornment>,
                        }}
                        fullWidth
                        type="number"
                        value={buyOrder.amount}
                        onChange={(e) => {
                            setBuyOrder({ ...buyOrder, amount: e.target.value as unknown as number })
                        }}

                    />
                    <Slider
                        size="small"
                        defaultValue={70}
                        aria-label="Small"
                        value={buyOrder.porcentage}
                        valueLabelDisplay="auto"
                        onChange={(event: Event, newValue: number | number[]) => {
                            const amount = Math.floor((available_buy / 100) * (newValue as number) / buyOrder.price);
                            setBuyOrder({
                                ...buyOrder, amount
                                , porcentage: newValue as number

                            })


                        }}

                    />
                    <TextField
                        label="Total"
                        sx={{ m: 1, width: '100%' }}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position="start">ReUSD</InputAdornment>,
                        }}
                        value={buyOrder.total}

                    />
                    <Button variant="contained" sx={{ m: 1, width: '100%' }} color='success' 
                    onClick={() => {
                        onBuyOrder({
                            price: buyOrder.price,
                            amount: buyOrder.amount,
                        })
                    }}
                    
                    >Buy</Button>
                </Grid>
                <Grid item xs={6} sx={{ pl: 1 }} aria-label="sell-order">
                    <TitleStyle variant='subtitle1' sx={{ p: 0, pl: 1 }}>
                        Avbl {available_sell} APT1
                    </TitleStyle>
                    <TextField
                        label="Price"
                        sx={{ m: 1 }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">ReUSD</InputAdornment>,
                        }}
                        fullWidth
                        type="number"
                        value={sellOrder.price}
                        onChange={(e) => {
                            setSellOrder({ ...sellOrder, price: e.target.value as unknown as number })
                        }}
                    />
                    <TextField
                        label="Amount"
                        sx={{ m: 1, width: '100%' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">BTC</InputAdornment>,
                        }}
                        fullWidth
                        type="number"
                        value={sellOrder.amount}
                        onChange={(e) => {
                            setSellOrder({ ...sellOrder, amount: e.target.value as unknown as number })
                        }}


                    />
                    <Slider
                        size="small"
                        defaultValue={70}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        value={sellOrder.porcentage}
                        onChange={(event: Event, newValue: number | number[]) => {
                            const amount = Math.floor((available_sell / 100) * (newValue as number) / sellOrder.price);
                            setSellOrder({
                                ...sellOrder, amount,
                                porcentage: newValue as number
                            })


                        }}
                    />
                    <TextField
                        label="Total"
                        sx={{ m: 1, width: '100%' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">ReUSD</InputAdornment>,
                        }}
                        disabled
                        value={sellOrder.total}

                    />
                    <Button variant="contained" sx={{ m: 1, width: '100%' }} color='error'
                        onClick={() => {
                            onSellOrder({
                                price: sellOrder.price,
                                amount: sellOrder.amount,
                            })
                        }}
                    
                    >Sell</Button>
                </Grid>
            </Grid>
        </Grid >);
}

export default Actions; <>ACTIONS</>