import { Divider, Paper, Typography } from "@mui/material";

const DividerWithPaperText = ({ text }: { text: string }) => {
    return (
        <Divider sx={{
            backgroundColor: 'transparent !important',
            padding: '1rem',
            '.MuiDivider-wrapper': {
                backgroundColor: 'transparent !important',
                zIndex: 1,
            }
        }}>
            <Paper elevation={5}
                sx={{
                    backgroundColor: '#FFF',
                    padding: '1rem',
                    borderRadius: '20rem',
                    width: '25rem',
                    margin: 'auto',
                    textAlign: 'center',

                }}>
                <Typography variant='title3'>
                    {text}
                </Typography>

            </Paper>
        </Divider>

    );
}

export default DividerWithPaperText;