import { createApi } from '@reduxjs/toolkit/query/react';
import { PathApis } from 'config';
import { ICompany, PaginationObject } from 'app/redux/models';
import { axiosBaseQuery, buildHeaders } from './baseApi';







export const companiesApi = createApi({
    reducerPath: 'companiesApi',
    baseQuery: axiosBaseQuery({ baseUrl: PathApis.gatewayPath }),
    tagTypes: ['Companies'],
    endpoints: ({ query, mutation }) => ({
        registerCompany: mutation<any, { data?: any, token: string }>({
            query: ({ data, token }) => ({
                url: '/bo/companies/register',
                method: 'POST',
                data: data,
                headers: buildHeaders(token)
            }),
            invalidatesTags: ['Companies'],
        }),
        getAllCompanies: query<PaginationObject<ICompany>, { params?: any, token: string }>({
            query: ({ params, token }) => ({
                url: '/bo/companies/query',
                method: 'GET',
                params,
                headers: buildHeaders(token)
            }),
            providesTags: ['Companies'],
        }),
        getPlans: query<any, { token: string }>({
            query: ({ token }) => ({
                url: '/bo/companies/plans',
                method: 'GET',
                headers: buildHeaders(token)
            }),
            providesTags: ['Companies'],
        }),
        validateRegisterCompany: mutation<any, { params: { company_name?: string, company_email?: string, company_address?: string, company_phone?: string }, token: string }>({
            query: ({ params, token }) => ({
                url: '/bo/companies/register-validefields',
                method: 'GET',
                params,
                headers: buildHeaders(token)
            }),
        }),
        getCompanyUsers: query<any, { token: string, company: string }>({
            query: ({ token, company }) => ({
                url: `/bo/companies/${company}/users`,
                method: 'GET',
                headers: buildHeaders(token)
            }),
        }),
        getCompanyNames: query<PaginationObject<{ name: string, id: string }>, { token: string, params: { page?: number, per_page?: number } }>({
            query: ({ token, params }) => ({
                url: '/bo/companies/names',
                method: 'GET',
                headers: buildHeaders(token),
                params
            }),

        })
    }),
    keepUnusedDataFor: 3600,
});

export const {
    useRegisterCompanyMutation,
    useGetAllCompaniesQuery,
    useGetPlansQuery,
    useValidateRegisterCompanyMutation
} = companiesApi;