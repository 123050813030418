import { Box } from "@mui/material";
import MatxCalendar from "./Calendar/AvalCalendar";

const BookingPage = () => {
  return (

    <Box>
      <MatxCalendar />



    </Box>
  );
};

export default BookingPage;
