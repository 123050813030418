import { Box, Button, Divider, Grid, Stack, TextField } from "@mui/material";
import { H6, Paragraph } from "app/components/Typography";
import { useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';





const DeleteAccount = () => {
  const [deleteText, setDeleteText] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const steps = ['Notify Users', 'Distribute Earnings & Stop Trading', 'Buy Token Back']
  return (
    <>
      
      <Box sx={{ p: 3 }}>
        <Paragraph sx={{ maxWidth: '600px' }}>
          This action will remove the property from the portfolio. It can not be undone.
          For the Removal process to be triggered write delete property
        </Paragraph>
      </Box >

      <Divider />

      <Stack direction="row" alignItems="center" spacing={1} paddingTop={2} paddingLeft={2}>
        <H6 sx={{ fontSize: '12' }}>Write  Delete Property.</H6>
      </Stack>

      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={10}>
          <TextField
            sx={{ p: 2 }}
            disabled={isDelete}
            fullWidth
            label="Delete Property"
            variant="outlined"
            placeholder="Write Delete Property"
            value={deleteText}
            onChange={(e) => setDeleteText(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2} alignSelf={'center'}>
          <Button size='large' variant="contained"
            color="error"
            disabled={deleteText.toLocaleLowerCase().trim() !== 'delete property'}
            sx={{ textAlign: 'center' }}
            onClick={() => setIsDelete(true)}
          >
            Delete
          </Button>
        </Grid>
      </Grid>

      {
        isDelete && (
          <Stepper alternativeLabel activeStep={0} sx={{ paddingTop: '2rem' }} >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

        )
      }



    </ >
  );
};

export default DeleteAccount;
