import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

//! Ver que devuelve la api.
interface IHomeStatsSlice {
  error: any;
  statistics: any;
  loading: boolean;
}

const initialState: IHomeStatsSlice = {
  error: null,
  statistics: {
    squares: [],
    rectangles: []
  },
  loading: false,
};

export const getHomeStatsSummary = createAsyncThunk("statistics/get", async () => {
  const res = await axios.get("/api/pages/home/statistics-home-summary");
  return res.data;
});

const homeStatsSlice = createSlice({
  initialState,
  name: "statistics",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeStatsSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeStatsSummary.fulfilled, (state, actions) => {
        state.loading = false;
        state.statistics = actions.payload;
      })
      .addCase(getHomeStatsSummary.rejected, (state, actions) => {
        state.loading = false;
        state.statistics = [];
        state.error = actions.error.message;
      });
  },
});

export const homeStatsReducer = homeStatsSlice.reducer;
