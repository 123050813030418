import * as React from "react";
import * as ReactDOM from "react-dom";
import { Box, Button, styled } from "@mui/material";
import { convertHexToRGB } from "app/utils/utils";
import { format, getDay, parse, startOfWeek } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { useEffect, useRef, useState } from "react";
import { Calendar, dateFnsLocalizer, Views, Event } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import CalendarHeader from "./CalendarHeader";
import { getAllEvents, updateEvent } from "./CalendarService";
import EventEditorDialog from "./EventEditorDialog";


const CalendarRoot = styled("div")(({ theme }) => ({
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    "& .rbc-event": {
        background: `rgba(${convertHexToRGB(theme.palette.primary.main)},1)`,
    },
    "& .rbc-selected": {
        background: `rgba(${convertHexToRGB(theme.palette.secondary.main)},1)`,
    },
    "& .rbc-calendar": { height: "auto", flexGrow: 1 },
    "& .rbc-header": {
        padding: "12px 16px !important",
        "& a": { paddingBottom: "8px !important" },
        "& span": { fontSize: "15px !important", fontWeight: 500 },
    },
}));

const locales = { "en-US": enUS };
const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });

const DragAndDropCalendar = withDragAndDrop(Calendar);
//@ts-ignore
let viewList = Object.keys(Views).map((key) => Views[key]);

const MatxCalendar: React.FC = () => {
    const headerComponentRef = useRef<HTMLDivElement>(null);
    const [events, setEvents] = useState<Event[]>([]);
    const [newEvent, setNewEvent] = useState<Event | null>(null);
    const [shouldShowEventDialog, setShouldShowEventDialog] = useState(false);

    const updateCalendar = () => {
        getAllEvents()
            .then((res: any) => res.data)
            .then((events: any) => {
                //@ts-ignore
                events = events?.map((e: Event) => ({ ...e, start: new Date(e.start), end: new Date(e.end) }));
                setEvents(events);
            });
    };

    const handleDialogClose = () => {
        setShouldShowEventDialog(false);
        updateCalendar();
    };

    const handleEventMove = (event: Event) => handleEventResize(event);

    const handleEventResize = (event: Event) => {
        updateEvent(event).then(() => updateCalendar());
    };

    const openNewEventDialog = ({ action, ...event }: { action: any, event: Event, start: any, end: any }) => {
        if (action === "doubleClick") {
            setNewEvent(event);
            setShouldShowEventDialog(true);
        }
    };

    const openExistingEventDialog = (event: Event) => {
        setNewEvent(event);
        setShouldShowEventDialog(true);
    };

    useEffect(() => {
        updateCalendar();
    }, []);

    return (
        <>
            <CalendarRoot>
                <Box ref={headerComponentRef} />
                <DragAndDropCalendar
                    step={60}
                    resizable
                    selectable
                    events={events}
                    views={viewList}
                    //@ts-ignore
                    endAccessor="end"
                    showMultiDayTimes
                    localizer={localizer}
                    //@ts-ignore
                    startAccessor="start"
                    defaultDate={new Date()}
                    defaultView={Views.MONTH}
                    //@ts-ignore
                    onEventDrop={handleEventMove}
                    //@ts-ignore
                    onEventResize={handleEventResize}
                    components={{
                        toolbar: (props: any) => {
                            return headerComponentRef.current ? (
                                ReactDOM.createPortal(<CalendarHeader {...props} />, headerComponentRef.current)
                            ) : (
                                <div>Header component not found</div>
                            );
                        },
                    }}
                    // onNavigate={handleNavigate}
                    onSelectEvent={(event: Event) => openExistingEventDialog(event)}
                    onSelectSlot={(slotDetails: any) => openNewEventDialog(slotDetails)}
                />
            </CalendarRoot>

            {shouldShowEventDialog && (
                <EventEditorDialog
                    event={newEvent}
                    open={shouldShowEventDialog}
                    handleClose={handleDialogClose}
                />
            )}

            <Box aria-label='actions'
                sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() =>
                        //@ts-ignore
                        openNewEventDialog({ action: "doubleClick", start: new Date(), end: new Date() })
                    }
                >
                    Book
                </Button>
            </Box>
        </>
    );
};

export default MatxCalendar;
