import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";
const PucharseChart = () => {

    const today = new Date();

    const series = [
        {
            name: 'Función 1',
            data: [
                [moment(today).date(1).month(1).year(2021).valueOf(), 10],
                [moment(today).date(2).month(1).year(2021).valueOf(), 15],
                [moment(today).date(3).month(1).year(2021).valueOf(), 8],
                [moment(today).date(4).month(1).year(2021).valueOf(), 12],
                [moment(today).date(5).month(1).year(2021).valueOf(), 9],
                [moment(today).date(6).month(1).year(2021).valueOf(), 10]
            ]
        },
        {
            name: 'Función 1_2',
            data: [
                [moment(today).date(1).month(2).year(2021).valueOf(), 10],
                [moment(today).date(2).month(2).year(2021).valueOf(), 15],
                [moment(today).date(5).month(2).year(2021).valueOf(), 8],
                [moment(today).date(6).month(2).year(2021).valueOf(), 12],
                [moment(today).date(12).month(2).year(2021).valueOf(), 9],
                [moment(today).date(15).month(2).year(2021).valueOf(), 10]
            ]
        },
        {
            name: 'Función 2',
            data: [
                [moment(today).date(1).month(6).year(2021).valueOf(), 5],
                [moment(today).date(1).month(7).year(2021).valueOf(), 7],
                [moment(today).date(1).month(8).year(2021).valueOf(), 11],
                [moment(today).date(1).month(9).year(2021).valueOf(), 6],
                [moment(today).date(1).month(10).year(2021).valueOf(), 13],
                [moment(today).date(1).month(11).year(2021).valueOf(), 8]
            ]
        },
        {
            name: 'Función 3',
            data: [
                [moment(today).date(1).month(0).year(2021).valueOf(), 3],
                [moment(today).date(1).month(1).year(2021).valueOf(), 6],
                [moment(today).date(1).month(2).year(2021).valueOf(), 9],
                [moment(today).date(1).month(3).year(2021).valueOf(), 15],
                [moment(today).date(1).month(4).year(2021).valueOf(), 7],
                [moment(today).date(1).month(5).year(2021).valueOf(), 12]
            ]
        }
    ];


    const options: ApexOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            animations: {
                enabled: false
            }
        },
        stroke: {
            width: [2, 2, 2],
            curve: 'straight'
        },
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
        title: {
            text: 'Market Graph'
        },
        xaxis: {
            type: 'datetime',
            title: {
                text: 'Time'
            }
        },
        yaxis: [
            {
                title: {
                    text: 'Value'
                }
            }
        ],
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true,
            borderColor: '#ebebeb',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        annotations: {
            xaxis: [

                {
                    x: 11,
                    borderColor: '#000',
                    strokeDashArray: 0,

                }
            ]
        }
    };
    return (
        <ReactApexChart options={options} series={series} type="line" height={350} style={{padding: "1.5rem 1rem"}}/>
    );
}

export default PucharseChart;