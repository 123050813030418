import { Box, Typography } from "@mui/material";
import PageWrapper from "../../Layout/PageWrapper";
import BookTable from "./BookTable";

const BookStayingPage = () => {
    return (
        <PageWrapper>

            <Typography variant="title1">Book Staying</Typography>
            <Box sx={{ p: 3 }} >
                <BookTable />
            </Box>



        </PageWrapper>);
}

export default BookStayingPage;