import { Container, SxProps } from "@mui/material";

const PageContainer = ({ children, id = '', sx, ariaLabel, component = 'div' }: { children?: any, id?: string, sx?: SxProps<any>, ariaLabel?: string, component?: string }) => {

    return (
        <Container
            component={component as any}
            aria-label={ariaLabel}
            sx={sx}
            id={`page-container${id}`}
            maxWidth="lg">
            {children}
        </Container>

    );
}

export default PageContainer;