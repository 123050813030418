import { Button, Icon, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { InvestmentType, Property } from "app/redux/models";
import { Fragment } from "react";
import GridCardsContainer from "../components/GridCardsContainer";
import PageContainer from "../components/PageContainer";
import FilterBar from "./FilterBar";
import InvestCard from "./InvestCard";

const dummyData: Partial<Property[]> = [
    {
        "property_uuid": "1",
        "property_code": "P001",
        "building_type": "Residential",
        "name": "Example Property 1",
        "address": "Avenixa Rivadavia 51111",
        "city": "Exampleville",
        "dorms": 3,
        "bathrooms": 2,
        "parking_lots": 1,
        "state": "Example State",
        "country": "Example Country",
        "zipcode": "12345",
        "description": "This is an example property description.",
        "amenities": ["Swimming Pool", "Gym", "Parking"],
        "pictures": ["https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"],
        "balance": 1000000,
        "surface": 200,
        "floor": 5,
        "apartment": "A",
        "economics": {
            "Construction_type_code": "CT001",
            "active_buying": true,
            "active_renting": false,
            "active_selling": true,
            "cf_interest": 5,
            "cf_type": "Cash Flow",
            "created_at": "2023-01-01",
            "current_market_asset_value": 1200000,
            "expected_net_yearly_rent_percentage": 4,
            "fund_raising_end": "2023-06-30",
            "fund_raising_start": "2023-01-01",
            "id": 1,
            "investment_type": InvestmentType.CROWD_FUNDING,
            "re_appreciation_period": "5 years",
            "construction": {
                "asset_value": 1500000,
                "asset_value_at_completed": 1200000,
                "expected_asset_value": 1800000,
                "construction_completed": "2023-01-01",
                "construction_started": "2022-01-01",
                "construction_status": "Completed"
            },
            "current_market_value": 1200000,
            "current_reference_value": 1200000,
            "estimation": {
                "cost_apartment_expenses": 1000,
                "cost_building_expenses": 5000,
                "cost_insurance": 2000,
                "cost_taxes": 3000,
                "gross_rent_yearly_percentage": 8,
                "gross_rent_yearly_raw": 96000,
                "net_rent_yearly_percentage": 6,
                "occupancy": 90,
                "platform_management_commission_percentage": 2,
                "platform_management_commission_raw": 1920,
                "property_management_commission_percentage": 4,
                "property_management_commission_raw": 3840
            },
            "minimal_permanency_period": "1 year",
            "plan_code": "P001",
            "rent_distribution_period": "Monthly",
            "total_investment": {
                "administrative_fees": 10000,
                "decoration_n_furniture": 5000,
                "initial_asset_value": 1200000,
                "platform_buy_commision_amount": 30000,
                "property_buy_commission_amount": 20000
            }
        },
        "token": {
            "token_available": 100000,
            "active": true,
            "deployment_date": "2023-01-01",
            "id": 1,
            "modified_at": "2023-01-01",
            "token_address": "0x123ABC",
            "token_amount": 100000,
            "token_description": "Example token description",
            "token_in_market": 100000,
            "token_name": "Example Token",
            "token_price": 10,
            "token_ticker": "T001",
            "updated_by": "admin",
            "uuid": "abc123"
        },
        "manager": {
            "company_name": "Example Company",
            "contact_name": "John Doe",
            "email": "john@example.com",
            "phone": "123-456-7890",
            "id": 1
        }
    },
    {
        "property_uuid": "2",
        "property_code": "P001",
        "building_type": "Residential",
        "name": "Example Property 1",
        "address": "123 Main St",
        "city": "Exampleville",
        "dorms": 3,
        "bathrooms": 2,
        "parking_lots": 1,
        "state": "Example State",
        "country": "Example Country",
        "zipcode": "12345",
        "description": "This is an example property description.",
        "amenities": ["Swimming Pool", "Gym", "Parking"],
        "pictures": ["https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"],
        "balance": 1000000,
        "surface": 200,
        "floor": 5,
        "apartment": "A",
        "economics": {
            "Construction_type_code": "CT001",
            "active_buying": true,
            "active_renting": false,
            "active_selling": true,
            "cf_interest": 5,
            "cf_type": "Cash Flow",
            "created_at": "2023-01-01",
            "current_market_asset_value": 1200000,
            "expected_net_yearly_rent_percentage": 4,
            "fund_raising_end": "2023-06-30",
            "fund_raising_start": "2023-01-01",
            "id": 1,
            "investment_type": InvestmentType.CROWD_FUNDING,
            "re_appreciation_period": "5 years",
            "construction": {
                "asset_value": 1500000,
                "asset_value_at_completed": 1200000,
                "expected_asset_value": 1800000,
                "construction_completed": "2023-01-01",
                "construction_started": "2022-01-01",
                "construction_status": "Completed"
            },
            "current_market_value": 1200000,
            "current_reference_value": 1200000,
            "estimation": {
                "cost_apartment_expenses": 1000,
                "cost_building_expenses": 5000,
                "cost_insurance": 2000,
                "cost_taxes": 3000,
                "gross_rent_yearly_percentage": 8,
                "gross_rent_yearly_raw": 96000,
                "net_rent_yearly_percentage": 6,
                "occupancy": 90,
                "platform_management_commission_percentage": 2,
                "platform_management_commission_raw": 1920,
                "property_management_commission_percentage": 4,
                "property_management_commission_raw": 3840
            },
            "minimal_permanency_period": "1 year",
            "plan_code": "P001",
            "rent_distribution_period": "Monthly",
            "total_investment": {
                "administrative_fees": 10000,
                "decoration_n_furniture": 5000,
                "initial_asset_value": 1200000,
                "platform_buy_commision_amount": 30000,
                "property_buy_commission_amount": 20000
            }
        },
        "token": {
            "token_available": 100000,
            "active": true,
            "deployment_date": "2023-01-01",
            "id": 1,
            "modified_at": "2023-01-01",
            "token_address": "0x123ABC",
            "token_amount": 100000,
            "token_description": "Example token description",
            "token_in_market": 100000,
            "token_name": "Example Token",
            "token_price": 10,
            "token_ticker": "T001",
            "updated_by": "admin",
            "uuid": "abc123"
        },
        "manager": {
            "company_name": "Example Company",
            "contact_name": "John Doe",
            "email": "john@example.com",
            "phone": "123-456-7890",
            "id": 1
        }
    }
]


const video_url = "https://www.savills.com/_images/cinemagraphs/COMb.mp4"

const InvestPage = () => {

    return (
        <Fragment>

            <header style={{ position: 'relative', height: '30vh' }}>
                <PageContainer sx={{ position: 'absolute', left: '50%', bottom: 0, transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <Typography variant="title1" component="h1" style={{ textAlign: 'center', color: 'white' }}>
                        Buscá las mejores oportunidades para invertir!
                    </Typography>
                    <Paper elevation={3} style={{ padding: '0rem', margin: '0 auto', marginTop: '1rem', display: 'flex', width: 'fit-content' }}>
                        <Button variant="text" color="primary" sx={{ borderRadius: '2rem' }}>
                            <div style={{ padding: '1rem' }} >
                                <Typography textAlign='start' fontWeight='500'>
                                    Lugar
                                </Typography>
                                <Typography textAlign='start'>
                                    Explorar destinos
                                </Typography>
                            </div>
                        </Button>
                        <Button variant="text" color="primary" sx={{ borderRadius: '2rem' }}>
                            <div style={{ padding: '1rem' }} >
                                <Typography textAlign='start' fontWeight='500'>
                                    Precio
                                </Typography>
                                <Typography textAlign='start'>
                                   Desde.. hasta..
                                </Typography>
                            </div>
                        </Button>
                        <Button variant="text" color="primary" sx={{ borderRadius: '2rem' }}>
                            <div style={{ padding: '1rem' }} >
                                <Typography textAlign='start' fontWeight='500'>
                                    Estado
                                </Typography>
                                <Typography textAlign='start'>
                                    Terminada? Solo primer Piso?
                                </Typography>
                            </div>
                        </Button>
                        <Button variant="text" color="primary" sx={{ borderRadius: '2rem' }}>
                            <div style={{ padding: '1rem' }} >
                                <Typography textAlign='start' fontWeight='500'>
                                    Tipo de propiedad
                                </Typography>
                                <Typography textAlign='start'>
                                    Hoteles.. cabañas..
                                </Typography>
                            </div>
                        </Button>
                        <Box aria-label="search" sx={{ display: 'flex', flexGrow: '1', alignSelf: 'center' }}>
                            <IconButton sx={{ height: 'fit-content', padding: '2rem' }}>
                                <Icon>search</Icon>
                            </IconButton>
                        </Box>
                    </Paper>
                </PageContainer>

                <video autoPlay muted loop id="myVideo" style={{ filter: 'brightness(0.8)', maxHeight: '100%', width: '100%', height: 'inherit', objectFit: 'cover' }}>
                    <source src={video_url} type="video/mp4" />
                </video>
            </header>
            <section id="properties-to-invest">
                <PageContainer>
                    <nav id="filters" style={{ marginTop: '1rem', display: 'flex', height: '3rem' }}>
                        <FilterBar />  
                    </nav>

                    <GridCardsContainer>
                        <InvestCard investData={dummyData[0] as Property} />
                        <InvestCard investData={dummyData[1] as Property} />
                        <InvestCard investData={dummyData[0] as Property} />
                        <InvestCard investData={dummyData[1] as Property} />
                        <InvestCard investData={dummyData[0] as Property} />
                        <InvestCard investData={dummyData[1] as Property} />
                        <InvestCard investData={dummyData[0] as Property} />
                    </GridCardsContainer>
                </PageContainer>

            </section>
        </Fragment>
    );
}

export default InvestPage;