import MenuIcon from '@mui/icons-material/Menu';
import { Fade, Icon, ListItemIcon, ListItemText, Paper, Skeleton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthModal from 'app/components/Modals/AuthModal/AuthModal';
import useAuth from 'app/hooks/useAuth';
import { UserRole } from 'app/redux/models';
import { isAgency, isGuest, isInvestor, isRenvance } from 'app/utils/utils';
import { LogoBrand } from 'assets/images';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageContainer from '../main-pages/components/PageContainer';

const pages = [
    {
        name: 'Home',
        path: '/',
        disabled: false
    },
    {
        name: 'Alquilar',
        path: '/rent'
    },
    {
        name: 'Invertir',
        path: '/invest',
        disabled: false
    },
    {
        name: 'Como funciona',
        path: '/how-it-works'
    }
]


interface UserButtonProps {
    user: any,
    logout: any,
    isInitialised: boolean,
    handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void,
    anchorElUser: HTMLElement | null,
    handleCloseUserMenu: () => void,
    userPages: { name: string, path: string, icon: string, action?: any }[],
    navigate: any
    onClick: (action: 'login' | 'register') => void
}

const UserButton = ({ user, logout, isInitialised, handleOpenUserMenu, anchorElUser, handleCloseUserMenu, userPages, navigate, onClick }: UserButtonProps) => {
    if (!isInitialised) return <>
        <Skeleton variant='circular' width='10.18rem' animation='wave' height={50} sx={{ borderRadius: '2rem' }} />
    </>


    const loginPages = [
        {
            name: 'Login',
            path: '/session/signin',
        },
        {
            name: 'Registrar',
            path: '/session/signup',
        },

    ]


    return user ? (
        <Fade in={isInitialised} timeout={1000}>
            {<Paper elevation={user ? 3 : 0} sx={{ borderRadius: '2rem' }}>
                <Box
                    aria-label='user'
                    sx={{ display: 'flex', alignItems: 'center', p: '5px', ml: '9px' }}
                >
                    <Typography variant='body1' color='darkgray' mr='1rem'
                        sx={{ display: { md: 'flex' } }}
                    >
                        {user?.displayName}
                    </Typography>
                    <Box sx={{ flexGrow: 0 }}>

                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt={user?.email?.toString()} src={user?.photoURL?.toString()} />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="mobile-acc"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                sx: {
                                    borderRadius: '1.5rem',
                                }
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >

                            {userPages.map(({ icon, path, name, action }) => (
                                <MenuItem
                                    key={name + path}
                                    onClick={() => {
                                        action ? action() : navigate(path)
                                        handleCloseUserMenu()
                                    }}>
                                    <ListItemIcon>
                                        <Icon fontSize='small'>
                                            {icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText>{name}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Box>
            </Paper>}
        </Fade>
    ) : <Box display='flex' columnGap='10px'>
        <Button sx={{ fontSize: "1rem"}} onClick={() => {
            onClick('login')
        }} >
            {loginPages[0].name}
        </Button>
        {/* <Button variant="contained" //? DISABLE REGISTER BUTTON https://github.com/renvance-com/renvance-webapp/pull/7
            onClick={() => {
                onClick('register')
            }}
            sx={{
                backgroundColor: 'limegreen',
                '&:hover': {
                    backgroundColor: '#0fa90f  !important'
                }
            }}>
            {loginPages[1].name}
        </Button> */}

    </Box >
}

function Header() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const { user, logout, isInitialised } = useAuth()
    const authModalState = useState({
        open: false,
        type: 'login' as 'login' | 'register'
    })

    const [userPages, setUserPages] = useState<{ name: string, path: string, icon: string, action?: any }[]>([]);


    useEffect(() => {
        if (!user) {
            return setUserPages([])
        }
        else {
            const role: UserRole = user.role;
            const lastIndex = userPages.length - 1;
            let pageOnRol: any = []
            if (isInvestor(role) || isGuest(role)) {
                pageOnRol = [{
                    name: 'Profile',
                    path: `/profile${isGuest(role) ? '/identification' : ''}`,
                    icon: 'person'
                }]
            }
            else if (isAgency(role)) {
                pageOnRol = [{
                    name: 'Agency',
                    path: '/company/home',
                    icon: 'dashboard'
                }]
            }
            else if (role === UserRole.GUEST) {
                userPages.splice(lastIndex, 0, {
                    name: 'Verification',
                    path: '/profile/verification',
                    icon: 'fingerprint'
                });
                setUserPages([...userPages])
            }
            else if (isRenvance(role)) {
                pageOnRol = [{
                    name: 'Manage',
                    path: '/company/home',
                    icon: 'settings'
                }]
            }
            pageOnRol.push({
                name: 'Logout',
                path: '/logout',
                icon: 'logout',
                action: logout,
            })
            setUserPages([...pageOnRol])


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation()
    const navigator = useNavigate()


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (

        <>
            <AuthModal state={authModalState} />
            <AppBar sx={{ backgroundColor: 'white !important', boxShadow: '0', py: '10px', borderRadius: '0px' }} position='sticky' >
                <PageContainer>
                    <Toolbar sx={{ padding: 0, justifyContent: 'space-between' }} disableGutters>
                        <img
                            alt='logo'
                            src={LogoBrand}
                            style={{ width: '12rem', display: isMobile ? 'none' : 'block', cursor: 'pointer' }}
                            onClick={() => navigate('/')}
                        />
                        <Box aria-label='mobile-menu' sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, maxInlineSize: 'fit-content' }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map(({ name, path }) => (
                                    <MenuItem key={name} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{name}ewew</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <img
                            alt='logo'
                            src={LogoBrand}
                            style={{ width: '12rem', display: isMobile ? 'flex' : 'none', justifyContent: 'center' }}
                        />
                        <Box aria-label='menu' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                            {pages.map(({ name, path, disabled }) => (
                                <Button
                                    disabled={disabled}
                                    key={name}
                                    onClick={() => {
                                        navigator(path)
                                    }}
                                    variant="text"
                                    sx={{
                                        my: 2, display: 'block',
                                        ...location.pathname === path && {
                                            borderBottom: 'solid',
                                            borderRadius: 0,
                                            borderBottomStyle: 'double',
                                        }
                                    }}


                                >
                                    <Typography variant="subtitle2" sx={{ fontSize: "1.1rem",margin: '0 0.5rem' }}>{name}</Typography>
                                </Button>
                            ))}
                        </Box>
                        {/* Hay que cambiar en el hover que las letras sean verdes o probar alguna otra combinación,  
                    pero quiero romper la armonia de colores */}
                        {user && !isAgency(user.role) && !isRenvance(user.role) && <Button

                            size='medium'
                            variant="contained"
                            onClick={() => navigate('/deposit')}
                            sx={{
                                mr: 2,
                                backgroundColor: location.pathname === '/deposit' ? '#0fa90f' : 'limegreen',
                                '&:hover': {
                                    backgroundColor: '#0fa90f  !important'
                                }
                            }}>
                            Deposit
                        </Button>}
                        <UserButton
                            onClick={(action) => {
                                authModalState[1]({ open: true, type: action })
                            }}
                            user={user}
                            logout={logout}
                            isInitialised={isInitialised}
                            handleOpenUserMenu={handleOpenUserMenu}
                            anchorElUser={anchorElUser}
                            handleCloseUserMenu={handleCloseUserMenu}
                            userPages={userPages}
                            navigate={navigate} />
                    </Toolbar>
                </PageContainer>
            </AppBar >
        </>
    );
}
export default Header;

