import { Box } from "@mui/material";
import OBContent from "./OBContent";
import OBHeader from "./OBHeader";

export enum OrderType {
    BIDS,
    ASKS
}

const OrderBook = () => {

    return (<Box display={'flex'} flexDirection={'column'}>
        <OBHeader />
        <OBContent />

    </Box>);
}

export default OrderBook;