import Typography from '@mui/material/Typography';
import CardProperty from 'app/components/cards/CardProperty';
import { formatToUSDValue } from 'app/utils/utils';
import dayjs from 'dayjs';


const RentCard = ({ rentData }: { rentData?: any }) => {

    const content = () => {
        return <div>
            <Typography gutterBottom variant="title3" component="div" fontSize='initial'>
                {rentData.propertyName}
            </Typography>
            <Typography variant="subtitle3" color="text.secondary">
                {rentData.propertyDirection}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {dayjs(rentData.available_start).get('D')} al {dayjs(rentData.available_end).get('D')} de {dayjs(rentData.available_end).format('MMM')}.
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{
                fontWeight: 'bold'
            }} >
                {formatToUSDValue(rentData.price_per_night)} USD por noche
            </Typography>
        </div>
    }

    return <CardProperty image={rentData.image} uuid={rentData.property_uuid} content={content()} />

};

export default RentCard;
