import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, Typography, Icon } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import PhoneFormik from 'app/components/FormikUI/PhoneFormik';
import TextFieldFormik from 'app/components/FormikUI/TextFieldFormik';
import useCaptcha from 'app/hooks/useCaptcha';
import useContract from 'app/hooks/useContract';
import { MetamaskIcon, backIdCard, frontIdCard } from 'assets/images';
import { googleConfig } from 'config';
import { useFormik } from 'formik';
import { matchIsValidTel } from 'mui-tel-input';
import { useSnackbar } from 'notistack';
import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { object, string } from 'yup';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const validationSchema = object({
    name: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required name'),
    lastname: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required lastname'),
    phone_number: string().required('Required').test('is-valid-tel', 'Invalid phone number', (v) => matchIsValidTel(v)),
    passport: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required passport'),
    country: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required country'),
    state: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required state'),
    address: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required address'),
    zipcode: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required zipcode'),
});


const VerificationForm = ({ onVerify }: { onVerify: (formData: any, captcha_token: string) => Promise<void> }) => {

    const [loading, setLoading] = useState(false)

    const [frontId, setFrontId] = useState<File>()
    const [backId, setBackId] = useState<File>()
    const [selfie, setSelfie] = useState<File>()
    const { enqueueSnackbar } = useSnackbar();
    const { executeCaptcha } = useCaptcha();

    const { ConnectWallet, currentAccount } = useContract();


    const handleSubmit = async (formikValues: any) => {
        await validationSchema.validate(formikValues)



        if (!frontId || !backId) {
            enqueueSnackbar('Please upload your ID/Passport', { variant: 'error', preventDuplicate: true })
            return
        }

        if (!selfie) {
            enqueueSnackbar('Please upload a selfie', { variant: 'error', preventDuplicate: true })
            return
        }


        const formData = new FormData();
        Object.entries(formikValues).forEach(([key, value]) => {
            formData.append(key, value as string);
        })


        formData.append('official_id_frontside_photo', frontId.name)
        formData.append('official_id_backside_photo', backId.name)
        formData.append('selfie_photo', selfie.name)
        formData.append('wallet_address', currentAccount || '')


        try {
            setLoading(true)
            const captcha_token = await executeCaptcha('VERIFY_INVESTOR')
            await onVerify(formData, captcha_token)
            // enqueueSnackbar('Please verify the captcha', { variant: 'error', preventDuplicate: true })
        }
        finally {
            setLoading(false)
        }
    }



    const formik = useFormik({
        initialValues: {
            name: 'James P',
            lastname: 'Sullivan',
            phone_number: '+54 9 1156958336',
            passport: 'EXD46721',
            country: 'AR',
            state: 'Monstropolis',
            address: '324 Scare Road, Monstropolis',
            zipcode: '80024'
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            handleSubmit(values)
        },
    });
    return (<>
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextFieldFormik
                            name="name"
                            label="First Name"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldFormik
                            name="lastname"
                            label="Last Name"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneFormik
                            defaultCountry={'AR'}
                            onlyCountries={['AR', 'BR', 'US']}
                            name="phone_number"
                            label="Phone"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldFormik
                            name="passport"
                            label="ID/Passport"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldFormik
                            name="country"
                            label="Country"
                            type="text"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldFormik
                            name="state"
                            label="State"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldFormik
                            name="address"
                            label="Address"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldFormik
                            name="zipcode"
                            label="Zip Code"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="inp-wallet" size='small'
                                shrink={currentAccount ? true : false} >Wallet (Optional)</InputLabel>
                            <OutlinedInput
                                fullWidth
                                size='small'
                                id="inp-wallet"
                                type='text'
                                value={currentAccount || ''}
                                sx={{ p: 0 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button
                                            sx={{ borderWidth: 0, '&:hover': { borderWidth: 0 } }}
                                            startIcon={<img src={MetamaskIcon} width='30px' alt="metamask-icon" />}
                                            variant="outlined" onClick={() => {
                                                ConnectWallet()
                                            }}>
                                            {currentAccount ? 'Connected' : 'Connect'}
                                        </Button>
                                    </InputAdornment>
                                }
                                label="Wallet (Optional)"
                            />
                            <FormHelperText>
                                if you don't have a wallet, no worries, one will be created for you
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" component='p' fontSize='1.25rem'>
                            Upload your ID/Passport
                        </Typography>

                    </Grid>
                    <Grid item xs={6} aria-label='front' >

                        <Button size='small' sx={{ mb: 2 }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Front Side
                            <VisuallyHiddenInput type="file"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        setFrontId(e.target.files[0])
                                    }
                                }}
                            />
                        </Button>
                        <Box component='img'
                            src={frontId ? URL.createObjectURL(frontId) : frontIdCard}
                            alt="Front Id"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                aspectRatio: '10/4',
                                objectFit: 'cover',
                                borderRadius: '1rem',
                                position: 'inherit',
                                zIndex: 2,
                                '&:hover': {
                                    opacity: 0.9,
                                    cursor: 'pointer',

                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} aria-label='back' >

                        <Button size='small' sx={{ mb: 2 }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Back Side
                            <VisuallyHiddenInput type="file"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        setBackId(e.target.files[0])
                                    }
                                }}
                            />
                        </Button>
                        <Box component='img'
                            src={backId ? URL.createObjectURL(backId) : backIdCard}
                            alt="Face Photo"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                aspectRatio: '10/4',
                                objectFit: 'cover',
                                borderRadius: '1rem',
                                position: 'inherit',
                                zIndex: 2,
                                '&:hover': {
                                    opacity: 0.9,
                                    cursor: 'pointer',

                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} aria-label='back' >
                        <Button size='small' sx={{ mb: 2 }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Selfie
                            <VisuallyHiddenInput type="file"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        setSelfie(e.target.files[0])
                                    }
                                }}
                            />
                        </Button>
                        <Box component='img'
                            src={selfie ? URL.createObjectURL(selfie) : frontIdCard}
                            alt="Selfie"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                aspectRatio: '10/4',
                                objectFit: 'cover',
                                borderRadius: '1rem',
                                position: 'inherit',
                                zIndex: 2,
                                '&:hover': {
                                    opacity: 0.9,
                                    cursor: 'pointer',

                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='flex-end'>
                        <LoadingButton
                            startIcon={<Icon>send</Icon>}
                            loadingPosition="start"
                            loading={loading}
                            variant="contained"
                            type="submit">
                            Verify
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </div>

    </>);
}

export default VerificationForm;