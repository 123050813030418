import { MuiTelInput } from "mui-tel-input";
import { memo } from "react";

const arePropsEqual = (prevProps: any, nextProps: any) => {
    return (
        prevProps.formik.values[prevProps.name] === nextProps.formik.values[nextProps.name] &&
        prevProps.formik.touched[prevProps.name] === nextProps.formik.touched[nextProps.name] &&
        prevProps.formik.errors[prevProps.name] === nextProps.formik.errors[nextProps.name]
    );
};

const PhoneFormik = memo(
    ({ name, label, formik, onlyCountries, defaultCountry }: { name: any, label: string, formik: any, onlyCountries?: any, defaultCountry?: any }) => {

        return (
            <MuiTelInput
                fullWidth
                label={label}
                name={name}
                variant="outlined"
                size="small"
                value={formik.values[name]}
                onChange={(v, i) => formik.setFieldValue(name, v)}
                onBlur={formik.handleBlur}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name]}
                onlyCountries={onlyCountries}
                defaultCountry={defaultCountry}
            />
        );
    },
    arePropsEqual  // Pasa la función de comparación como segundo argumento a memo
);

export default PhoneFormik;
